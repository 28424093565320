const initialState = {
  requesting: true,
  weight_range: [],
  weight_range_general: [],
  weight_range_contract: [],
  weight_range_promo: [],
  weight_range_general_old_data: [],
  weight_range_contract_old_data: [],
  weight_range_promo_old_data: [],
  weight_range_general_list: [],
  weight_range_contract_list: [],
  weight_range_promo_list: [],
  weightRangeDetailsByAirlineId: [],
};

export const airlineWeightRangeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'RETRIEVE_WEIGHT_RANGE':
      return {
        ...state,
        weight_range: [...payload],
        requesting: false,
      };

    // ----------------------- General Range ----------------------- //

    case 'RETRIEVE_WEIGHT_RANGE_GENERAL':
      return {
        ...state,
        weight_range_general: [...payload],
        requesting: false,
      };

    case 'SET_OLD_WEIGHT_RANGE_GENERAL_UPDATE_DATA':
      return {
        ...state,
        weight_range_general_old_data: [...payload],
        requesting: false,
      };

    case 'RETRIEVE_WEIGHT_RANGE_GENERAL_PRE_FOR_UPDATE_GENERAL':
      return {
        ...state,
        weight_range_general: payload?.[0] ? [...payload?.[0]] : [],
        requesting: false,
      };

    case 'RETRIEVE_WEIGHT_RANGE_GENERAL_LIST':
      return {
        ...state,
        weight_range_general_list: [...payload],
        weight_range_general: [],
        requesting: false,
      };

    // ----------------------- Contract Range ----------------------- //

    case 'RETRIEVE_WEIGHT_RANGE_CONTRACT':
      return {
        ...state,
        weight_range_contract: [...payload],
        requesting: false,
      };

    case 'SET_OLD_WEIGHT_RANGE_CONTRACT_UPDATE_DATA':
      return {
        ...state,
        weight_range_contract_old_data: [...payload],
        requesting: false,
      };

    case 'RETRIEVE_WEIGHT_RANGE_CONTRACT_PRE_FOR_UPDATE_CONTRACT':
      return {
        ...state,
        weight_range_contract: payload?.[0] ? [...payload?.[0]] : [],
        requesting: false,
      };

    case 'RETRIEVE_WEIGHT_RANGE_CONTRACT_LIST':
      return {
        ...state,
        weight_range_contract_list: [...payload],
        weight_range_contract: [],
        requesting: false,
      };

    // ----------------------- Promo Range ----------------------- //

    case 'RETRIEVE_WEIGHT_RANGE_PROMO':
      return {
        ...state,
        weight_range_promo: [...payload],
        requesting: false,
      };

    case 'SET_OLD_WEIGHT_RANGE_PROMO_UPDATE_DATA':
      return {
        ...state,
        weight_range_promo_old_data: [...payload],
        requesting: false,
      };

    case 'RETRIEVE_WEIGHT_RANGE_PROMO_PRE_FOR_UPDATE_PROMO':
      return {
        ...state,
        weight_range_promo: payload?.[0] ? [...payload?.[0]] : [],
        requesting: false,
      };

    case 'RETRIEVE_WEIGHT_RANGE_PROMO_LIST':
      return {
        ...state,
        weight_range_promo_list: [...payload],
        weight_range_promo: [],
        requesting: false,
      };

    // ----------------------- WT Range ----------------------- //

    case 'RETRIEVE_WEIGHT_RANGE_DETAILS_BY_AIRLINE_ID':
      return {
        ...state,
        requesting: false,
        weightRangeDetailsByAirlineId: [...payload],
      };

    default:
      return {
        ...state,
      };
  }
};
