const initial_state = {};

export const retrieve_agent_details_reducer = (state = initial_state, { type, payload }) => {
  switch (type) {
    case 'RETRIEVE_AGENT_DETAILS':
      return {
        ...state,
        ...payload,
      };

    default:
      return {
        ...state,
      };
  }
};
