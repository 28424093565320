import React, { useContext,useEffect,useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppContext from '../../../@crema/utility/AppContext';
import { ThemeMode } from '../../constants/AppEnums';
import Hidden from '@material-ui/core/Hidden';
import {useSelector,useDispatch } from 'react-redux';  
import { downLogoFile } from 'pages/mainPages/FreightForwarder/Admin/Quotation/redux/action';
import {downloadConsignee} from '../../../pages/mainPages/Shipper/Profile/UserProfile/redux/actions'
const AppLogo = () => {
  const dispatch=useDispatch()
  const {profile}=useSelector((state)=>state.auth);
  const [logo,setLogo]=useState('')
  useEffect(()=>{
   
    if(profile?.role!=="Client (Freight Forwarder)"||profile?.role!=="Client (Consignee/Shipper)")
    {
      dispatch(downLogoFile(profile?.company?.profileImage))
      setLogo(profile?.company?.profileImage)
    }
    
  },[profile?.company?.profileImage])

  useEffect(()=>{
    if(profile?.role==="Client (Freight Forwarder)"||profile?.role==="Client (Consignee/Shipper)")
    {
      dispatch(downloadConsignee(profile?.companyLogo,profile?.role==="Client (Consignee/Shipper)"?'Consignee':'Client'))
      setLogo(profile?.companyLogo)

    }
  },[profile?.companyLogo])


  const { themeMode } = useContext(AppContext);
  const useStyles = makeStyles(() => ({
    logoRoot: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
      height: 50,
    },
    logo: {
      height: 'auto',
      // marginRight: 10,
      // width: 200,
      width: 185,
    },
    webLogo: {
      height: 36,
      marginRight: 15,
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.logoRoot}>
      <Hidden smUp>
        {
          profile?.company!=null&&profile?.company?.profileImage!==null?
        <img
          className={classes.logo}
          src={
            themeMode === ThemeMode.DARK
              ? '/assets/images/logo-white.png'
              : (profile?.company!=null&&profile?.company?.profileImage!=null)&&logo!==''?logo:'/assets/images/logo-with-name.png'
          }
          alt="logo"
        />:
          profile?.role=="Super Admin"?
          <img
          className={classes.logo}
          src={
            '/assets/images/logo-with-name.png'
          }
          alt="logo"
        />:
        profile?.companyLogo!=null?<img
        className={classes.logo}
        src={logo}
        alt="logo"
      />:
        <h3 style={{textAlign:'center'}}>{profile?.companyName}</h3>
      }
      </Hidden>
      <Hidden xsDown>
        {
          profile?.company!=null&&profile?.company?.profileImage!==null?
        <img
          className={classes.logo}
          src={
            themeMode === ThemeMode.DARK
              ? '/assets/images/sign-in-logo.png'
              : (profile?.company!=null&&profile?.company?.profileImage!=null)&&logo!==''?logo:'/assets/images/logo-with-name.png'
          }
          alt="logo"
        />:
        profile?.role=="Super Admin"?
          <img
          className={classes.logo}
          src={
            '/assets/images/logo-with-name.png'
          }
          alt="logo"
        />:
        profile?.companyLogo!=null?<img
        className={classes.logo}
        src={logo}
        alt="logo"
      />:
        <h3 style={{textAlign:'center'}}>{profile?.companyName}</h3>
      }
      </Hidden>
    </Box>
  );
};

export default AppLogo;
