import React, { useContext, useState } from 'react';
import AppContext from '../../utility/AppContext';

import clsx from 'clsx';

import Box from '@material-ui/core/Box';

import useStyles from './index.style';

const ThemeSetting = (props) => {
  const [open, setCustomizerStatus] = useState(false);
  const [themeColor, setThemeColor] = useState('preset');
  const {
    themeMode,
    updateThemeMode,
    themeStyle,
    updateThemeStyle,
    updateTheme,
    footer,
    footerType,
    setFooter,
    setFooterType,
    theme,
    isRTL,
    setRTL,
    rtAnim,
    changeRTAnim,
    navStyle,
    layoutType,
    updateLayoutStyle,
    changeNavStyle,
  } = useContext(AppContext);
  const onStyleChange = (event, themeStyle) => {
    if (themeStyle) updateThemeStyle(themeStyle);
  };

  const onModeChange = (event, themeMode) => {
    if (themeMode) updateThemeMode(themeMode);
  };

  const onSelectThemeColor = (event, color) => {
    if (color) setThemeColor(color);
  };

  const onLayoutChange = (layoutType) => {
    updateLayoutStyle(layoutType);
  };
  const onNavStyleChange = (navStyle) => {
    changeNavStyle(navStyle);
  };

  const onChangeRtlSetting = (event) => {
    setRTL(event.target.checked);
  };
  const updateThemeColors = (colorSet) => {
    theme.palette.primary.main = colorSet.PrimaryColor;
    theme.palette.secondary.main = colorSet.SecondaryColor;
    theme.palette.sidebar.bgColor = colorSet.SidebarColor;
    updateTheme(theme);
  };
  const classes = useStyles(props);

  return <Box className={clsx(classes.customizerOption, 'customizerOption')}></Box>;
};

export default ThemeSetting;
