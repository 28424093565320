import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { UPDATE_AUTH_USER } from '../../shared/constants/ActionTypes';
import { fetchStart, fetchSuccess, setJWTToken } from '../../redux/actions';
import { AuthType } from '../../shared/constants/AppEnums';
import { defaultUser } from '../../shared/constants/AppConst';
import jwtAxios from '../services/auth/jwt-auth/jwt-api';
import { getProfile } from '../../redux/actions/JWTAuth';

export const useAuthToken = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { user, token } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (!token) return;
    dispatch(getProfile());
  }, [token]);

  useEffect(() => {
    const validateAuth = async () => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      if (!token) {
        dispatch(fetchSuccess());
        return;
      }
      dispatch(setJWTToken(token));
      try {
        // const res = await jwtAxios.get('/auth');

        const data = JSON.parse(localStorage.getItem('userDetails'));

        dispatch(fetchSuccess());
        dispatch({
          type: UPDATE_AUTH_USER,
          payload: {
            displayName: `${data.firstName} ${data.lastName}`,
            email: data.email,
            role: data.role[0].authority,
            token: data.jwtToken,
            photoURL: data.avatar,
          },
        });
        return;
      } catch (err) {
        dispatch(fetchSuccess());
        return;
      }
    };

    const checkAuth = () => {
      Promise.all([validateAuth()]).then(() => {
        setLoading(false);
      });
    };
    checkAuth();
  }, [dispatch]);

  return [loading, user];
};

export const useAuthUser = () => {
  const { user } = useSelector(({ auth }) => auth);

  if (user) {
    return { id: 1, ...user };
  }
  return [null];
};
