const initial_state = {
  requesting: true,
  airport_list: [],
};

export const search_airport_list_reducer = (state = initial_state, { type, payload }) => {
  switch (type) {
    case 'SEARCH_AIRPORT_LIST':
      return {
        ...state,
        requesting: false,
        airport_list: [...payload],
      };
    default:
      return {
        ...state,
      };
  }
};
