export const ffClientNavConfig = [
  // Quotes
  {
    id: 'quotationManagement',
    title: 'Quotes',
    messageId: 'sidebar.quotationManagement',
    type: 'item',
    icon: ['/assets/images/sideMenu/quotes-active.png', '/assets/images/sideMenu/quotes.png'],
    role: ['ROLE_FFCLIENT'],
    url: ['/freight-forwarder/client/quotation/create',
      '/freight-forwarder/client/quotation/price',
      '/freight-forwarder/client/quotation/Airfreight-quote/price',
      '/freight-forwarder/client/quotation/list/view',
      '/freight-forwarder/client/quotation/details/',
      '/freight-forwarder/client/quotation/inquerier/list/view',
      '/freight-forwarder/client/quotation/manual/view',
      '/freight-forwarder/client/quotation/manual/details'
    ],
    // children: [
    //   {
    //     id: 'quotationCreate',
    //     title: 'New Quote',
    //     messageId: 'sidebar.quotationManagement.quotationCreate',
    //     type: 'item',
    //     url: '/freight-forwarder/client/quotation/create',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'quotationList',
    //     title: 'My Quotes',
    //     messageId: 'sidebar.quotationManagement.quotationList',
    //     type: 'item',
    //     url: '/freight-forwarder/client/quotation/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'quoteInquerier',
    //     title: 'Quote Inquerier',
    //     messageId: 'sidebar.quotationManagement.quoteInquerier',
    //     type: 'item',
    //     url: '/freight-forwarder/client/quotation/inquerier/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Tracking
  // {
  //   id: 'trackingManagement',
  //   title: 'Tracking',
  //   messageId: 'sidebar.trackingManagement',
  //   type: 'item',
  //   icon: ['/assets/images/sideMenu/tracking.png'],
  //   role: ['ROLE_FFCLIENT'],
  //   children: [],
  // },

  // Freight Forwarders
  {
    id: 'freightForwarders',
    title: 'Freight Forwarders',
    messageId: 'sidebar.freightForwarders',
    type: 'item',
    icon: ['/assets/images/sideMenu/freight-forwarder-active.png', '/assets/images/sideMenu/freight-forwarder.png'],
    role: ['ROLE_FFCLIENT'],
    url: ['/freight-forwarder/client/freight-forwarders/sent/requests/list/view',
      '/freight-forwarder/client/freight-forwarders/sent/requests/list/view',
      '/freight-forwarder/client/freight-forwarders/request/send',
      '/ffclient/freight-forwarder/company-details/',
      '/freight-forwarder/client/freight-forwarders/sent/requests/list/action/view',
      '/freight-forwarder/client/freight-forwarders/message/list/view',
    ],
    // children: [
    //   {
    //     id: 'sentRequests',
    //     title: 'Sent Requests',
    //     messageId: 'sidebar.sentRequests',
    //     type: 'item',
    //     url: '/freight-forwarder/client/freight-forwarders/sent/requests/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'sendRequest',
    //     title: 'Send Request',
    //     messageId: 'sidebar.sendRequest',
    //     type: 'item',
    //     url: '/freight-forwarder/client/freight-forwarders/request/send',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Address Book
  // {
  //   id: 'addressManagement',
  //   title: 'Address Book',
  //   messageId: 'sidebar.addressManagement',
  //   type: 'item',
  //   icon: ['/assets/images/sideMenu/address-book-active.png', '/assets/images/sideMenu/address-book.png'],
  //   role: ['ROLE_FFCLIENT'],
  //   url: ['/freight-forwarder/client/directory/address/new',
  //     '/freight-forwarder/client/directory/address/list/view',
  //     '/freight-forwarder/client/directory/address/edit/'],
  //   // children: [
  //   //   {
  //   //     id: 'addAddress',
  //   //     title: 'Add Address',
  //   //     messageId: 'sidebar.addressManagement.addAddress',
  //   //     type: 'item',
  //   //     url: '/freight-forwarder/client/directory/address/new',
  //   //     icon: '/assets/images/sideMenu/sub-menu.png',
  //   //   },
  //   //   {
  //   //     id: 'customerList',
  //   //     title: 'Address List',
  //   //     messageId: 'sidebar.addressManagement.addressList',
  //   //     type: 'item',
  //   //     url: '/freight-forwarder/client/directory/address/list/view',
  //   //     icon: '/assets/images/sideMenu/sub-menu.png',
  //   //   },
  //   // ],
  // },

  // Statistics
  // {
  //   id: 'statistics',
  //   title: 'Statistics',
  //   messageId: 'sidebar.statistics',
  //   type: 'item',
  //   icon: ['/assets/images/sideMenu/statistics-active.png', '/assets/images/sideMenu/statistics.png'],
  //   role: ['ROLE_FFCLIENT'],
  //   url: ['/freight-forwarder/client/statistics'],
  //   // children: [
  //   //   {
  //   //     id: 'statistics',
  //   //     title: 'Statistics',
  //   //     messageId: 'sidebar.statistics.statistics',
  //   //     type: 'item',
  //   //     url: '/freight-forwarder/client/statistics',
  //   //     icon: '/assets/images/sideMenu/sub-menu.png',
  //   //   },
  //   // ],
  // },

  // Settings
  {
    id: 'profileManagement',
    title: 'Settings',
    messageId: 'sidebar.profile',
    type: 'item',
    icon: ['/assets/images/sideMenu/settings-active.png', '/assets/images/sideMenu/settings.png'],
    role: ['ROLE_FFCLIENT'],
    url: ['/freight-forwarder/client/profile/update',
      '/freight-forwarder/client/profile/measurements/system'],
    // children: [
    //   {
    //     id: 'profile',
    //     title: 'View Profile',
    //     messageId: 'sidebar.adminProfile.update',
    //     type: 'item',
    //     url: '/freight-forwarder/client/profile/update',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Documents
  // {
  //   id: 'documentsManagement',
  //   title: 'Documents',
  //   messageId: 'sidebar.documentsManagement',
  //   type: 'collapse',
  //   icon: '/assets/images/sideMenu/documents.png',
  //   role: ['ROLE_FFCLIENT'],
  //   children: [],
  // },

  // Notifications
  {
    id: 'notification',
    title: 'Notifications',
    messageId: 'sidebar.notification',
    type: 'item',
    icon: ['/assets/images/sideMenu/notification-active.png', '/assets/images/sideMenu/notification.png'],
    role: ['ROLE_FFCLIENT'],
    url: ['/freight-forwarder/client/notification'],
    // children: [
    //   {
    //     id: 'notification',
    //     title: 'Notifications',
    //     messageId: 'sidebar.notification',
    //     type: 'item',
    //     url: '/freight-forwarder/client/notification',
    //     icon: '/assets/images/sideMenu/sub-menu.png',

    //   },
    // ],
  },

  // Feedback
  {
    id: 'feedBackManagement',
    title: 'Feedback',
    messageId: 'sidebar.feedBackManagement',
    type: 'item',
    icon: ['/assets/images/sideMenu/feedback-active.png', '/assets/images/sideMenu/feedback.png'],
    role: ['ROLE_FFCLIENT'],
    url: ['/freight-forwarder/Client/addfeedback',
      '/freight-forwarder/Client/feedback/view',
      '/freight-forwarder/ffclient/feedback/feedbacklist/view/'],
    // children: [
    //   {
    //     id: 'feedback',
    //     title: 'Feedback',
    //     messageId: 'sidebar.feedBackManagement.feedback',
    //     type: 'item',
    //     url: '/freight-forwarder/Client/feedback/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Tutorial
  {
    id: 'ytTutorial',
    title: 'Tutorial',
    messageId: 'sidebar.ytTutorial',
    type: 'item',
    icon: ['/assets/images/sideMenu/youtube-active.png', '/assets/images/sideMenu/youtube.png'],
    url: ['/freight-forwarder/client/tutorial/view'],
    role: ['ROLE_FFCLIENT'],
  },
];
