const initialState = {
  requesting: true,
  freightForwarders: [],
  sendRequest: {},
  sentRequests: [],
  messageData: [],
};

export const shipperRequestToFreightForwardersReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case '@@freightForwarders/allFreightForwarders':
      return {
        ...state,
        requesting: false,
        freightForwarders: payload,
      };
    case '@@freightForwarders/sendRequest':
      return {
        ...state,
        requesting: false,
        sendRequest: payload,
      };
    case '@@freightForwarders/sentRequests':
      return {
        ...state,
        requesting: false,
        sentRequests: payload,
      };

    case '@@freightForwarders/messageData':
      return {
        ...state,
        requesting: false,
        messageData: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
