const init_state = {
  airport_list: [],
  airport_details: {},
};

export const airportsReducer = (state = init_state, { type, payload }) => {
  switch (type) {
    case `RETRIEVE_AIRPORT_LIST`:
      return {
        ...state,
        airport_list: [...payload],
      };
    case `RETRIEVE_AIRPORT_DETAILS`:
      return {
        ...state,
        airport_details: {
          ...payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
