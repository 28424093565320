import React, { useContext } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import AppContext from '../../../@crema/utility/AppContext';
import './footer.css';

const Footer = (props) => {
  const { themeMode } = useContext(AppContext);
  const dispatch = useDispatch();

  return (
    <Grid container className="footer">
      <Grid item xs={12} sm={8}>
        <Typography className="copy-right-txt">
          ©2023 Powered by <Box className="inhive-txt"> InHive Systems Inc. </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} className="text-align-right">
        {/* <Typography className="version-txt">Version : Demo 1.0</Typography> */}
        <Typography className="version-txt">
          Version :{' '}
          <font color="#38bbeb" font-weight="bold" margin-left="3px" margin-right="3px">
            Beta 2.0.0
          </font>
        </Typography>
      </Grid>
    </Grid>
  );
};
export default Footer;
