import {
  POST_FFADMIN_QUOTATIONS_PRICE_REQ,
  POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS,
  POST_FFADMIN_QUOTATIONS_PRICE_FAIL,
  GET_FFADMIN_QUOTATIONS_REQ,
  GET_FFADMIN_QUOTATIONS_SUCCESS,
  GET_FFADMIN_QUOTATIONS_FAIL,
  GET_FFADMIN_QUOTATION_REQ,
  GET_FFADMIN_QUOTATION_SUCCESS,
  GET_FFADMIN_QUOTATION_FAIL,
  CREATE_FFADMIN_QUOTATION_REQ,
  CREATE_FFADMIN_QUOTATION_SUCCESS,
  CREATE_FFADMIN_QUOTATION_FAIL,
  GET_FFADMIN_SEARCH_ADDRESS_REQ,
  GET_FFADMIN_SEARCH_ADDRESS_SUCCESS,
  GET_FFADMIN_SEARCH_ADDRESS_FAIL,
  GET_FFADMIN_SEARCH_ADDRESS2_REQ,
  GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS,
  GET_FFADMIN_SEARCH_ADDRESS2_FAIL,
  GET_REQUEST_QUOTE_REQ,
  GET_REQUEST_QUOTE_SUCCESS,
  GET_REQUEST_QUOTE_FAIL,
  GET_SHIP_FROM_AIRPORT_REQ,
  GET_SHIP_FROM_AIRPORT_SUCCESS,
  GET_SHIP_FROM_AIRPORT_FAIL,
  GET_SHIP_TO_AIRPORT_REQ,
  GET_SHIP_TO_AIRPORT_SUCCESS,
  GET_SHIP_TO_AIRPORT_FAIL,
  GET_SEARCH_BY_ZIP_REQ,
  GET_SEARCH_BY_ZIP_SUCCESS,
  GET_SEARCH_BY_ZIP_FAIL,
  GET_COUNTRY_REQ,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAIL,
  GET_SHIP_TO_QUICK_AIRPORT_REQ,
  GET_SHIP_TO_QUICK_AIRPORT_SUCCESS,
  GET_SHIP_TO_QUICK_AIRPORT_FAIL,
  CHECK_QUOTE_AVAILABLE_REQ,
  CHECK_QUOTE_AVAILABLE_SUCCESS,
  CHECK_QUOTE_AVAILABLE_FAIL,
  SET_QUOTATION_DATA,
  SEND_QUOTE_BY_EMAIL_REQ,
  SEND_QUOTE_BY_EMAIL_SUCCESS,
  SEND_QUOTE_BY_EMAIL_FAIL,
  GET_AIRPORT_DEPATURE_LIST,
  STORE_RADIO_OPTION,
  GET_EMPTY_COUNTRY_LIST,
  POST_FFADMIN_QUOTATION_PDF_REQ,
  POST_FFADMIN_QUOTATION_PDF_SUCCESS,
  POST_FFADMIN_QUOTATION_PDF_FAIL,
  GET_UPDATED_QUOTATION_LIST,
  GET_SHIPPER_LIST_REQ,
  GET_SHIPPER_LIST_SUCCESS,
  GET_SHIPPER_LIST_FAIL,
  SAVE_QUOTATION_DATA,
  SAVE_CHECKBOX_DATA,
  EDIT_SAVE_QUOTATION,
  EDIT_QUOTATION_REQ,
  EDIT_QUOTATION_SUCCESS,
  EDIT_QUOTATION_FAIL,
  SET_PREVIOUS_STATE,
  SET_UPDATE_PREVIOUS_STATE,
  SET_QUOTATION_UPDATE_UNIT,
  SET_SHIPPER_QUOTATION_REQ,
  SET_SHIPPER_QUOTATION_SUCCESS,
  SET_SHIPPER_QUOTATION_FAIL,
  GET_TRUKING_DATA,
  GET_RECALCULATE_VALUE,
  GET_DELIVERY_ZIPCODE_REQ,
  GET_DELIVERY_ZIPCODE_SUCCESS,
  GET_DELIVERY_ZIPCODE_FAIL,
  GET_UPDATE_DELIVERY_ZIPCODE_FAIL,
  GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS,
  GET_UPDATE_DELIVERY_ZIPCODE_REQ,
  GET_UPDATED_PICKUP_ZIPCODE_REQ,
  GET_UPDATED_PICKUP_ZIPCODE_SUCCESS,
  GET_UPDATED_PICKUP_ZIPCODE_FAIL,
  GET_UNASSIGNED_LIST_REQ,
  GET_UNASSIGNED_LIST_SUCCESS,
  GET_UNASSIGNED_LIST_FAIL,
  GET_ASSIGNED_LIST_REQ,
  GET_ASSIGNED_LIST_SUCCESS,
  GET_ASSIGNED_LIST_FAIL,
  SET_CREATE_SHIPPER_QUOTATION_REQ,
  SET_CREATE_SHIPPER_QUOTATION_SUCCESS,
  SET_CREATE_SHIPPER_QUOTATION_FAIL,
  GET_SHIPPER_QUOTATIONS_REQ,
  GET_SHIPPER_QUOTATIONS_SUCCESS,
  GET_SHIPPER_QUOTATIONS_FAIL,
  GET_CONSIGNEE_COUNTRY_LIST,
  GET_CONSIGNEE_COUNTRY_BASED_DATA,
  GET_SEND_REQUEST_STATUS,
} from '../constants';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';
import { toast } from 'react-toastify';
import axios from 'axios';

// export const getAllQuotation = (page, size) => async (dispatch) => {
//   try {
//     dispatch({ type: GET_FFADMIN_QUOTATIONS_REQ });

//     const config = {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${localStorage.getItem('token')}`,
//       },
//     };
//     const response = await jwtAxios.get(
//       `/ffClient/quotations-list?page=${page}&size=${size}`,
//       config,
//     );

//     if (response.status === 200) {
//       dispatch({
//         type: GET_FFADMIN_QUOTATIONS_SUCCESS,
//         payload: { ...response.data },
//       });
//       return new Promise((resolve, _) => {
//         resolve(response);
//       });
//     }
//   } catch (error) {
//     
//     dispatch({ type: GET_FFADMIN_QUOTATIONS_FAIL });
//     toast.error('Something went wrong!');
//   }
// };

export const getAllQuotation = (page, size, sorting, filtering) => async (dispatch) => {

  const queryParams = {
    page,
    size,
    filter: JSON.stringify(filtering),
    sort: JSON.stringify(sorting),
  };

  try {
    dispatch({ type: GET_SHIPPER_QUOTATIONS_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    // const response = await jwtAxios.get(
    //   `/ffclient/quotations-list?page=${page}&size=${size}&sort=${JSON.stringify(sorting)}&filter=${JSON.stringify(filtering)}`,
    //   config,
    // );

    const response = await jwtAxios.get('/ffclient/quotations-list', {
      ...config,
      params: queryParams,
    });

    if (response.status === 200) {
      dispatch({
        type: GET_SHIPPER_QUOTATIONS_SUCCESS,
        payload: { ...response.data },
      });
      return new Promise((resolve, _) => {
        resolve(response);
      });
    }
  } catch (error) {
    
    dispatch({ type: GET_SHIPPER_QUOTATIONS_FAIL });
    toast.error('Something went wrong!');
  }
};

export const saveQuotationPriceItem = (data) => async (dispatch) => {
  try {
    dispatch({ type: POST_FFADMIN_QUOTATIONS_PRICE_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios({
      url: `/ffclient/quotation/save?request_id=${data.request_id}&quote_id=${data.quote_id}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      dispatch({
        type: POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS,
        payload: response.data,
      });
      toast.success('Quotation Items saved.');
    }
  } catch (error) {
    
    dispatch({ type: POST_FFADMIN_QUOTATIONS_PRICE_FAIL });
    toast.error(error.response.data.title);
  }
};

export const createQuotation = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_FFADMIN_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffclient/quotation/create', data, config);

    if (response.status === 200) {
      dispatch({
        type: CREATE_FFADMIN_QUOTATION_SUCCESS,
        payload: response.data,
      });
      // dispatch({
      //   type: GET_SEARCH_BY_ZIP_SUCCESS,
      //   payload: ''
      // });
      // dispatch({
      //   type: GET_DELIVERY_ZIPCODE_SUCCESS,
      //   payload:'',
      // });
      if (response?.data?.truckingCarriers?.length != 0)
        dispatch({
          type: GET_TRUKING_DATA,
          payload: response.data.truckingCarriers,
        });
      // toast.success("Quotation created");

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    // 
    dispatch({ type: CREATE_FFADMIN_QUOTATION_FAIL });
    // toast.error(error.response?.data?.title, { autoClose: 3500 });
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};

export const getTruckingCarriersDetails = (Rid, truckingCarriersId, truckingCarrierData) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        url: `ffclient/quotation?request_id=${Rid}&trucking_id=${truckingCarriersId}`,
        method: 'post',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // truckingCarrierData.economicsRates = res?.data?.economicsRates;

      let truckingCarriers = truckingCarrierData;

      if (res.status === 200) {
        dispatch({
          type: CREATE_FFADMIN_QUOTATION_SUCCESS,
          payload: { ...res?.data, truckingCarriers },
          // payload: res?.data,
        });
        toast.success('Quotation updated successfully');
      }
    } catch (error) {
      
      // toast.error(`Internal Server Error`);
      if (error.res != undefined) {
        toast.error(error.res, { autoClose: 5000 });
      } else {
        toast.error('Internal Server Error');
      }
    }
  };
};

export const updateQuotation = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_FFADMIN_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffclient/quotation/create', data, config);
    if (response.status === 200) {
      dispatch({
        type: CREATE_FFADMIN_QUOTATION_SUCCESS,
        payload: response.data,
      });
      if (response.data.truckingCarriers.length != 0) {
        dispatch({
          type: GET_TRUKING_DATA,
          payload: response.data.truckingCarriers,
        });
        dispatch({ type: GET_RECALCULATE_VALUE });
      }
      toast.success('Quotation updated successfully');

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    
    dispatch({ type: CREATE_FFADMIN_QUOTATION_FAIL });
    // toast.error(error.response.data.title);
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};

export const updateSaveQuotation = (data) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffClient/saved-quotation/update', data, config);
    if (response.status === 200) {
      dispatch({
        type: EDIT_QUOTATION_SUCCESS,
        payload: response.data,
      });

      if (response.data.truckingCarriers.length != 0)
        dispatch({
          type: GET_TRUKING_DATA,
          payload: response.data.truckingCarriers,
        });
      toast.success('Quotation updated successfully');

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    
    dispatch({ type: EDIT_QUOTATION_FAIL });
    toast.error(error.response.data.title);
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};
// Testing purpose

export const getPricedQuote = () => async (dispatch) => {
  try {
    dispatch({ type: CREATE_FFADMIN_QUOTATION_REQ });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffClient/quotation/replays/598', {}, config);

    if (response.status === 200) {
      dispatch({
        type: CREATE_FFADMIN_QUOTATION_SUCCESS,
        payload: response.data,
      });
      // toast.success("Quotation created");

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    
    dispatch({ type: CREATE_FFADMIN_QUOTATION_FAIL });
    toast.error(error.response.data.title);
    return new Promise((resolve, reject) => {
      reject(error.response);
    });
  }
};

export const getQuotation = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_FFADMIN_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`/ffclient/quotation/${id}`, config);

    if (response.status === 200) {
      dispatch({ type: GET_FFADMIN_QUOTATION_SUCCESS, payload: response.data });
    }
  } catch (error) {
    
    dispatch({ type: GET_FFADMIN_QUOTATION_FAIL });
    toast.error('Something went wrong!');
  }
};

export const getShipperQuotationDetail = (id) => async (dispatch) => {
  try {
    dispatch({ type: SET_SHIPPER_QUOTATION_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`/ffClient/client/quotation-request/${id}`, config);

    if (response.status === 200) {
      dispatch({ type: SET_SHIPPER_QUOTATION_SUCCESS, payload: response.data });
    }
  } catch (error) {
    
    dispatch({ type: SET_SHIPPER_QUOTATION_FAIL });
    toast.error('Something went wrong!');
  }
};

export const getCreateShipperQuotationDetail = (id) => async (dispatch) => {
  try {
    // dispatch({ type: SET_CREATE_SHIPPER_QUOTATION_REQ });

    // const config = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${localStorage.getItem('token')}`,
    //   },
    // };
    // const response = await jwtAxios.post(`/ffClient/quotation/create/${id}`, config);

    // if (response.status === 200) {
    //   dispatch({ type: SET_CREATE_SHIPPER_QUOTATION_SUCCESS, payload: response.data });
    // }

    dispatch({ type: SET_CREATE_SHIPPER_QUOTATION_REQ });

    const res = await jwtAxios({
      url: `/ffClient/quotation/create/${id}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      // data: {
      //   ...post_data,
      // },
    });

    if (200 <= res.status && res.status < 300) {
      dispatch({ type: SET_CREATE_SHIPPER_QUOTATION_SUCCESS, payload: res.data });
      // toast.success('Record Created Successfully');
    } else {
      toast.error(`Internal Server Error`);
    }
  } catch (error) {
    
    dispatch({ type: SET_CREATE_SHIPPER_QUOTATION_FAIL });
    toast.error('Something went wrong!');
  }
};

export const searchFromAddress = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: GET_FFADMIN_SEARCH_ADDRESS_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post(
      `/ffClient/search/directory`,
      { search: searchQuery },
      config,
    );

    if (response.status === 200) {
      dispatch({
        type: GET_FFADMIN_SEARCH_ADDRESS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    
    dispatch({ type: GET_FFADMIN_SEARCH_ADDRESS_FAIL });
    toast.error('Something went wrong!');
  }
};

export const searchToAddress = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: GET_FFADMIN_SEARCH_ADDRESS2_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post(
      `/ffClient/search/directory`,
      { search: searchQuery },
      config,
    );

    if (response.status === 200) {
      dispatch({
        type: GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    
    dispatch({ type: GET_FFADMIN_SEARCH_ADDRESS2_FAIL });
    toast.error('Something went wrong!');
  }
};

export const getReqQuotation = () => async (dispatch) => {
  try {
    dispatch({ type: GET_REQUEST_QUOTE_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get('/ffClient/client/quotation-request/list', config);

    if (response.status === 200) {
      dispatch({
        type: GET_REQUEST_QUOTE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    
    dispatch({ type: GET_REQUEST_QUOTE_FAIL });
    toast.error('Something went wrong!');
  }
};

export const getShipFromAirportList = (zipcode) => async (dispatch) => {
  try {
    dispatch({ type: GET_SHIP_FROM_AIRPORT_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post(`/near-airport/search?zipcode=${zipcode}`, {}, config);

    if (response.status === 200) {
      dispatch({
        type: GET_SHIP_FROM_AIRPORT_SUCCESS,
        payload: response.data,
      });
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    
    dispatch({ type: GET_SHIP_FROM_AIRPORT_FAIL });
    // toast.error("Something went wrong!");
  }
};

export const getShipToAirportList = (data) => async (dispatch) => {
  try {
    dispatch({ type: GET_SHIP_TO_AIRPORT_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.post(`/airport/search`, { search: data }, config);

    if (response.status === 200) {
      dispatch({
        type: GET_SHIP_TO_AIRPORT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    
    dispatch({ type: GET_SHIP_TO_AIRPORT_FAIL });
    toast.error('Something went wrong!');
  }
};

export const getShipToQuickAirportList = (data) => async (dispatch) => {
  try {
    if (data === '') {
      dispatch({ type: GET_EMPTY_COUNTRY_LIST });
    } else {
      dispatch({ type: GET_SHIP_TO_QUICK_AIRPORT_REQ });

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await jwtAxios.post(`/airport/search`, { search: data }, config);

      if (response.status === 200) {
        dispatch({
          type: GET_SHIP_TO_QUICK_AIRPORT_SUCCESS,
          payload: response.data,
        });
      }
    }
  } catch (error) {
    
    dispatch({ type: GET_SHIP_TO_QUICK_AIRPORT_FAIL });
    toast.error('Something went wrong!');
  }
};

// export const getAddressByZip = (zipcode) => async (dispatch) => {
//   if (zipcode != '') {
//     try {
//       dispatch({ type: GET_SEARCH_BY_ZIP_REQ });

//       const config = {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//         },
//       };
//       // const response = await jwtAxios.get(
//       //   `/quotation/search/zipcode?zipcode=${zipcode}`,
//       //   // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
//       //   config
//       // );
//       const response = await axios.get(
//         // `/quotation/search/zipcode?zipcode=${zipcode}`,
//         // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
//         // `https://api.zippopotam.us/us/${zipcode}`,
//         `http://3.134.15.140:8080/api/zipcode?zipcode=${zipcode}`,
//         config,
//       );

//       if (response.status === 200) {
//         dispatch({
//           type: GET_SEARCH_BY_ZIP_SUCCESS,
//           payload: response.data, 
//         });
//         return new Promise((resolve, reject) => {
//           resolve(response);
//         });
//       }
//     } catch (error) {
      
//       toast.error('Zipcode not found Please enter the data Manaualy');
//       dispatch({ type: GET_SEARCH_BY_ZIP_FAIL });
//       return new Promise((resolve, reject) => {
//         reject(error);
//       });
//       // toast.error("Something went wrong!");
//     }
//   } else {
//     dispatch({
//       type: GET_SEARCH_BY_ZIP_SUCCESS,
//       payload: '',
//     });
//   } 
// };
export const getAddressByZip = (data) => {
  return async (dispatch, getState) => {
    if (data != '') {

      dispatch({ type: GET_SEARCH_BY_ZIP_REQ });

      try {
        const response = await jwtAxios({
          method: 'get',
          url: `/zipcode?zipcode=${data}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          dispatch({
            type: GET_SEARCH_BY_ZIP_SUCCESS,
            payload: response.data,
          });
          const hasEmptyObject = response.data.some(obj => Object.keys(obj).length === 0);
          if (hasEmptyObject)
            toast.error('Zipcode not available');
          return new Promise((resolve, reject) => {
            resolve(response);
          });
        }
      } catch (error) {
        // console.log(error.message);
        toast.error('Zipcode not found Please enter the data Manaualy');
        dispatch({ type: GET_SEARCH_BY_ZIP_FAIL });
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    };

    if (data === '')
      dispatch({
        type: GET_SEARCH_BY_ZIP_SUCCESS,
        payload: '',
      });

  }
};

export const getShipToCountries = () => async (dispatch) => {
  try {
    dispatch({ type: GET_COUNTRY_REQ });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(`country-list`, config);

    if (response.status === 200) {
      dispatch({
        type: GET_COUNTRY_SUCCESS,
        payload: response.data,
      });
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    
    dispatch({ type: GET_COUNTRY_FAIL });
    return new Promise((resolve, reject) => {
      reject(error);
    });
    // toast.error("Something went wrong!");
  }
};

export const checkQuoteAvailable = (zipcode, airportCode) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_QUOTE_AVAILABLE_REQ });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    // const response = await jwtAxios.get(
    //   `/airlines/search?zipcode=${zipcode}&airport_code=${airportCode}`,
    //   config
    // );

    const response = await jwtAxios.get(
      `/airlines/airport-to-airport/search?from_airport=${zipcode}&to_airport=${airportCode}`,
      config,
    );

    if (response.status === 200) {
      dispatch({
        type: CHECK_QUOTE_AVAILABLE_SUCCESS,
      });
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  } catch (error) {
    
    dispatch({ type: CHECK_QUOTE_AVAILABLE_FAIL });
    return new Promise((resolve, reject) => {
      reject(error);
    });
    // toast.error("Something went wrong!");
  }
};

export const setQuotationDataReq = (data) => (dispatch) => {
  dispatch({ type: SET_QUOTATION_DATA, payload: data });
};

export const saveQuotationDataReq = (data, unit, date, airpDep, airpDest) => (dispatch) => {
  dispatch({ type: SAVE_QUOTATION_DATA, payload: { data, unit, date, airpDep, airpDest } });
};

export const sendQuoteByEmail = (data) => async (dispatch) => {
  try {
    dispatch({ type: SEND_QUOTE_BY_EMAIL_REQ });

    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // };

    // const response = await jwtAxios.post(
    //   "/ffClient/quotation/email",
    //   data,
    //   config
    // );
    const response = await jwtAxios({
      url: `/ffclient/quotation/email?request_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      dispatch({
        type: SEND_QUOTE_BY_EMAIL_SUCCESS,
      });
      toast.success('Email sent successfully!');
    }
  } catch (error) {
    
    dispatch({ type: SEND_QUOTE_BY_EMAIL_FAIL });
    toast.error(error.response.data.title);
  }
};

export const getAirportOfdepartureList = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const data = {
      search: 'USA',
    };
    const response = await jwtAxios.post('/airport/search', data, config);

    if (response.status === 200) {
      dispatch({
        type: GET_AIRPORT_DEPATURE_LIST,
        payload: response.data,
      });
    }
  } catch (error) {
    
    // toast.error(error.response.data.title);
  }
};

export const getRadioOption = (useroption) => async (dispatch) => {
  dispatch({ type: STORE_RADIO_OPTION, payload: useroption });
};

export const saveCheckBoxOption = (option) => async (dispatch) => {
  dispatch({ type: SAVE_CHECKBOX_DATA, payload: option });
};

export const printQuotationPriceItem = (data) => async (dispatch) => {
  try {
    dispatch({ type: POST_FFADMIN_QUOTATION_PDF_REQ });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffClient/quotation/pdf', data, config);

    if (response.status === 200) {
      dispatch({
        type: POST_FFADMIN_QUOTATION_PDF_SUCCESS,
        payload: response.data,
      });
      return new Promise((resolve, reject) => {
        resolve(response);
      });
      // toast.success("Pdf Items received successfully.");
    }
  } catch (error) {
    
    dispatch({ type: POST_FFADMIN_QUOTATION_PDF_FAIL });
    toast.error(error.response.data.title);
  }
};

export const updatedQuotationList = (data) => async (dispatch) => {
  try {
    // dispatch({ type:GET_UPDATED_QUOTATION_LIST  });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffClient/quotation/edit', data, config);

    if (response.status === 200) {
      dispatch({
        type: GET_UPDATED_QUOTATION_LIST,
        payload: response.data,
      });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
      // toast.success("Pdf Items received successfully.");
    }
  } catch (error) {
    
    return new Promise((resolve, reject) => {
      reject(error);
    });
    // dispatch({ type: POST_FFADMIN_QUOTATION_PDF_FAIL });
  }
};

export const updatePreviousQuotationList = (data) => async (dispatch) => {
  try {
    // dispatch({ type:GET_UPDATED_QUOTATION_LIST  });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffClient/quotation/edit', data, config);

    if (response.status === 200) {
      dispatch({
        type: GET_UPDATED_QUOTATION_LIST,
        payload: response.data,
      });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
      // toast.success("Pdf Items received successfully.");
    }
  } catch (error) {
    
    return new Promise((resolve, reject) => {
      reject(error);
    });
    // dispatch({ type: POST_FFADMIN_QUOTATION_PDF_FAIL });
  }
};

export const updatededitQuotationList = (data) => async (dispatch) => {
  try {
    // dispatch({ type:GET_UPDATED_QUOTATION_LIST  });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await jwtAxios.post('/ffClient/saved-quotation/edit', data, config);

    if (response.status === 200) {
      dispatch({
        type: EDIT_SAVE_QUOTATION,
        payload: response.data,
      });

      return new Promise((resolve, reject) => {
        resolve(response);
      });
      // toast.success("Pdf Items received successfully.");
    }
  } catch (error) {
    
    return new Promise((resolve, reject) => {
      reject(error);
    });
    // dispatch({ type: POST_FFADMIN_QUOTATION_PDF_FAIL });
  }
};

export const getShipperList = (page, size, status) => async (dispatch) => {
  try {
    dispatch({ type: GET_SHIPPER_LIST_REQ });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    var response = await jwtAxios.get(
      `/ffClient/client/quotation-request/list?page=${page}&size=${size}&status=${status}`,
      config,
    );

    if (response.status === 200) {
      dispatch({ type: GET_SHIPPER_LIST_SUCCESS, payload: response.data });

      // toast.success('Sh approved.');
      return new Promise((resolve, reject) => {
        resolve(response);
      });
      // dispatch(getRequestedPackage());
    }
  } catch (error) {
    
    dispatch({ type: GET_SHIPPER_LIST_FAIL });
    toast.error('Something went wrong.');
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
};

export const getPreviousState = (data) => (dispatch) => {
  dispatch({ type: SET_PREVIOUS_STATE, payload: data });
};

export const getUpdatePreviousState = (data) => (dispatch) => {
  dispatch({ type: SET_UPDATE_PREVIOUS_STATE, payload: data });
};

export const getQuotationUpdateUnit = (data) => (dispatch) => {
  dispatch({ type: SET_QUOTATION_UPDATE_UNIT, payload: data });
};

export const sendSaveQuoteByEmail = (data) => async (dispatch) => {
  try {
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // };

    // const response = await jwtAxios.post(
    //   "/ffClient/quotation/email",
    //   data,
    //   config
    // );
    const response = await jwtAxios({
      url: `/ffclient/saved-quotation/email?quotation_id=${data.request_id}&quote_id=${data.quote_id}&email=${data.email}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // data,
      },
    });

    if (response.status === 200) {
      toast.success('Email sent successfully!');
    }
  } catch (error) {
    
    toast.error(error.response.data.title);
  }
};

export const retrivePreAirportOfDepatureDetails = (airportDept) => async (dispatch, getState) => {
  try {
    const res = await jwtAxios({
      method: 'POST',
      url: `airport/${airportDept}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    dispatch({
      type: 'RETRIEVE_PRE_AIRPORT_OF_DEPARTURE_DETAILS',
      payload: res.data?.airport,
    });
  } catch (err) {
    toast.error('Internal Server Error');
  }
};

export const retrivePreAirportOfDestinationDetails =
  (airportDest) => async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        method: 'POST',
        url: `airport/${airportDest}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch({
        type: 'RETRIEVE_PRE_AIRPORT_OF_DESTINATION_DETAILS',
        payload: res.data?.airport,
      });
    } catch (err) {
      toast.error('Internal Server Error');
    }
  };

// export const getDeliveryAddressByZip = (zipcode) => async (dispatch) => {
//   if (zipcode != '') {
//     try {
//       dispatch({ type: GET_DELIVERY_ZIPCODE_REQ });

//       const config = {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//         },
//       };
//       // const response = await jwtAxios.get(
//       //   `/quotation/search/zipcode?zipcode=${zipcode}`,
//       //   // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
//       //   config
//       // );
//       const response = await axios.get(
//         // `/quotation/search/zipcode?zipcode=${zipcode}`,
//         // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
//         // `https://api.zippopotam.us/us/${zipcode}`,
//         `http://3.134.15.140:8080/api/zipcode?zipcode=${zipcode}`,
//         config,
//       );

//       if (response.status === 200) {
//         dispatch({
//           type: GET_DELIVERY_ZIPCODE_SUCCESS,
//           payload: response.data,
//         });
//         return new Promise((resolve, reject) => {
//           resolve(response);
//         });
//       }
//     } catch (error) {
      
//       toast.error('Zipcode not found Please enter the data Manaualy');
//       dispatch({ type: GET_DELIVERY_ZIPCODE_FAIL });
//       return new Promise((resolve, reject) => {
//         reject(error);
//       });
//       // toast.error("Something went wrong!");
//     }
//   } else {
//     dispatch({
//       type: GET_DELIVERY_ZIPCODE_SUCCESS,
//       payload: '',
//     });
//   }
// };
export const getDeliveryAddressByZip = (data) => {
  return async (dispatch, getState) => {
    if (data != '') {

      dispatch({ type: GET_DELIVERY_ZIPCODE_REQ });

      try {
        const response = await jwtAxios({
          method: 'get',
          url: `/zipcode?zipcode=${data}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          dispatch({
            type: GET_DELIVERY_ZIPCODE_SUCCESS,
            payload: response.data,
          });
          const hasEmptyObject = response.data.some(obj => Object.keys(obj).length === 0);
          if (hasEmptyObject)
            toast.error('Zipcode not available');
          return new Promise((resolve, reject) => {
            resolve(response);
          });
        }
      } catch (error) {
        // console.log(error.message);
        toast.error('Zipcode not found Please enter the data Manaualy');
        dispatch({ type: GET_DELIVERY_ZIPCODE_FAIL });
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    };

    if (data === '')
      dispatch({
        type: GET_DELIVERY_ZIPCODE_SUCCESS,
        payload: [],
      });

  }
};

export const getUpdateDeliveryAddressByZip = (zipcode) => async (dispatch) => {
  if (zipcode != '') {
    try {
      dispatch({ type: GET_UPDATE_DELIVERY_ZIPCODE_REQ });

      const config = {
        headers: {
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
      // const response = await jwtAxios.get(
      //   `/quotation/search/zipcode?zipcode=${zipcode}`,
      //   // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
      //   config
      // );
      const response = await axios.get(
        // `/quotation/search/zipcode?zipcode=${zipcode}`,
        // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
        `https://api.zippopotam.us/us/${zipcode}`,
        config,
      );

      if (response.status === 200) {
        dispatch({
          type: GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS,
          payload: response.data,
        });
        return new Promise((resolve, reject) => {
          resolve(response);
        });
      }
    } catch (error) {
      
      toast.error('Zipcode not found Please enter the data Manaualy');
      dispatch({ type: GET_UPDATE_DELIVERY_ZIPCODE_FAIL });
      return new Promise((resolve, reject) => {
        reject(error);
      });
      // toast.error("Something went wrong!");
    }
  } else {
    dispatch({
      type: GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS,
      payload: '',
    });
  }
};

export const getPickUpAddressByZip = (zipcode) => async (dispatch) => {
  if (zipcode != '') {
    try {
      dispatch({ type: GET_UPDATED_PICKUP_ZIPCODE_REQ });

      const config = {
        headers: {
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
      // const response = await jwtAxios.get(
      //   `/quotation/search/zipcode?zipcode=${zipcode}`,
      //   // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
      //   config
      // );
      const response = await axios.get(
        // `/quotation/search/zipcode?zipcode=${zipcode}`,
        // `https://app.zipcodebase.com/api/v1/search?apikey=040dd3b0-dfe8-11ec-b5f0-31df7f725ac3&codes=${zipcode}&country=us`,
        `https://api.zippopotam.us/us/${zipcode}`,
        config,
      );

      if (response.status === 200) {
        dispatch({
          type: GET_UPDATED_PICKUP_ZIPCODE_SUCCESS,
          payload: response.data,
        });
        return new Promise((resolve, reject) => {
          resolve(response);
        });
      }
    } catch (error) {
      
      toast.error('Zipcode not found Please enter the data Manaualy');
      dispatch({ type: GET_UPDATED_PICKUP_ZIPCODE_FAIL });
      return new Promise((resolve, reject) => {
        reject(error);
      });

      // toast.error("Something went wrong!");
    }
  } else {
    dispatch({
      type: GET_UPDATED_PICKUP_ZIPCODE_SUCCESS,
      payload: '',
    });
  }
};

export const retriveUnassigKeyList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: 'GET_UNASSIGNED_LIST_REQ' });
    const res = await jwtAxios({
      method: 'GET',
      url: `ffClient/key-management/unassigned-list?role=all`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    dispatch({
      type: 'GET_UNASSIGNED_LIST_SUCCESS',
      payload: res.data,
    });
  } catch (err) {
    toast.error('Internal Server Error');
    dispatch({
      type: 'GET_UNASSIGNED_LIST_FAIL',
    });
  }
};

export const retriveAssigKeyList = (cid, dropDown) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'GET_ASSIGNED_LIST_REQ' });
    const res = await jwtAxios({
      method: 'GET',
      // url: `key-management/get-keys?status=unused&activeStatus=active&assignStatus=assign&companyID=12`,
      url: `/key-management/get-keys?assignStatus=assign&companyID=${cid}&role=${dropDown}`,
      // url: `http://3.134.15.140:8080/api/key-management/get-keys?assignStatus=assign&role=${dropDown.toLowerCase()}&companyID=${cid}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    dispatch({
      type: 'GET_ASSIGNED_LIST_SUCCESS',
      payload: res.data,
    });
  } catch (err) {
    toast.error('Internal Server Error');
    dispatch({
      type: 'GET_ASSIGNED_LIST_FAIL',
    });
  }
};

export const updateStausOfUser = (id, status, cid, dropDown, role) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await jwtAxios.get(
      `freight-forwarder/active-status?id=${id}&status=${status}&role=${role.toLowerCase()}`,
      config,
    );

    if (response.status === 200) {
      dispatch(retriveAssigKeyList(cid, dropDown));
    }
  } catch (error) {
    
    toast.error(error.response.data.title);
  }
};

export const handleSelectionType = (companyId, data) => async (dispatch) => {
  try {
    const res = await jwtAxios({
      method: 'GET',
      url: `key-management/get-keys?assignStatus=assign&companyID=${companyId}&role=${data}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    dispatch({
      type: 'GET_ASSIGNED_LIST_SUCCESS',
      payload: res.data,
    });
  } catch (err) {
    toast.error(err);
  }
};

export const checkDistanceBetweenZipcode =
  (pickUpzipcode, departureZipcode, airport) => async (dispatch) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await jwtAxios.get(
        `check-trucking?pickup-zip=${pickUpzipcode}&delivery-zip=${departureZipcode}&departure-airport=${airport}`,
        config,
      );

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    } catch (error) {
    }
  };

// /ffClient/client-quotation/send

export const sendClientQuoteMail = (DATA) => {
  return async (dispatch, getState) => {
    try {
      // console.log(post_data.perUNNumberFee);
      const res = await jwtAxios({
        url: `/ffClient/client-quotation/send?quotation_id=${DATA?.quotation_id}&request_id=${DATA?.request_id}&quote_id=${DATA?.quote_id}&client_id=${DATA?.client_id}`,
        method: 'post',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        // data: {
        //   // ...post_data,
        // },
      });

      if (200 <= res.status && res.status < 300) {
        toast.success('Record Created Successfully');
      } else {
        toast.error(`Internal Server Error`);
      }
    } catch (error) {
      // if (error.message == "Request failed with status code 404") {
      //   console.log("Airport already exists." + err.message + " abc " + err.response);
      // }
      if (error.response?.data != undefined) {
        toast.error(error.response.data, { autoClose: 1500 });
      } else {
        toast.error('Internal Server Error');
      }
    }
  };
};

export const confirmStatus = (request_id, quote_id, status) => {
  // try{
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('token')}`,
  //     },
  //   }
  //   const response = await jwtAxios.put(`/ffclient/quotation/confirm-or-cancel/${request_id}&${quote_id}&status=${status}`, config);

  //   if (200 <= response.status && response.status < 300) {
  //     toast.success('Confirm Successfully');
  //   } else {
  //     toast.error(`Internal Server Error`);
  //   }

  // }
  // catch (err){
  //   toast.error('Internal Server Error')

  // }
  return async (dispatch, getState) => {
    try {
      // console.log(post_data.perUNNumberFee);
      const res = await jwtAxios({
        url: `/ffclient/quotation/confirm-or-cancel?request_id=${request_id}&quote_id=${quote_id}&status=${status}`,
        method: 'put',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        // data: {
        //   // ...post_data,
        // },
      });

      if (200 <= res.status && res.status < 300) {
        toast.success('Confirmed Successfully');
      } else {
        toast.error(`Internal Server Error`);
      }
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    } catch (error) {
      // if (error.message == "Request failed with status code 404") {
      //   console.log("Airport already exists." + err.message + " abc " + err.response);
      // }
      // if (error.response?.data != undefined) {
      //   toast.error(error.response.data, { autoClose: 1500 });
      // }
      toast.error('Internal Server Error');

      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  };
};

export const confirmOrCancelQuotationRecord = (ccId, ccQuoteId, status) => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        url: `/ffclient/saved-quotation/confirm-or-cancel?quotation_id=${ccId}&quote_id=${ccQuoteId}&status=${status}`,
        method: 'put',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (200 <= res.status && res.status < 300) {
        toast.success(`Record ${status} Successfully`);
      }
    } catch (error) {
      if (error?.response?.data?.title != undefined) {
        toast.error(error?.response?.data?.title, { autoClose: 1500 });
      } else {
        toast.error('Internal Server Error');
      }
    }
  };
};

export const getCountryList = () => {
  return async (dispatch, getState) => {
    try {
      const res = await jwtAxios({
        url: `ff-company/country-list`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // dispatch({
      //   type: '@@freightForwarders/sentRequests',
      //   payload: [...res.data],
      // });
      dispatch({
        type: GET_CONSIGNEE_COUNTRY_LIST,
        payload: res.data,
      });
    } catch (error) { }
  };
};
export const getDetailsBasedOnCountry = (country) => {
  return async (dispatch, getState) => {
    if (country != undefined && country != '') {
      try {
        const res = await jwtAxios({
          url: `company/${country}`,
          method: 'get',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        dispatch({ type: GET_CONSIGNEE_COUNTRY_BASED_DATA, payload: res.data });
      } catch (error) {
        dispatch({ type: GET_CONSIGNEE_COUNTRY_BASED_DATA, payload: '' });
      }
    } else {
      dispatch({ type: GET_CONSIGNEE_COUNTRY_BASED_DATA, payload: '' });
    }
  };
};

export const getSendRequestStatus = (id) => async (dispatch) => {
  try {
    const res = await jwtAxios({
      url: `shipper/check-send-request/${id}`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (res.status == 200) dispatch({ type: GET_SEND_REQUEST_STATUS, payload: res?.data });
  } catch (error) {
    toast.error('Internal Server Error');
  }
};
