export const GET_FFAGENT_ADDRESSES_REQ = 'GET_FFAGENT_ADDRESSES_REQ';
export const GET_FFAGENT_ADDRESSES_SUCCESS = 'GET_FFAGENT_ADDRESSES_SUCCESS';
export const GET_FFAGENT_ADDRESSES_FAIL = 'GET_FFAGENT_ADDRESSES_FAIL';

export const UPDATE_FFAGENT_ADDRESS_REQ = 'UPDATE_FFAGENT_ADDRESS_REQ';
export const UPDATE_FFAGENT_ADDRESS_SUCCESS = 'UPDATE_FFAGENT_ADDRESS_SUCCESS';
export const UPDATE_FFAGENT_ADDRESS_FAIL = 'UPDATE_FFAGENT_ADDRESS_FAIL';

export const DELETE_FFAGENT_ADDRESS_REQ = 'DELETE_FFAGENT_ADDRESS_REQ';
export const DELETE_FFAGENT_ADDRESS_SUCCESS = 'DELETE_FFAGENT_ADDRESS_SUCCESS';
export const DELETE_FFAGENT_ADDRESS_FAIL = 'DELETE_FFAGENT_ADDRESS_FAIL';

export const CREATE_FFAGENT_ADDRESS_REQ = 'CREATE_FFAGENT_ADDRESS_REQ';
export const CREATE_FFAGENT_ADDRESS_SUCCESS = 'CREATE_FFAGENT_ADDRESS_SUCCESS';
export const CREATE_FFAGENT_ADDRESS_FAIL = 'CREATE_FFAGENT_ADDRESS_FAIL';

export const GET_FFAGENT_ADDRESS_REQ = 'GET_FFAGENT_ADDRESS_REQ';
export const GET_FFAGENT_ADDRESS_SUCCESS = 'GET_FFAGENT_ADDRESS_SUCCESS';
export const GET_FFAGENT_ADDRESS_FAIL = 'GET_FFAGENT_ADDRESS_FAIL';

export const GET_FFAGENT_ADDRESS2_REQ = 'GET_FFAGENT_ADDRESS2_REQ';
export const GET_FFAGENT_ADDRESS2_SUCCESS = 'GET_FFAGENT_ADDRESS2_SUCCESS';
export const GET_FFAGENT_ADDRESS2_FAIL = 'GET_FFAGENT_ADDRESS2_FAIL';

export const SET_FFAGENT_ADDRESS_DATA = 'SET_FFAGENT_ADDRESS_DATA';
export const SET_FFAGENT_ADDRESS_DATA1 = 'SET_FFAGENT_ADDRESS_DATA1';
export const SET_FFAGENT_ADDRESS_DATA2 = 'SET_FFAGENT_ADDRESS_DATA2';
