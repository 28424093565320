export const dataEntryNavConfig = [
  // Airfare
  {
    id: 'airfareRates',
    title: 'Airfare',
    messageId: 'sidebar.superAdminAirfareRates',
    type: 'item',
    // icon: '/assets/images/sideMenu/airfare.png',
    icon: ['/assets/images/sideMenu/airfare-active.png', '/assets/images/sideMenu/airfare.png'],
    role: ['ROLE_DATAENTRY'],
    url: [
      '/data-entry/airfare/create',
      '/data-entry/airfare/upload',
      '/data-entry/airfare/list/view',
    ]
    // children: [
    //   {
    //     id: 'createAirfareRates',
    //     title: 'Create',
    //     messageId: 'sidebar.superAdminAirfareRates.create',
    //     type: 'item',
    //     url: ['/data-entry/airfare/create'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    //   {
    //     id: 'uploadAirfare',
    //     title: 'Upload',
    //     messageId: 'sidebar.superAdminAirfareRates.upload',
    //     type: 'item',
    //     url: ['/data-entry/airfare/upload'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    //   {
    //     id: 'airfareRates',
    //     title: 'List',
    //     messageId: 'sidebar.superAdminAirfareRates.list',
    //     type: 'item',
    //     url: ['/data-entry/airfare/list/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    // ],
  },

  // Tracking
  // {
  //   id: 'trackingManagement',
  //   title: 'Tracking',
  //   messageId: 'sidebar.trackingManagement',
  //   type: 'collapse',
  //   icon: ['/assets/images/sideMenu/tracking-active.png', '/assets/images/sideMenu/tracking.png'],
  //   role: ['ROLE_DATAENTRY'],
  //   children: [],
  // },

  // Documents
  // {
  //   id: 'documentsManagement',
  //   title: 'Documents',
  //   messageId: 'sidebar.documentsManagement',
  //   type: 'collapse',
  //   icon: ['/assets/images/sideMenu/documents-active.png', '/assets/images/sideMenu/documents.png'],
  //   role: ['ROLE_DATAENTRY'],
  //   children: [],
  // },

  // Statistics
  {
    id: 'statistics',
    title: 'Statistics',
    messageId: 'sidebar.statistics',
    type: 'item',
    icon: ['/assets/images/sideMenu/statistics-active.png', '/assets/images/sideMenu/statistics.png'],
    role: ['ROLE_DATAENTRY'],
    url: [
      '/data-entry/airfare/statistics',
    ]
    // children: [
    //   {
    //     id: 'statistics',
    //     title: 'Statistics',
    //     messageId: 'sidebar.statistics.statistics',
    //     type: 'item',
    //     url: ['/data-entry/airfare/statistics'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    // ],
  },

  // Notifications
  {
    id: 'notification',
    title: 'Notifications',
    messageId: 'sidebar.notification',
    type: 'item',
    icon: ['/assets/images/sideMenu/notification-active.png', '/assets/images/sideMenu/notification.png'],
    role: ['ROLE_DATAENTRY'],
    url: [
      '/data-entry/notification',
    ]
    // children: [
    //   {
    //     id: 'notification',
    //     title: 'Notifications',
    //     messageId: 'sidebar.notification',
    //     type: 'item',
    //     url: ['/data-entry/notification'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    // ],
  },

  // Tutorial
  {
    id: 'ytTutorial',
    title: 'Tutorial',
    messageId: 'sidebar.ytTutorial',
    type: 'item',
    icon: ['/assets/images/sideMenu/youtube-active.png', '/assets/images/sideMenu/youtube.png'],
    url: ['/data-entry/tutorial/view'],
    role: ['ROLE_DATAENTRY'],
  },
];
