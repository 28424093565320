const initialState = {
  agent_list: [],
};

export const retrieveFreightForwarderAgentListReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case 'FREIGHT_FORWARDER_AGENT_LIST':
      return {
        ...state,
        agent_list: [...payload],
      };

    default:
      return {
        ...state,
      };
  }
};
