export const airlineAgentNavConfig = [
  // Settings
  {
    id: 'profileManagement',
    title: 'Settings',
    messageId: 'sidebar.profile',
    type: 'item',
    // icon: '/assets/images/sideMenu/settings.png',
    icon: ['/assets/images/sideMenu/settings-active.png', '/assets/images/sideMenu/settings.png'],
    role: ['ROLE_AIRLINE_AGENT'],
    url: ['/airline/agent/profile/update'],
    // children: [
    //   {
    //     id: 'profile',
    //     title: 'View Profile',
    //     messageId: 'sidebar.adminProfile.update',
    //     type: 'item',
    //     url: ['/airline/agent/profile/update'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png'],
    //   },
    // ],
  },

  // History
  {
    id: 'historyManagement',
    title: 'History',
    messageId: 'sidebar.history',
    type: 'item',
    // icon: '/assets/images/sideMenu/history.png',
    icon: ['/assets/images/sideMenu/history-active.png', '/assets/images/sideMenu/history.png'],
    role: ['ROLE_AIRLINE_AGENT'],
    url: ['/airline/agent/history/list',
      '/airline/agent/history/view/'],
    // children: [
    //   {
    //     id: 'profile',
    //     title: 'History List',
    //     messageId: 'sidebar.history.list',
    //     type: 'item',
    //     url: ['/airline/agent/history/list'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png'],
    //   },
    // ],
  },

  // General Rate
  {
    id: 'generalRate',
    title: 'General Rate',
    messageId: 'sidebar.generalRate',
    type: 'item',
    // icon: '/assets/images/sideMenu/contract-rate.png',
    icon: ['/assets/images/sideMenu/contract-rate-active.png', '/assets/images/sideMenu/contract-rate.png'],
    role: ['ROLE_AIRLINE_AGENT'],
    url: ['/airline/agent/general-rate/create',
      '/airline/agent/general-rate/upload-rates',
      '/airline/agent/general-rate/list/view',
      '/airline/agent/general-rate/edit/'],
    // children: [
    //   {
    //     id: 'createGeneralRates',
    //     title: 'Create',
    //     messageId: 'sidebar.airline.createGeneralRates',
    //     type: 'item',
    //     url: ['/airline/agent/general-rate/create'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    //   {
    //     id: 'generalRateList',
    //     title: 'List',
    //     messageId: 'sidebar.airline.generalRateList',
    //     type: 'item',
    //     url: ['/airline/agent/general-rate/list/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png'],
    //   },
    // ],
  },

  // Priority Rate
  {
    id: 'priorityRate',
    title: 'Priority Rate',
    messageId: 'sidebar.priorityRate',
    type: 'item',
    // icon: '/assets/images/sideMenu/contract-rate.png',
    icon: ['/assets/images/sideMenu/contract-rate-active.png', '/assets/images/sideMenu/contract-rate.png'],
    role: ['ROLE_AIRLINE_AGENT'],
    url: ['/airline/agent/priority-rate/create',
      '/airline/agent/priority-rate/upload-rates',
      '/airline/agent/priority-rate/list/view',
      '/airline/agent/priority-rate/edit/'],
    // children: [
    //   {
    //     id: 'createPriorityRates',
    //     title: 'Create',
    //     messageId: 'sidebar.airline.createPriorityRates',
    //     type: 'item',
    //     url: ['/airline/agent/priority-rate/create'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    //   {
    //     id: 'priorityRates',
    //     title: 'List',
    //     messageId: 'sidebar.airline.priorityRates',
    //     type: 'item',
    //     url: ['/airline/agent/priority-rate/list/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    // ],
  },

  // Spot Rate
  {
    id: 'spotRate',
    title: 'Promo Rate',
    messageId: 'sidebar.spotRate',
    type: 'item',
    // icon: '/assets/images/sideMenu/contract-rate.png',
    icon: ['/assets/images/sideMenu/contract-rate-active.png', '/assets/images/sideMenu/contract-rate.png'],
    role: ['ROLE_AIRLINE_AGENT'],
    url: ['/airline/agent/spot-rate/create',
      '/airline/agent/spot-rate/upload-rates',
      '/airline/agent/spot-rate/list/view',
      '/airline/agent/spot-rate/edit/'],
    // children: [
    //   {
    //     id: 'createSpotRate',
    //     title: 'Create',
    //     messageId: 'sidebar.airline.createSpotRate',
    //     type: 'item',
    //     url: ['/airline/agent/spot-rate/create'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    //   {
    //     id: 'spotRates',
    //     title: 'List',
    //     messageId: 'sidebar.airline.spotRates',
    //     type: 'item',
    //     url: ['/airline/agent/spot-rate/list/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    // ],
  },

  // Weight Range
  {
    id: 'weightRange',
    title: 'Weight Range',
    messageId: 'sidebar.weightRange',
    type: 'item',
    // icon: '/assets/images/sideMenu/weight-range.png',
    icon: ['/assets/images/sideMenu/weight-range-active.png', '/assets/images/sideMenu/weight-range.png'],
    role: ['ROLE_AIRLINE_AGENT'],
    url: ['/airline/agent/wieght-range/update'],
    // children: [
    //   {
    //     id: 'updateWeightRange',
    //     title: 'List',
    //     messageId: 'sidebar.airline.updateWeightRange',
    //     type: 'item',
    //     url: ['/airline/agent/wieght-range/update'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png'],
    //   },
    // ],
  },

  // Surcharge
  {
    id: 'surCharge',
    title: 'Surcharge',
    messageId: 'sidebar.surCharge',
    type: 'item',
    // icon: '/assets/images/sideMenu/surcharge.png',
    icon: ['/assets/images/sideMenu/surcharge-active.png', '/assets/images/sideMenu/surcharge.png'],
    role: ['ROLE_AIRLINE_AGENT'],
    url: ['/airline/agent/sur-charge/update'],
    // children: [
    //   {
    //     id: 'updateSurCharge',
    //     title: 'List',
    //     messageId: 'sidebar.airline.updateSurCharge',
    //     type: 'item',
    //     url: ['/airline/agent/sur-charge/update'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png'],
    //   },
    // ],
  },

  // Tracking
  // {
  //   id: 'trackingManagement',
  //   title: 'Tracking',
  //   messageId: 'sidebar.trackingManagement',
  //   type: 'collapse',
  //   icon: '/assets/images/sideMenu/tracking.png',
  //   role: ['ROLE_AIRLINE_AGENT'],
  //   children: [],
  // },

  // Notifications
  {
    id: 'notification',
    title: 'Notifications',
    messageId: 'sidebar.notification',
    type: 'item',
    // icon: '/assets/images/sideMenu/notification.png',
    icon: ['/assets/images/sideMenu/notification-active.png', '/assets/images/sideMenu/notification.png'],
    role: ['ROLE_AIRLINE_AGENT'],
    url: ['/airline/agent/notification'],
    // children: [
    //   {
    //     id: 'notification',
    //     title: 'Notifications',
    //     messageId: 'sidebar.notification',
    //     type: 'item',
    //     url: ['/airline/agent/notification'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png'],
    //   },
    // ],
  },

  // Tutorial
  {
    id: 'ytTutorial',
    title: 'Tutorial',
    messageId: 'sidebar.ytTutorial',
    type: 'item',
    icon: ['/assets/images/sideMenu/youtube-active.png', '/assets/images/sideMenu/youtube.png'],
    url: ['/airline/agent/tutorial/view'],
    role: ['ROLE_AIRLINE_AGENT'],
  },
];
