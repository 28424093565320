import {
  GET_ECONOMICS_RANGES_REQ,
  GET_ECONOMICS_RANGES_SUCCESS,
  GET_ECONOMICS_RANGES_FAIL,
  UPDATE_ECONOMICS_RANGE_REQ,
  UPDATE_ECONOMICS_RANGE_SUCCESS,
  UPDATE_ECONOMICS_RANGE_FAIL,
  CREATE_ECONOMICS_RANGE_REQ,
  CREATE_ECONOMICS_RANGE_SUCCESS,
  CREATE_ECONOMICS_RANGE_FAIL,
  DEL_ECONOMICS_RANGE_REQ,
  DEL_ECONOMICS_RANGE_SUCCESS,
  DEL_ECONOMICS_RANGE_FAIL,
  GET_SINGLE_ECONOMICS_SUCCESS
} from '../constants';

const INIT_STATE = {
  loading: false,
  airlineRangeList: [],
  insuranceRangeList: [],
  truckingRangeList: [],
  economicRages:{}

};

const economicsRangesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //   Listeners for Get action
    case GET_ECONOMICS_RANGES_REQ:
      return {
        ...state,
        loading: true,
      };

    case 'RETRIEVE_AIRLINE_RANGE_LIST':
      return {
        ...state,
        loading: false,
        airlineRangeList: [...action.payload],
      };

    case 'RETRIEVE_TRUCKING_RANGE_LIST':
      return {
        ...state,
        loading: false,
        truckingRangeList: [...action.payload],
      };

    case 'RETRIEVE_INSURANCE_RANGE_LIST':
      return {
        ...state,
        loading: false,
        insuranceRangeList: [...action.payload],
      };
    case GET_ECONOMICS_RANGES_FAIL:
      return {
        ...state,
        loading: false,
      };
    //   Listeners for Create action
    case CREATE_ECONOMICS_RANGE_REQ:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ECONOMICS_RANGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_ECONOMICS_RANGE_FAIL:
      return {
        ...state,
        loading: false,
      };
    //   Listeners for Update action
    case UPDATE_ECONOMICS_RANGE_REQ:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ECONOMICS_RANGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ECONOMICS_RANGE_FAIL:
      return {
        ...state,
        loading: false,
      };
    //   Listeners for Delete action
    case DEL_ECONOMICS_RANGE_REQ:
      return {
        ...state,
        loading: true,
      };
    case DEL_ECONOMICS_RANGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DEL_ECONOMICS_RANGE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SINGLE_ECONOMICS_SUCCESS:
      return {
        ...state,
        economicRages:action.payload
      }
    default:
      return state;
  }
};
export default economicsRangesReducer;
