export const phoneRegExp = /^((\\+[1-9]{1,9}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const dateRegExp = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

export const priceRexExp = /^(\d{1,5}|\d{0,5}\.\d{1,2})$/;

export const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export const convertStrToNum = (str) => parseFloat(str.replace(/,/g, ''));

export  const handleInputChange = (event,setValue) => {
      const inputValue = event.target.value;
        if (!isNaN(inputValue)) {
        setValue(inputValue);
      }  
    };

export  const handleKeyPress = (event) => {
      if (!/^[0-9\b]+$/.test(event.key)) {
        event.preventDefault();
      }
    };

export const   totalAmountofAddOn =(price, days, user)=> {
  const total = parseFloat(price * days * Number(user))
  return `$${(total.toLocaleString('en-US', { minimumFractionDigits: 2 }))}`
}