// Economics

export const GET_AGENT_REQ = 'GET_AGENT_REQ';
export const GET_AGENT_SUCCESS = 'GET_AGENT_SUCCESS';
export const GET_AGENT_FAIL = 'GET_AGENT_FAIL';

export const CREATE_ECONOMICS_REQ = 'CREATE_ECONOMICS_REQ';
export const CREATE_ECONOMICS_SUCCESS = 'CREATE_ECONOMICS_SUCCESS';
export const CREATE_ECONOMICS_FAIL = 'CREATE_ECONOMICS_FAIL';

export const GET_ECONOMICS_REQ = 'GET_ECONOMICS_REQ';
export const GET_ECONOMICS_SUCCESS = 'GET_ECONOMICS_SUCCESS';
export const GET_ECONOMICS_FAIL = 'GET_ECONOMICS_FAIL';

export const GET_SINGLE_ECONOMICS_REQ = 'GET_SINGLE_ECONOMIC_REQ';
export const GET_SINGLE_ECONOMICS_SUCCESS = 'GET_SINGLE_ECONOMICS_SUCCESS';
export const GET_SINGLE_ECONOMICS_FAIL = 'GET_SINGLE_ECONOMICS_FAIL';

export const GET_SINGLE_ECONOMICS_SUCCESS_SURCHARGES = 'GET_SINGLE_ECONOMICS_SUCCESS_SURCHARGES';
export const GET_SINGLE_ECONOMICS_FAIL_SURCHARGES = 'GET_SINGLE_ECONOMICS_FAIL_SURCHARGES';

export const GET_CREATE_GROUP_REQ = 'GET_CREATE_GROUP_REQ';
export const GET_CREATE_GROUP_SUCCESS = 'GET_CREATE_GROUP_SUCCESS';
export const GET_CREATE_GROUP_FAIL = 'GET_CREATE_GROUP_FAIL';

export const GET_GROUP_LIST_REQ = 'GET_GROUP_LIST_REQ';
export const GET_GROUP_LIST_SUCCESS = 'GET_GROUP_LIST_SUCCESS';
export const GET_GROUP_LIST_FAIL = 'GET_GROUP_LIST_FAIL';


