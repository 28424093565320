import {
  POST_FFADMIN_QUOTATIONS_PRICE_REQ,
  POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS,
  POST_FFADMIN_QUOTATIONS_PRICE_FAIL,
  GET_FFADMIN_QUOTATIONS_REQ,
  GET_FFADMIN_QUOTATIONS_SUCCESS,
  GET_FFADMIN_QUOTATIONS_FAIL,
  GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_REQ,
  GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_SUCCESS,
  GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_FAIL,
  GET_FFADMIN_QUOTATION_REQ,
  GET_FFADMIN_QUOTATION_SUCCESS,
  GET_FFADMIN_QUOTATION_FAIL,
  UPDATE_FFADMIN_QUOTATION_REQ,
  UPDATE_FFADMIN_QUOTATION_SUCCESS,
  UPDATE_FFADMIN_QUOTATION_FAIL,
  DELETE_FFADMIN_QUOTATION_REQ,
  DELETE_FFADMIN_QUOTATION_SUCCESS,
  DELETE_FFADMIN_QUOTATION_FAIL,
  CREATE_FFADMIN_QUOTATION_REQ,
  CREATE_FFADMIN_QUOTATION_SUCCESS,
  CREATE_FFADMIN_QUOTATION_FAIL,
  GET_FFADMIN_SEARCH_ADDRESS_REQ,
  GET_FFADMIN_SEARCH_ADDRESS_SUCCESS,
  GET_FFADMIN_SEARCH_ADDRESS_FAIL,
  GET_FFADMIN_SEARCH_ADDRESS2_REQ,
  GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS,
  GET_FFADMIN_SEARCH_ADDRESS2_FAIL,
  GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_REQ,
  GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS,
  GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_FAIL,
  GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS_SAVE_RECORD,
  GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_REQ,
  GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_SUCCESS,
  GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_FAIL,
  GET_REQUEST_QUOTE_REQ,
  GET_REQUEST_QUOTE_SUCCESS,
  GET_REQUEST_QUOTE_FAIL,
  GET_SHIP_FROM_AIRPORT_REQ,
  GET_SHIP_FROM_AIRPORT_SUCCESS,
  GET_SHIP_FROM_AIRPORT_FAIL,
  GET_SHIP_TO_AIRPORT_REQ,
  GET_SHIP_TO_AIRPORT_SUCCESS,
  GET_SHIP_TO_AIRPORT_FAIL,
  GET_COUNTRY_REQ,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAIL,
  GET_SHIP_TO_QUICK_AIRPORT_REQ,
  GET_SHIP_TO_QUICK_AIRPORT_SUCCESS,
  GET_SHIP_TO_QUICK_AIRPORT_FAIL,
  GET_SEARCH_BY_ZIP_REQ,
  GET_SEARCH_BY_ZIP_SUCCESS,
  GET_SEARCH_BY_ZIP_FAIL,
  CHECK_QUOTE_AVAILABLE_REQ,
  CHECK_QUOTE_AVAILABLE_SUCCESS,
  CHECK_QUOTE_AVAILABLE_FAIL,
  SET_QUOTATION_DATA,
  SEND_QUOTE_BY_EMAIL_REQ,
  SEND_QUOTE_BY_EMAIL_SUCCESS,
  SEND_QUOTE_BY_EMAIL_FAIL,
  GET_AIRPORT_DEPATURE_LIST,
  STORE_RADIO_OPTION,
  GET_EMPTY_COUNTRY_LIST,
  POST_FFADMIN_QUOTATION_PDF_REQ,
  POST_FFADMIN_QUOTATION_PDF_SUCCESS,
  POST_FFADMIN_QUOTATION_PDF_FAIL,
  GET_UPDATED_QUOTATION_LIST,
  GET_SHIPPER_LIST_REQ,
  GET_SHIPPER_LIST_SUCCESS,
  GET_SHIPPER_LIST_FAIL,
  SAVE_QUOTATION_DATA,
  SAVE_CHECKBOX_DATA,
  EDIT_SAVE_QUOTATION,
  EDIT_QUOTATION_REQ,
  EDIT_QUOTATION_SUCCESS,
  EDIT_QUOTATION_FAIL,
  SET_PREVIOUS_STATE,
  SET_UPDATE_PREVIOUS_STATE,
  SET_QUOTATION_UPDATE_UNIT,
  SET_SHIPPER_QUOTATION_REQ,
  SET_SHIPPER_QUOTATION_SUCCESS,
  SET_SHIPPER_QUOTATION_FAIL,
  GET_TRUKING_DATA,
  GET_RECALCULATE_VALUE,
  GET_DELIVERY_ZIPCODE_REQ,
  GET_DELIVERY_ZIPCODE_SUCCESS,
  GET_DELIVERY_ZIPCODE_FAIL,
  GET_UPDATE_DELIVERY_ZIPCODE_REQ,
  GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS,
  GET_UPDATE_DELIVERY_ZIPCODE_FAIL,
  GET_UPDATED_PICKUP_ZIPCODE_REQ,
  GET_UPDATED_PICKUP_ZIPCODE_SUCCESS,
  GET_UPDATED_PICKUP_ZIPCODE_FAIL,
  GET_UNASSIGNED_LIST_REQ,
  GET_UNASSIGNED_LIST_SUCCESS,
  GET_UNASSIGNED_LIST_FAIL,
  GET_ASSIGNED_LIST_REQ,
  GET_ASSIGNED_LIST_SUCCESS,
  GET_ASSIGNED_LIST_FAIL,
  SET_CREATE_SHIPPER_QUOTATION_REQ,
  SET_CREATE_SHIPPER_QUOTATION_FAIL,
  SET_CREATE_SHIPPER_QUOTATION_SUCCESS,
  SET_INCOMING_SHIPPER_QUOTATION_REQ,
  SET_INCOMING_SHIPPER_QUOTATION_SUCCESS,
  SET_INCOMING_SHIPPER_QUOTATION_FAIL,
  GET_USER_KEY_MANAGEMENT_SUCCESS,
  GET_KEY_COUNT_SUCCESS,
  GET_KEY_USER_ROLE_SELECT_SUCCESS,
  GET_NO_0F_QUOTATION,
  GET_KEY_MANAGEMENT_ASSIGNED_KEY_SUCCESS,
  GET_KEY_MANAGEMENT_ASSIGNED_KEY_REQ,
  GET_KEY_MANAGEMENT_ASSIGNED_KEY_FAIL,
  GET_KEY_MANAGEMENT_UNASSIGNED_KEY_REQ,
  GET_KEY_MANAGEMENT_UNASSIGNED_KEY_SUCCESS,
  GET_KEY_MANAGEMENT_UNASSIGNED_KEY_FAIL,
  GET_CLIENT_QUOTATION,
  GET_INCOMING_COUNT,
  GET_INCOMING_LIST_SUCCESS,
  GET_INCOMING_LIST_REQ,
  GET_INCOMING_LIST_FAIL,
  GET_INCOMING_QUOTATIONS_DETAIL_REQ,
  GET_INCOMING_QUOTATIONS_DETAIL_SUCCESS,
  GET_INCOMING_QUOTATIONS_DETAIL_FAIL,
  GET_QUOTEANALYS_QUOTAION_LIST_REQ,
  GET_QUOTEANALYS_QUOTAION_LIST_SUCCESS,
  GET_QUOTEANALYS_QUOTAION_LIST_FAIL,
  GET_QUOTEANALYST_QUOTATION_DETAILS_REQ,
  GET_QUOTEANALYST_QUOTATION_DETAILS_SUCCESS,
  GET_QUOTEANALYST_QUOTATION_DETAILS_FAIL,
  GET_ANALYST_USER_FILTER_VALUE,
  DOWNLOAD_STATUS_OF_IMAGE_FAIL,
  DOWNLOAD_STATUS_OF_IMAGE_REQ,
  DOWNLOAD_STATUS_OF_IMAGE_SUCCES,
  GET_FEEDBACK_LIST_SUCCESS,
  GET_FEED_BACK_LIST_REQ,
  GET_FEEDBACL_LIST_FAIL,
  GET_DISCLAIMER_DATA,
  GET_PRINT_LOADER_STATUS,
  GET_EXPORT_LOADER_STATUS,
  GET_EXPORT_LOADER_STATUS_SUCCESS,
  GET_EXPORT_LOADER_STATUS_FAILURE,
  RESET_STATE,
  GET_FFADMIN_REQ_QUOTEFORMODEL_LIST,
  GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST,
  GET_FFADMIN_FAIL_QUOTEFORMODEL_LIST,
  GET_SAVE_AS_NEW_QUOTATION,
  UPDATE_QUOTATION_RESULT_DATA,
  GET_DEPARTURE_ZIPCODE,
  UPDATE_VALUE_FOR_STATUS,
  GET_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME,
  GET_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME_SUCCESS,
  GET_ABORT_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME,
  SET_VERSION_NUMBER,
  GET_VERSION_ID_DATA,
  GET_AIRLINE_LIST,
  GET_AIRLINE_LIST1,
  GET_MANUAL_QUOTATION_REQ,
  GET_MANUAL_QUOTATION_SUCCESS,
  GET_MANUAL_QUOTATION_FAIL,
  SAVE_DATA_FROM_NEW_QUOTE,
  REMOVE_SAVE_DATA_FROM_NEW_QUOTE,
  GET_ABOUT_VERSION_CONTROL_ALL_DATA,
  GET_ABOUT_VERSION_CONTROL_DATA_BY_ID,
  GET_LOADER_STATUS,
  PRINT_MANUAL_QUOTATION_REQ,
  PRINT_MANUAL_QUOTATION_SUCCESS,
  PRINT_MANUAL_QUOTATION_FAIL,
  SAVE_MANUAL_QUOTATION_SUCCESS,
  SAVE_MANUAL_QUOTATION_REQ,
  SAVE_MANUAL_QUOTATION_FAIL,
  SHARE_MANUAL_QUOTATION_REQ,
  SHARE_MANUAL_QUOTATION_SUCCESS,
  SHARE_MANUAL_QUOTATION_FAIL,
  PRINT_MANUAL_QUOTATION_DETAIL_FAIL,
  PRINT_MANUAL_QUOTATION_DETAIL_SUCCESS,
  PRINT_MANUAL_QUOTATION_DETAIL_REQ,
  SHARE_MANUAL_QUOTATION_DETAIL_REQ,
  SHARE_MANUAL_QUOTATION_DETAIL_SUCCESS,
  SHARE_MANUAL_QUOTATION_DETAIL_FAIL,
  CREATE_TRUKCING_QUOTATION_REQ,
  CREATE_TRUKCING_QUOTATION_SUCCESS,
  CREATE_TRUKCING_QUOTATION_FAIL,
  SET_LOGGED_IN,
  SET_LOGGED_OUT,
  SAVE_AVAILABILITY_MODAL_ACCEPTED,
  SAVED_QUOTATION_ID,
  GET_UNIT_MEASUREMENT,
  GET_MEASUREMENT_SYSYTEM_REQ,
  GET_MEASUREMENT_SYSYTEM_SUCCESS,
  GET_MEASUREMENT_SYSYTEM_FAIL,
  MEASUREMENTS_SYSYTEM_REQ,
  MEASUREMENTS_SYSYTEM_SUCCESS,
  MEASUREMENTS_SYSYTEM_FAIL,
  SET_MEASUREMENTS_SYSYTEM_REQ,
  SET_MEASUREMENTS_SYSYTEM_SUCCESS,
  SET_MEASUREMENTS_SYSYTEM_FAIL,
  MEASUREMENTS_SYSYTEM_REQ_PDF,
  MEASUREMENTS_SYSYTEM_SUCCESS_PDF,
  MEASUREMENTS_SYSYTEM_FAIL_PDF,
  SET_MEASUREMENTS_SYSYTEM_REQ_PDF,
  SET_MEASUREMENTS_SYSYTEM_SUCCESS_PDF,
  SET_MEASUREMENTS_SYSYTEM_FAIL_PDF,
  SET_FAVROUITE_AIRPORT_REQ,
  SET_FAVROUITE_AIRPORT_SUCCESS,
  SET_FAVROUITE_AIRPORT_FAIL,
  GET_PREFERRED_AIRPORTS_LIST,
  GET_INCOMING_MANAULQUOTE_DETAILS_REQ,
  GET_INCOMING_MANAULQUOTE_DETAILS_SUCCESS,
  GET_INCOMING_MANAULQUOTE_DETAILS_FAIL,
  GET_INCOMING_AGENT_LIST_REQ,
  GET_INCOMING_AGENT_LIST_SUCCESS,
  GET_INCOMING_AGENT_LIST_FAIL,
  RENEW_ADD_ON_REQ,
  RENEW_ADD_ON_SUCCESS,
  RENEW_ADD_ON_FAIL,
  PAYPAL_STATUS,
  SAVE_TRANSACTION_ID,
  SAVE_ADDONS_DATA,
  HANDLE_BACK_BUTTON_IN_KEYMANAGEMENT,
  GET_HOST_REASSIGN_DETAILS_REQ,
  GET_HOST_REASSIGN_DETAILS_SUCCESS,
  GET_HOST_REASSIGN_DETAILS_FAIL,
} from '../constants';

const INIT_STATE = {
  quotationList: [],
  quotationInquirerDetailsByID: [],
  userSelection: '',
  loading: false,
  singleQuotation: {},
  singleQuotation2: {},
  addressResult: [],
  addressResult2: [],
  inquirerNamesByCompanyName: [],
  inquirerNamesByCompanyNameFinal: [],
  inquirerDetailsNamesByInquirerName: [],
  reqQuoteList: [],
  shipFromAirportList: [],
  shipToAirportList: [],
  quotationResult: {},
  // shipperQuotataionCreateDetail: {},
  countryList: [],
  shipToQuickAirportList: [],
  addressByZip: [],
  stateData: [],
  storedQuotation: {},
  airportOfDepartureList: [],
  pdfrequest: false,
  pdfData: [],
  quotationListLoading: true,
  updatedQuotation: [],
  shipperLoading: true,
  shipperData: [],
  saveQuotation: {},
  checkBoxOption: {},
  date: '',
  unit: '',
  editSaveQuotaion: {},
  editSaveQuotationLoading: false,
  departure: '',
  destination: '',
  truckingCarriersDummyVariable: [],
  previousUnit: 'Imperial / Metric',
  getUpdatePreviousUnit: '',
  getQuoteUpdateUnit: '',
  shipperQuotation: {},
  // shipperQuotataionCreateDetail: {},
  statusFromUpdate: false,
  preAirportOfDepatureDetails: '',
  preAirportOfDestinationDetails: '',
  deliveryAdressByzip: [],
  deliveryPlace: [],
  singleQuotationDetail: {},
  pickUpAdress: [],
  pickAdressData: [],
  updatedAddress: [],
  updatedArrayData: [],
  unassignedLoading: false,
  unassignedList: [],
  assignedListLoading: false,
  assignedList: [],
  confirmStatus: '',
  comment: '',
  listOfUser: [],
  statisticData: {},
  listOfRoleFilterData: [],
  noOfQuotaion: [],
  listOfassignData: [],
  listLoading: false,
  listOfUnassignData: [],
  unassignListLoading: false,
  shipperRequestCount: [],
  clientList: [],
  clientData: [],
  clientLoading: false,
  clientQuoteDetail: {},
  clientQuoteLoading: false,
  quoteAnlystQuotationList: [],
  quoteAnalystQuotationDetail: {},
  quoteAnalystQotationDetailReq: false,
  quoteAnalystlist: false,
  userFilter: 'all',
  downloading: false,
  feedBackList: [],
  feedBacklistReq: false,
  disclaimer: {},
  getPrintLoaderStatus: '',
  quoteForModelList: [],
  departureAddress: [],
  retrievePrintQuotationAirlineNames: [],
  versionId: '',
  versionData: {},
  airlineList1: [],
  airlineList2: [],
  manualQuoationReq: false,
  manualQuotationData: {},
  manualQuoteData: {},
  aboutVersionControlAllData: [],
  aboutVersionControlDataByID: [],
  getLoaderStatus: '',
  commonLoaderForManual: false,
  commonLoaderForManaualQuotationDetail: false,
  truckingQuotation: {},
  truckingQuotationLoading: false,
  isLoggedIn: false,
  saveAvailabiltyModalAccepted: false,
  savedQuotationId: '',
  quoteRefrenceId: '',
  checkForSubmission: false,
  checkFormodal: false,
  isFirstSubmission: "newQuotes",
  editManualQuoteData: {},
  unitType: '',
  measurementSystem: '',
  measurementSystemPdf: '',
  favrouiteAirportList: [],
  favouriteAirportLoading: false,
  preferredAirportsListAdmin: [],
  clientManualQuote: false,
  clientManualQuoteDetails: {},
  agentListLoading: false,
  hostReassignLoading: false,
  allAgentList: [],
  hostReassignDetailsData: {},
  client_scret: '',
  renewKeyData: {

  },
  transaCationId: {},
  payPalStatus: {},
  renewAddonsData: {},
  backButtonFromKeymanagemnet: {},
  comesFrom: {},
  routingFromPayemnt: {},
  fromWhichTab: '',
  manualAirlineList:[],
  extra_surcharge_list_selected:[],
  view_details:{},
  manualSetting:{
    isAllowManualQuotesAdmin:'Yes',
    isAllowManualQuotesAgent:'No'
  }
};

const ffAdminquotationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'RESET_STATE':
      return INIT_STATE;
    case PAYPAL_STATUS:
      return {
        ...state,
        payPalStatus: action.payload
      }
    case "RETRIEVE_EXTRA_SURCHARGE_LIST":
      return {
        ...state,
        extra_surcharge_list_selected:[...action.payload]

      }
      case "VIEW_AIRRATES_DETAILS":
      return {
        ...state,
        view_details:action.payload

      }
    case SAVE_TRANSACTION_ID:
      return {
        ...state,
        transaCationId: action.payload
      }
    case SAVE_ADDONS_DATA:
      return {
        ...state,
        renewAddonsData: action.payload

      }
    
    case 'MANAUL_SETTING':
      return{
        ...state,
        manualSetting:{...action.payload,isAllowManualQuotesAdmin:action.payload.isAllowManualQuotesAdmin,isAllowManualQuotesAgent:action.payload.isAllowManualQuotesAgent}
      }
    case "SAVE_PAYMENT_STAE":
      return {
        ...state,
        routingFromPayemnt: action.payload
      }
      case "MANUAL_AIRLINE_LIST":
        return {
          ...state,
          manualAirlineList: action.payload
        }
    case 'SAVE_FROM_WHICH_TAB':
      return {
        ...state,
        fromWhichTab: action.payload
      }

    case "SAVE_FOR_BACK_BUTTON_HANDLE":
      return {
        ...state,
        comesFrom: action.payload
      }

    case HANDLE_BACK_BUTTON_IN_KEYMANAGEMENT:
      return {
        ...state,
        backButtonFromKeymanagemnet: action.payload
      }

    case SAVED_QUOTATION_ID:
      return {
        ...state,
        savedQuotationId: action.payload
      }
    case GET_UNIT_MEASUREMENT:
      return {
        ...state,
        unitType: action.payload
      }
    case 'SAVE_REFRENCE_ID':
      return {
        ...state,
        quoteRefrenceId: action.payload
      }
    case 'EDIT_MANUAL_QUOATION':
      return {
        ...state,
        editManualQuoteData: action.payload
      }
    case 'EMPTY_REFRENCE_ID':
      return {
        ...state,
        quoteRefrenceId: action.payload
      }
    case 'SAVE_DATA_FROM_NEW_QUOTE':
      return {
        ...state,
        manualQuoteData: action.payload,
      };

    case 'UPDATE_FOR_FIRST_TIME':
      return {
        ...state,
        isFirstSubmission: action.payload,
      };


    case 'UPDATE_SUBMIT':
      return {
        ...state,
        checkForSubmission: action.payload
      }
    case 'UPDATE_MODAL':
      return {
        ...state,
        checkFormodal: action.payload,
      };


    case 'SAVE_AVAILABILITY_MODAL_ACCEPTED':
      return {
        ...state,
        saveAvailabiltyModalAccepted: action.payload,
      };

    case PRINT_MANUAL_QUOTATION_REQ:
      return {
        ...state,
        commonLoaderForManual: true,
      };
    case PRINT_MANUAL_QUOTATION_SUCCESS:
      return {
        ...state,
        commonLoaderForManual: false,
        // stateData: action.payload.places,
      };
    case PRINT_MANUAL_QUOTATION_FAIL:
      return {
        ...state,
        commonLoaderForManual: false,
      };

    case CREATE_TRUKCING_QUOTATION_REQ:
      return {
        ...state,
        truckingQuotationLoading: true,
      };
    case CREATE_TRUKCING_QUOTATION_SUCCESS:
      return {
        ...state,
        truckingQuotationLoading: false,
        truckingQuotation: action.payload,
      };
    case CREATE_TRUKCING_QUOTATION_FAIL:
      return {
        ...state,
        truckingQuotationLoading: false,
      };



    case SAVE_MANUAL_QUOTATION_REQ:
      return {
        ...state,
        commonLoaderForManual: true,
      };
    case SAVE_MANUAL_QUOTATION_SUCCESS:
      return {
        ...state,
        commonLoaderForManual: false,
        // stateData: action.payload.places,
      };
    case SAVE_MANUAL_QUOTATION_FAIL:
      return {
        ...state,
        commonLoaderForManual: false,
      };

    case SHARE_MANUAL_QUOTATION_REQ:
      return {
        ...state,
        commonLoaderForManual: true,
        clientManualQuote: true,

      };
    case SHARE_MANUAL_QUOTATION_SUCCESS:
      return {
        ...state,
        commonLoaderForManual: false,
        clientManualQuote: false,

        // stateData: action.payload.places,
      };
    case SHARE_MANUAL_QUOTATION_FAIL:
      return {
        ...state,
        commonLoaderForManual: false,
        clientManualQuote: false,
      };

    case SHARE_MANUAL_QUOTATION_DETAIL_REQ:
      return {
        ...state,
        commonLoaderForManaualQuotationDetail: true,
      };
    case SHARE_MANUAL_QUOTATION_DETAIL_SUCCESS:
      return {
        ...state,
        commonLoaderForManaualQuotationDetail: false,
        // stateData: action.payload.places,
      };
    case SHARE_MANUAL_QUOTATION_DETAIL_FAIL:
      return {
        ...state,
        commonLoaderForManaualQuotationDetail: false,
      };

    case PRINT_MANUAL_QUOTATION_DETAIL_REQ:
      return {
        ...state,
        commonLoaderForManaualQuotationDetail: true,
        clientManualQuote: true,

      };
    case PRINT_MANUAL_QUOTATION_DETAIL_SUCCESS:
      return {
        ...state,
        commonLoaderForManaualQuotationDetail: false,
        clientManualQuote: false,

        // stateData: action.payload.places,
      };
    case PRINT_MANUAL_QUOTATION_DETAIL_FAIL:
      return {
        ...state,
        commonLoaderForManaualQuotationDetail: false,
        clientManualQuote: false,
      };

    case 'REMOVE_SAVE_DATA_FROM_NEW_QUOTE':
      return {
        ...state,
        manualQuoteData: {},
      };
    case 'RETRIEVE_PRE_AIRPORT_OF_DEPARTURE_DETAILS':
      return {
        ...state,
        preAirportOfDepatureDetails: action.payload,
        requesting: false,
      };

    case GET_AIRLINE_LIST1:
      return {
        ...state,
        airlineList2: action.payload,
      };
    case GET_AIRLINE_LIST:
      return {
        ...state,
        airlineList1: action.payload,
      };
    case 'GET_VERSION_ID_DATA':
      return {
        ...state,
        versionData: action.payload,
      };

    case 'RETRIEVE_PRE_AIRPORT_OF_DESTINATION_DETAILS':
      return {
        ...state,
        preAirportOfDestinationDetails: action.payload,
        requesting: false,
      };

    case GET_MANUAL_QUOTATION_REQ:
      return {
        ...state,
        manualQuoationReq: true,
      };

    case GET_MANUAL_QUOTATION_FAIL:
      return {
        ...state,
        manualQuoationReq: false,
      };

    case GET_MANUAL_QUOTATION_SUCCESS:
      let manaulData = {};
      if (action.payload === '') {
        manaulData = {}
      }
      else {
        manaulData = action.payload?.replayQuotation
      }
      return {
        ...state,
        manualQuoationReq: false,
        manualQuotationData: manaulData,
      };

    case SET_INCOMING_SHIPPER_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    // SET_INCOMING_SHIPPER_QUOTATION_REQ,
    // SET_INCOMING_SHIPPER_QUOTATION_SUCCESS,
    // SET_INCOMING_SHIPPER_QUOTATION_FAIL

    case SET_INCOMING_SHIPPER_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_VALUE_FOR_STATUS:
      return {
        ...state,
        statusFromUpdate: false,
      };

    case SET_INCOMING_SHIPPER_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        singleQuotation: action.payload.replayQuotation,
        singleQuotationDetail: action.payload.quotation,
        confirmStatus: action.payload.confirmStatus,
        comment: action?.payload?.comment,
        clientManualQuoteDetails: action?.payload
      };

    case 'UPDATE_STATUS':
      return {
        ...state,
        confirmStatus: action.payload
      }
    case SET_INCOMING_SHIPPER_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_FFADMIN_QUOTATIONS_REQ:
    case GET_FFADMIN_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_FFADMIN_QUOTATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        quotationListLoading: false,
        quotationList: action.payload,
      };

    case POST_FFADMIN_QUOTATIONS_PRICE_REQ:
      return {
        ...state,
        loading: true,
      };

    case POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        quotationList: action.payload,
      };

    case POST_FFADMIN_QUOTATIONS_PRICE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_FFADMIN_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        singleQuotation: action.payload.replayQuotation,
        singleQuotationDetail: action.payload.quotation,

        singleQuotation2: action.payload.replayQuotation1,
      };

    case GET_SAVE_AS_NEW_QUOTATION:
      return {
        ...state,
        singleQuotation: action.payload,
      };
    case GET_FFADMIN_QUOTATIONS_FAIL:
    case GET_FFADMIN_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_FFADMIN_QUOTATION_REQ:
      return {
        ...state,
        loading: true

      }
    case UPDATE_FFADMIN_QUOTATION_REQ:
    case DELETE_FFADMIN_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_FFADMIN_QUOTATION_SUCCESS:
    case DELETE_FFADMIN_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CREATE_FFADMIN_QUOTATION_SUCCESS:
      return {
        ...state,
        // truckingCarriersDummyVariable: action.payload.truckingCarriers,
        quotationResult: action.payload,
        // shipperQuotataionCreateDetail: action.payload,
        loading: false,
        statusFromUpdate: false,
      };
    case CREATE_FFADMIN_QUOTATION_FAIL:
      return {
        ...state,
        loading: false
      }
    case UPDATE_FFADMIN_QUOTATION_FAIL:
    case DELETE_FFADMIN_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_FFADMIN_SEARCH_ADDRESS_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_FFADMIN_SEARCH_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        addressResult: action.payload,
      };
    case GET_FFADMIN_SEARCH_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_FFADMIN_SEARCH_ADDRESS2_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS:
      return {
        ...state,
        loading: false,
        addressResult2: action.payload,
      };
    case GET_FFADMIN_SEARCH_ADDRESS2_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        inquirerNamesByCompanyName: action.payload,
      };
    case GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_FFADMIN_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS_SAVE_RECORD:
      return {
        ...state,
        loading: false,
        inquirerNamesByCompanyNameFinal: action.payload,
      };

    case GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        inquirerDetailsNamesByInquirerName: action.payload,
      };
    case GET_FFADMIN_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_REQUEST_QUOTE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_REQUEST_QUOTE_SUCCESS:
      return {
        ...state,
        reqQuoteList: action.payload,
        loading: false,
      };
    case GET_REQUEST_QUOTE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIP_FROM_AIRPORT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIP_FROM_AIRPORT_SUCCESS:
      return {
        ...state,
        shipFromAirportList: action.payload,
        loading: false,
      };
    case GET_SHIP_FROM_AIRPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIP_TO_AIRPORT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIP_TO_AIRPORT_SUCCESS:
      return {
        ...state,
        shipToAirportList: action.payload,
        loading: false,
      };

    case GET_SHIP_TO_AIRPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_COUNTRY_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countryList: action.payload,
      };
    case GET_COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIP_TO_QUICK_AIRPORT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIP_TO_QUICK_AIRPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        shipToQuickAirportList: action.payload,
      };
    case GET_SHIP_TO_QUICK_AIRPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SEARCH_BY_ZIP_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SEARCH_BY_ZIP_SUCCESS:
      return {
        ...state,
        loading: false,
        addressByZip: action.payload,
        // stateData: action.payload.places,
      };
    case GET_SEARCH_BY_ZIP_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CHECK_QUOTE_AVAILABLE_REQ:
      return {
        ...state,
        loading: true,
      };
    case CHECK_QUOTE_AVAILABLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHECK_QUOTE_AVAILABLE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SET_QUOTATION_DATA:
      return {
        ...state,
        storedQuotation: action.payload,
      };
    case SEND_QUOTE_BY_EMAIL_REQ:
      return {
        ...state,
        loading: true,
      };
    case SEND_QUOTE_BY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SEND_QUOTE_BY_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_AIRPORT_DEPATURE_LIST: {
      return {
        ...state,
        airportOfDepartureList: action.payload,
      };
    }
    case STORE_RADIO_OPTION: {
      return {
        ...state,
        userSelection: action.payload,
      };
    }
    case GET_EMPTY_COUNTRY_LIST: {
      return {
        ...state,
        shipToQuickAirportList: [],
      };
    }
    case POST_FFADMIN_QUOTATION_PDF_REQ: {
      return {
        ...state,
        pdfrequest: true,
      };
    }
    case SET_VERSION_NUMBER: {
      return {
        ...state,
        versionId: action.payload,
      };
    }
    case POST_FFADMIN_QUOTATION_PDF_FAIL: {
      return {
        ...state,
        pdfrequest: false,
      };
    }
    case POST_FFADMIN_QUOTATION_PDF_SUCCESS: {
      return {
        ...state,
        pdfData: action.payload,
        pdfrequest: false,
      };
    }
    case GET_UPDATED_QUOTATION_LIST: {
      return {
        ...state,
        updatedQuotation: action.payload,
      };
    }

    case GET_SHIPPER_LIST_REQ:
      return {
        ...state,
        shipperLoading: true,
      }

    case GET_SHIPPER_LIST_SUCCESS:
      return {
        // shipperLoading: false,
        ...state,
        shipperLoading: false,
        shipperData: action.payload,
        shipperQuotation: {},
        // quotationResult: {},
      };

    case SAVE_QUOTATION_DATA:
      return {
        ...state,
        saveQuotation: action.payload.data,
        date: action.payload.date,
        unit: action.payload.unit,
        departure: action.payload.airpDep,
        // destination: action.payload.airpDest,
        destination: action?.payload?.airpDest?.value ? action?.payload?.airpDest?.value : action?.payload?.airpDest,
      };

    case SAVE_CHECKBOX_DATA:
      return {
        ...state,
        checkBoxOption: action.payload,
      };
    case GET_SHIPPER_LIST_FAIL:

    case EDIT_SAVE_QUOTATION:
      return {
        ...state,
        editSaveQuotaion: action.payload,
      };

    case EDIT_QUOTATION_REQ:
      return {
        ...state,
        editSaveQuotationLoading: true,
      };

    case EDIT_QUOTATION_SUCCESS:
      return {
        ...state,
        // singleQuotation: action.payload,
        quotationResult: action.payload,

        editSaveQuotationLoading: false,
      };

    case EDIT_QUOTATION_FAIL:
      return {
        ...state,
        editSaveQuotationLoading: false,
      };

    case SET_PREVIOUS_STATE:
      return {
        ...state,
        previousUnit: action.payload,
      };

    case SET_UPDATE_PREVIOUS_STATE:
      return {
        ...state,
        getUpdatePreviousUnit: action.payload,
      };
    case SET_QUOTATION_UPDATE_UNIT:
      return {
        ...state,
        getQuoteUpdateUnit: action.payload,
      };

    case SET_SHIPPER_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };
    case SET_SHIPPER_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        shipperQuotation: action.payload,
        quotationResult: {},
      };
    case SET_SHIPPER_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case SET_CREATE_SHIPPER_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };
    case SET_CREATE_SHIPPER_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        quotationResult: action.payload,
        // shipperQuotataionCreateDetail: action.payload,
      };
    case SET_CREATE_SHIPPER_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_TRUKING_DATA:
      return {
        ...state,
        truckingCarriersDummyVariable: action.payload,
      };
    case GET_RECALCULATE_VALUE: {
      return {
        ...state,
        statusFromUpdate: true,
      };
    }

    case GET_DELIVERY_ZIPCODE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_DELIVERY_ZIPCODE_SUCCESS:
      // console.log('check for delivery zipcode', action.payload)
      return {
        ...state,
        loading: false,
        deliveryAdressByzip: action.payload,
        // deliveryPlace: action.payload.places,
      };
    case GET_DELIVERY_ZIPCODE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_UPDATE_DELIVERY_ZIPCODE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedAddress: action.payload,
        // updatedArrayData: action.payload.places,
      };
    case GET_UPDATE_DELIVERY_ZIPCODE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_UPDATED_PICKUP_ZIPCODE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_UPDATED_PICKUP_ZIPCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        pickUpAdress: action.payload,
        // pickAdressData: action.payload.places,
      };
    case GET_UPDATED_PICKUP_ZIPCODE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_UNASSIGNED_LIST_REQ:
      return {
        ...state,
        unassignedLoading: true,
      };
    case GET_UNASSIGNED_LIST_SUCCESS:
      return {
        ...state,
        unassignedLoading: false,
        unassignedList: action.payload,
      };

    case GET_UNASSIGNED_LIST_FAIL:
      return {
        ...state,
        unassignedLoading: false,
      };

    case GET_ASSIGNED_LIST_REQ:
      return {
        ...state,
        assignedListLoading: true,
      };
    case GET_ASSIGNED_LIST_SUCCESS:
      return {
        ...state,
        assignedListLoading: false,
        assignedList: action.payload,
      };

    case GET_ASSIGNED_LIST_FAIL:
      return {
        ...state,
        assignedListLoading: false,
      };

    case GET_USER_KEY_MANAGEMENT_SUCCESS:
      return {
        ...state,
        listOfUser: action.payload,
      };

    case GET_KEY_COUNT_SUCCESS:
      return {
        ...state,
        statisticData: action.payload,
      };

    case GET_KEY_USER_ROLE_SELECT_SUCCESS:
      return {
        ...state,
        listOfRoleFilterData: action.payload,
      };
    case GET_NO_0F_QUOTATION:
      return {
        ...state,
        noOfQuotaion: action.payload,
      };

    case GET_KEY_MANAGEMENT_ASSIGNED_KEY_REQ:
      return {
        ...state,
        listLoading: true,
      };
    case GET_KEY_MANAGEMENT_ASSIGNED_KEY_SUCCESS:
      return {
        ...state,
        listLoading: false,
        listOfassignData: action.payload,
      };

    case GET_KEY_MANAGEMENT_ASSIGNED_KEY_FAIL:
      return {
        ...state,
        listLoading: false,
      };

    case GET_KEY_MANAGEMENT_UNASSIGNED_KEY_REQ:
      return {
        ...state,
        unassignListLoading: true,
      };
    case GET_KEY_MANAGEMENT_UNASSIGNED_KEY_SUCCESS:
      return {
        ...state,
        unassignListLoading: false,
        listOfUnassignData: action.payload,
      };

    case GET_KEY_MANAGEMENT_UNASSIGNED_KEY_FAIL:
      return {
        ...state,
        unassignListLoading: false,
      };

    case GET_INCOMING_COUNT:
      return {
        ...state,
        shipperRequestCount: action.payload,
      };
    case GET_CLIENT_QUOTATION:
      return {
        ...state,
        clientList: action.payload,
      };

    case GET_INCOMING_LIST_REQ:
      return {
        ...state,
        clientLoading: true,
      };
    case GET_INCOMING_LIST_SUCCESS:
      return {
        ...state,
        clientLoading: false,
        clientData: action.payload,
      };
    case GET_INCOMING_LIST_FAIL:
      return {
        ...state,
        clientLoading: false,
      };

    case GET_INCOMING_QUOTATIONS_DETAIL_REQ:
      return {
        ...state,
        clientQuoteLoading: true,
      };
    case GET_INCOMING_QUOTATIONS_DETAIL_SUCCESS:
      return {
        ...state,
        clientLoading: false,
        clientQuoteDetail: action.payload,
      };
    case GET_INCOMING_QUOTATIONS_DETAIL_FAIL:
      return {
        ...state,
        clientQuoteLoading: false,
      };

    case GET_QUOTEANALYS_QUOTAION_LIST_REQ:
      return {
        ...state,
        quoteAnalystlist: true,
      };
    case GET_QUOTEANALYS_QUOTAION_LIST_SUCCESS:
      return {
        ...state,
        quoteAnalystlist: false,
        quoteAnlystQuotationList: action.payload,
      };

    case GET_QUOTEANALYS_QUOTAION_LIST_FAIL:
      return {
        ...state,
        quoteAnalystlist: false,
      };

    case GET_QUOTEANALYST_QUOTATION_DETAILS_REQ:
      return {
        ...state,
        quoteAnalystQotationDetailReq: true,
      };
    case GET_QUOTEANALYST_QUOTATION_DETAILS_SUCCESS:
      return {
        ...state,
        quoteAnalystQotationDetailReq: false,
        quoteAnalystQuotationDetail: action.payload,
      };
    case GET_QUOTEANALYST_QUOTATION_DETAILS_FAIL:
      return {
        ...state,
        quoteAnalystQotationDetailReq: false,
      };

    case DOWNLOAD_STATUS_OF_IMAGE_REQ:
      return {
        ...state,
        downloading: true,
      };
    case DOWNLOAD_STATUS_OF_IMAGE_SUCCES:
      return {
        ...state,
        downloading: false,
      };
    case DOWNLOAD_STATUS_OF_IMAGE_FAIL:
      return {
        ...state,
        downloading: false,
      };

    case GET_ANALYST_USER_FILTER_VALUE:
      return {
        ...state,
        userFilter: action.payload,
      };

    case GET_FEED_BACK_LIST_REQ:
      return {
        ...state,
        feedBacklistReq: true,
      };
    case GET_FEEDBACK_LIST_SUCCESS:
      return {
        ...state,
        feedBacklistReq: false,
        feedBackList: action.payload,
      };
    case GET_FEEDBACL_LIST_FAIL:
      return {
        ...state,
        feedBacklistReq: false,
      };
    case GET_DISCLAIMER_DATA:
      return {
        ...state,
        disclaimer: action.payload,
      };

    case GET_PRINT_LOADER_STATUS:
      return {
        ...state,
        getPrintLoaderStatus: action.payload,
      };
    case GET_EXPORT_LOADER_STATUS:
      return {
        ...state,
        loading: true,
      };
    case GET_EXPORT_LOADER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_EXPORT_LOADER_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_DEPARTURE_ZIPCODE:
      return {
        ...state,
        departureAddress: action.payload,
      };

    case GET_FFADMIN_REQ_QUOTEFORMODEL_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_FFADMIN_SUCCESS_QUOTEFORMODEL_LIST:
      return {
        ...state,
        loading: false,
        quoteForModelList: action.payload,
      };

    case GET_FFADMIN_FAIL_QUOTEFORMODEL_LIST:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_QUOTATION_RESULT_DATA:
      const quotationData = {
        ...state?.quotationResult,
        economicsRates: action?.payload?.economicsRates,
      };
      // console.log(quotationData);
      return {
        ...state,
        quotationResult: quotationData,
      };

    case GET_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME:
      return {
        ...state,
        loading: true,
      };

    case GET_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME_SUCCESS:
      return {
        ...state,
        retrievePrintQuotationAirlineNames: action.payload ? action.payload : [],
        loading: false,
      };

    case GET_ABORT_RETRIEVE_PRINT_QUOTATIONAIRLINE_NAME:
      return {
        ...state,
        loading: false,
      };

    case GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_REQ:
      return {
        ...state,
        loading: true,
        quotationInquirerDetailsByID: [],
      };

    case GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_SUCCESS:
      return {
        ...state,
        quotationInquirerDetailsByID: action.payload,
        loading: false,
      };

    case GET_FFADMIN_QUOTATIONS_DETAIL_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_LOADER_STATUS:
      return {
        ...state,
        getLoaderStatus: action.payload,
      };

    case GET_ABOUT_VERSION_CONTROL_ALL_DATA:
      return {
        ...state,
        aboutVersionControlAllData: action.payload,
      };

    case GET_ABOUT_VERSION_CONTROL_DATA_BY_ID:
      return {
        ...state,
        aboutVersionControlDataByID: action.payload,
        getLoaderStatus: 'loaded',
      };

    case MEASUREMENTS_SYSYTEM_REQ:
      return {
        ...state,
        loading: true,
      };

    case MEASUREMENTS_SYSYTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        measurementSystem: action.payload
      };

    case MEASUREMENTS_SYSYTEM_FAIL:
      return {
        ...state,
        loading: false,
        measurementSystem: ''
      };

    case SET_MEASUREMENTS_SYSYTEM_REQ:
      return {
        ...state,
        loading: true,
      };

    case SET_MEASUREMENTS_SYSYTEM_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case SET_MEASUREMENTS_SYSYTEM_FAIL:
      return {
        ...state,
        loading: false,
      };

    case MEASUREMENTS_SYSYTEM_REQ_PDF:
      return {
        ...state,
        loading: true,
      };

    case MEASUREMENTS_SYSYTEM_SUCCESS_PDF:
      return {
        ...state,
        loading: false,
        measurementSystemPdf: action.payload
      };

    case MEASUREMENTS_SYSYTEM_FAIL_PDF:
      return {
        ...state,
        loading: false,
        measurementSystemPdf: ''
      };

    case SET_MEASUREMENTS_SYSYTEM_REQ_PDF:
      return {
        ...state,
        loading: true,
      };

    case SET_MEASUREMENTS_SYSYTEM_SUCCESS_PDF:
      return {
        ...state,
        loading: false,
      };

    case SET_MEASUREMENTS_SYSYTEM_FAIL_PDF:
      return {
        ...state,
        loading: false,
      };

    case SET_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: true
      };

    case SET_LOGGED_OUT:
      return {
        ...state,
        isLoggedIn: false
      };

    case SET_FAVROUITE_AIRPORT_REQ:
      return {
        ...state,
        favouriteAirportLoading: true
      }

    case SET_FAVROUITE_AIRPORT_SUCCESS:
      return {
        ...state,
        favrouiteAirportList: action.payload,
        favouriteAirportLoading: false
      }

    case SET_FAVROUITE_AIRPORT_FAIL:
      return {
        ...state,
        favouriteAirportLoading: false
      }

    case GET_PREFERRED_AIRPORTS_LIST:
      return {
        ...state,
        preferredAirportsListAdmin: action.payload
      }

    case GET_INCOMING_MANAULQUOTE_DETAILS_REQ:
      return {
        ...state,
        clientManualQuote: true,
      };

    case GET_INCOMING_MANAULQUOTE_DETAILS_FAIL:
      return {
        ...state,
        clientManualQuote: false,
      };

    case GET_INCOMING_MANAULQUOTE_DETAILS_SUCCESS:
      // let manaulData = {};
      // if (action.payload === '') {
      //   manaulData = {}
      // }
      // else {
      //   manaulData = action.payload?.replayQuotation
      // }
      // console.log(action.payload, 'in manual quote')
      return {
        ...state,
        clientManualQuote: false,
        singleQuotation: action.payload.replayQuotation,
        singleQuotationDetail: action.payload.quotation,
        confirmStatus: action.payload.confirmStatus,
        comment: action?.payload?.comment,

        clientManualQuoteDetails: action.payload,
      };


    case GET_INCOMING_AGENT_LIST_REQ:
      return {
        ...state,
        agentListLoading: true
      }

    case GET_INCOMING_AGENT_LIST_SUCCESS:
      return {
        ...state,
        allAgentList: action.payload,
        agentListLoading: false
      }

    case GET_INCOMING_AGENT_LIST_FAIL:
      return {
        ...state,
        agentListLoading: false
      }

    case GET_HOST_REASSIGN_DETAILS_REQ:
      return {
        ...state,
        hostReassignLoading: true
      }

    case GET_HOST_REASSIGN_DETAILS_SUCCESS:
      return {
        ...state,
        hostReassignDetailsData: action.payload,
        hostReassignLoading: false
      }

    case GET_HOST_REASSIGN_DETAILS_FAIL:
      return {
        ...state,
        hostReassignLoading: false
      }

    case RENEW_ADD_ON_REQ:
      return {
        ...state
      }
    case RENEW_ADD_ON_SUCCESS:
      return {
        ...state,
        client_scret: action.payload
      }
    case RENEW_ADD_ON_FAIL:
      return {
        ...state
      }

    default:
      return state;
  }
};
export default ffAdminquotationReducer;
