const init_state = {
  requesting: true,
  extra_surcharge_list: [],
  extra_surcharge_list_module: [],
  extra_surcharge_list_selected: [],
  surcharge_list_by_airline_id: [],
  extra_general_surcharge_list: [],
  extra_dangerous_surcharge_list: [],
  extra_express_surcharge_list: [],
  extra_temp_contolled_surcharge_list: [],
  extra_perishable_surcharge_list: [],
  extra_oversized_surcharge_list: [],
  extraSurcharge: [],
  extra_surcharge_list_module_for_superadmin: [],
  manualRateList: [],

};

export const retrieveExtraSurchareListReducer = (state = init_state, { type, payload }) => {
  switch (type) {
    case 'RETRIEVE_EXTRA_SURCHARGE_LIST':
      return {
        ...state,
        extra_surcharge_list: [...payload],
        requesting: false,
      };

    case 'RETRIEVE_EXTRA_SURCHARGE':
      return {
        ...state,
        extraSurcharge: [...payload],
      };

    case 'RETRIEVE_EXTRA_SURCHARGE_LIST_MODULE':
      return {
        ...state,
        extra_surcharge_list_module: [...payload],
        extra_surcharge_list: [],
        extra_surcharge_list_selected: [],
        requesting: false,
      };
    case 'RETRIEVE_EXTRA_SURCHARGE_LIST_MODULE_FOR_SUPERADMIN':
      return {
        ...state,
        extra_surcharge_list_module_for_superadmin: [...payload],
        // extra_surcharge_list: [],
        // extra_surcharge_list_selected: [],
        // requesting: false,
      };

    case 'RETRIEVE_EXTRA_SURCHARGE_LIST_SELECTED':
      return {
        ...state,
        extra_surcharge_list_selected: [...payload],
        requesting: false,
      };

    case 'RETRIEVE_SURCHARGE_LIST_BY_AIRLINE_ID':
      return {
        ...state,
        surcharge_list_by_airline_id: [...payload],
      };

    case 'RETRIEVE_EXTRA_GENERAL_SURCHARGE_LISTS':
      return {
        ...state,
        extra_general_surcharge_list: [...payload],
        requesting: false,
      };

    case 'RETRIEVE_EXTRA_DANGEROUS_SURCHARGE_LISTS':
      return {
        ...state,
        extra_dangerous_surcharge_list: [...payload],
        requesting: false,
      };

    case 'RETRIEVE_EXTRA_EXPRESS_SURCHARGE_LISTS':
      return {
        ...state,
        extra_express_surcharge_list: [...payload],
        requesting: false,
      };

    case 'RETRIEVE_EXTRA_TEMP_CONTROLLED_SURCHARGE_LISTS':
      return {
        ...state,
        extra_temp_contolled_surcharge_list: [...payload],
        requesting: false,
      };

    case 'RETRIEVE_EXTRA_PERISHABLE_SURCHARGE_LISTS':
      return {
        ...state,
        extra_perishable_surcharge_list: [...payload],
        requesting: false,
      };

    case 'RETRIEVE_EXTRA_OVERSIZED_SURCHARGE_LISTS':
      return {
        ...state,
        extra_oversized_surcharge_list: [...payload],
        requesting: false,
      };


    case 'GET_FFADMIN_MANUALRATE_LIST_REQ':
      return {
        ...state,
        loading: true,
      };
    case 'GET_FFADMIN_MANUALRATE_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        manualRateList: payload,
      };
    case 'GET_FFADMIN_MANUALRATE_LIST_FAIL':
      return {
        ...state,
        loading: false,
      };


    default:
      return {
        ...state,
      };
  }
};
