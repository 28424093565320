const init_state = {
  usa_address: {},
};

export const retrieveUsaAddressReducer = (state = init_state, { type, payload }) => {
  switch (type) {
    case 'GET_USA_ADDRESS':
      return {
        ...state,
        usa_address: {
          ...payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
