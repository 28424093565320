import {
  GET_SUBSCRIPTIONS_REQ,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL,
  BUY_SUBSCRIPTION_REQ,
  BUY_SUBSCRIPTION_SUCCESS,
  BUY_SUBSCRIPTION_FAIL,
} from '../constants';

const INIT_STATE = {
  subscriptionPacks: [],
  loading: false,
};

const subscriptionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionPacks: action.payload,
      };
    case GET_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case BUY_SUBSCRIPTION_REQ:
      return {
        ...state,
        loading: true,
      };
    case BUY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case BUY_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default subscriptionReducer;
