//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//AUTH0
export const UPDATE_AUTH_USER = 'update_auth_user';
export const SET_AUTH_TOKEN = 'set_auth_token';
export const SIGNOUT_AUTH_SUCCESS = 'signout_auth_success';

export const GET_PROFILE_REQ = 'GET_PROFILE_REQ';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

//ANALYTICS-DASHBOARD
export const GET_ANALYTICS_DATA = 'get_analytics_data';

//ECOMMERCE-DASHBOARD
export const GET_ECOMMERCE_DATA = 'get_ecommerce_data';

//ACADEMY-DASHBOARD
export const GET_ACADEMY_DATA = 'get_academy_data';

//CRM-DASHBOARD
export const GET_CRM_DATA = 'get_crm_data';

//CRYPTO-DASHBOARD
export const GET_CRYPTO_DATA = 'get_crypto_data';

//CRYPTO-DASHBOARD
export const GET_HC_DATA = 'get_hc_data';

//METRICS-DASHBOARD
export const GET_METRICS_DATA = 'get_metrics_data';

//WIDGETS_DASHBOARD
export const GET_WIDGETS_DATA = 'get_widgets_data';

//MAIL-APP
export const GET_MAIL_LIST = 'get_mail_list';
export const GET_FOLDER_LIST = 'get_folder_list';
export const GET_LABEL_LIST = 'get_label_list';
export const TOGGLE_MAIL_DRAWER = 'toggle_mail_drawer';
export const COMPOSE_MAIL = 'compose_mail';
export const GET_MAIL_DETAIL = 'get_mail_detail';
export const UPDATE_MAIL_FOLDER = 'update_mail_folders';
export const UPDATE_MAIL_LABEL = 'update_mail_label';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const UPDATED_MAIL_DETAIL = 'updated_mail_detail';
export const CHANGE_READ_STATUS = 'change_read_status';
export const GET_CONNECTION_LIST = 'get_connection_list';
export const NULLIFY_MAIL = 'nullify_mail';

//TODO-APP
export const GET_TASK_LIST = 'get_task_list';
export const CREATE_NEW_TASK = 'create_new_task';
export const TOGGLE_TODO_DRAWER = 'toggle_todo_drawer';
export const GET_TODO_FOLDER_LIST = 'GET_TODO_FOLDER_LIST';
export const GET_TODO_LABEL_LIST = 'GET_TODO_LABEL_LIST';
export const GET_TODO_STATUS_LIST = 'GET_TODO_STATUS_LIST';
export const GET_TODO_PRIORITY_LIST = 'GET_TODO_PRIORITY_LIST';
export const UPDATE_TASK_FOLDER = 'UPDATE_TASK_FOLDER';
export const UPDATE_TASK_LABEL = 'UPDATE_TASK_LABEL';
export const UPDATE_TASK_STARRED_STATUS = 'UPDATE_TASK_STARRED_STATUS';
export const GET_TASK_DETAIL = 'GET_TASK_DETAIL';
export const UPDATE_TASK_DETAIL = 'UPDATE_TASK_DETAIL';
export const GET_TODO_STAFF_LIST = 'GET_TODO_STAFF_LIST';

//CONTACT_APP
export const GET_CONTACT_LIST = 'GET_CONTACT_LIST';
export const GET_CONTACT_FOLDER_LIST = 'GET_CONTACT_FOLDER_LIST';
export const GET_CONTACT_LABEL_LIST = 'GET_CONTACT_LABEL_LIST';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const UPDATE_CONTACT_LABEL = 'UPDATE_CONTACT_LABEL';
export const UPDATE_CONTACT_STARRED_STATUS = 'UPDATE_CONTACT_STARRED_STATUS';
export const GET_CONTACT_DETAIL = 'GET_CONTACT_DETAIL';
export const TOGGLE_CONTACT_DRAWER = 'TOGGLE_CONTACT_DRAWER';
export const UPDATE_CONTACT_DETAIL = 'UPDATE_CONTACT_DETAIL';
export const CREATE_NEW_CONTACT = 'CREATE_NEW_CONTACT';

//SCRUMBOARD_APP
export const GET_MEMBER_LIST = 'GET_MEMBER_LIST';
export const GET_SCRUM_LABEL_LIST = 'GET_SCRUM_LABEL_LIST';
export const GET_BOARDS = 'GET_BOARDS';
export const GET_BOARD_DETAIL = 'GET_BOARD_DETAIL';
export const ADD_BOARD_LIST = 'ADD_BOARD_LIST';
export const ADD_LIST_CARD = 'ADD_LIST_CARD';
export const EDIT_LIST_CARD = 'EDIT_LIST_CARD';
export const DELETE_LIST_CARD = 'DELETE_LIST_CARD';
export const DELETE_BOARD = 'DELETE_BOARD';
export const ADD_NEW_BOARD = 'ADD_NEW_BOARD';
export const DELETE_LIST = 'DELETE_LIST';
export const EDIT_BOARD_DETAIL = 'EDIT_BOARD_DETAIL';
export const EDIT_BOARD_LIST = 'EDIT_BOARD_LIST';

//CHAT_APP
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_USER_MESSAGES = 'get_user_messages';
export const ADD_NEW_MESSAGE = 'add_new_message';
export const EDIT_MESSAGE = 'edit_message';
export const DELETE_MESSAGE = 'delete_message';
export const DELETE_USER_MESSAGES = 'delete_user_messages';
export const TOGGLE_CHAT_DRAWER = 'toggle_chat_drawer';
export const SELECT_USER = 'select_user';

//WALL_APP
export const GET_WALL_DATA = 'get_wall_data';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_NEW_POST = 'create_new_post';
export const UPDATE_POST = 'update_post';

//USER_LIST
export const GET_USER_LIST = 'GET_USER_LIST';

//ECOMMERCE_LIST
export const GET_ECOMMERCE_LIST = 'get_ecommerce_list';
export const SET_PRODUCT_VIEW_TYPE = 'set_product_view_type';
export const SET_FILTER_DATA = 'set_filter_data';
export const SET_PRODUCT_DATA = 'set_product_data';
export const GET_RECENT_ORDER = 'get_recent_order';
export const GET_CUSTOMERS = 'get_customers';
export const ADD_CART_ITEM = 'add_cart_item';
export const REMOVE_CART_ITEM = 'remove_cart_item';
export const UPDATE_CART_ITEM = 'update_cart_item';
export const SET_CART_ITEMS = 'set_cart_items';

//CK-EDITOR
export const GET_BALLOON_BLOCK_DATA = 'get_balloon_block_data';
export const UPDATE_BALLOON_BLOCK_DATA = 'update_balloon_block_data';
export const GET_BALLOON_DATA = 'get_balloon_data';
export const UPDATE_BALLOON_DATA = 'update_balloon_data';
export const GET_CLASSIC_DATA = 'get_classic_data';
export const UPDATE_CLASSIC_DATA = 'update_classic_data';
export const GET_INLINE_DATA = 'get_inline_data';
export const UPDATE_INLINE_DATA = 'update_inline_data';
export const GET_DOCUMENT_DATA = 'get_document_data';
export const UPDATE_DOCUMENT_DATA = 'update_document_data';
export const GET_CUSTOM_DATA = 'get_custom_data';
export const UPDATE_CUSTOM_DATA = 'update_custom_data';

//GALLERY
export const GET_GALLERY_PHOTO = 'get_gallery_photo';

// SIGNIN ROLE
export const ROLE = 'role';

// Address
export const GET_ADDRESSES_REQ = 'GET_ADDRESSES_REQ';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const GET_ADDRESSES_FAIL = 'GET_ADDRESSES_FAIL';

export const UPDATE_ADDRESS_REQ = 'UPDATE_ADDRESS_REQ';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAIL = 'UPDATE_ADDRESS_FAIL';

export const DELETE_ADDRESS_REQ = 'DELETE_ADDRESS_REQ';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAIL = 'DELETE_ADDRESS_FAIL';

export const CREATE_ADDRESS_REQ = 'CREATE_ADDRESS_REQ';
export const CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS';
export const CREATE_ADDRESS_FAIL = 'CREATE_ADDRESS_FAIL';

export const GET_ADDRESS_REQ = 'GET_ADDRESS_REQ';
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_FAIL = 'GET_ADDRESS_FAIL';

export const GET_ADDRESS2_REQ = 'GET_ADDRESS2_REQ';
export const GET_ADDRESS2_SUCCESS = 'GET_ADDRESS2_SUCCESS';
export const GET_ADDRESS2_FAIL = 'GET_ADDRESS2_FAIL';

export const SET_ADDRESS_DATA = 'SET_ADDRESS_DATA';
export const SET_ADDRESS_DATA1 = 'SET_ADDRESS_DATA1';
export const SET_ADDRESS_DATA2 = 'SET_ADDRESS_DATA2';

// Quotation
// export const GET_QUOTATIONS_REQ = 'GET_QUOTATIONS_REQ';
// export const GET_QUOTATIONS_SUCCESS = 'GET_QUOTATIONS_SUCCESS';
// export const GET_QUOTATIONS_FAIL = 'GET_QUOTATIONS_FAIL';

// export const GET_QUOTATION_REQ = 'GET_QUOTATION_REQ';
// export const GET_QUOTATION_SUCCESS = 'GET_QUOTATION_SUCCESS';
// export const GET_QUOTATION_FAIL = 'GET_QUOTATION_FAIL';

// export const UPDATE_QUOTATION_REQ = 'UPDATE_QUOTATION_REQ';
// export const UPDATE_QUOTATION_SUCCESS = 'UPDATE_QUOTATION_SUCCESS';
// export const UPDATE_QUOTATION_FAIL = 'UPDATE_QUOTATION_FAIL';

// export const DELETE_QUOTATION_REQ = 'DELETE_QUOTATION_REQ';
// export const DELETE_QUOTATION_SUCCESS = 'DELETE_QUOTATION_SUCCESS';
// export const DELETE_QUOTATION_FAIL = 'DELETE_QUOTATION_FAIL';

// export const CREATE_QUOTATION_REQ = 'CREATE_QUOTATION_REQ';
// export const CREATE_QUOTATION_SUCCESS = 'CREATE_QUOTATION_SUCCESS';
// export const CREATE_QUOTATION_FAIL = 'CREATE_QUOTATION_FAIL';

// export const GET_SEARCH_ADDRESS_REQ = 'GET_SEARCH_ADDRESS_REQ';
// export const GET_SEARCH_ADDRESS_SUCCESS = 'GET_SEARCH_ADDRESS_SUCCESS';
// export const GET_SEARCH_ADDRESS_FAIL = 'GET_SEARCH_ADDRESS_FAIL';

// export const GET_SEARCH_ADDRESS2_REQ = 'GET_SEARCH_ADDRESS2_REQ';
// export const GET_SEARCH_ADDRESS2_SUCCESS = 'GET_SEARCH_ADDRESS2_SUCCESS';
// export const GET_SEARCH_ADDRESS2_FAIL = 'GET_SEARCH_ADDRESS2_FAIL';

// export const GET_SHIPPER_COMPANY_LIST_REQ = 'GET_SHIPPER_COMPANY_LIST_REQ';
// export const GET_SHIPPER_COMPANY_LIST_SUCCESS = 'GET_SHIPPER_COMPANY_LIST_SUCCESS';
// export const GET_SHIPPER_COMPANY_LIST_FAIL = 'GET_SHIPPER_COMPANY_LIST_FAIL';


export const GET_SHIPPER_QUOTATIONS_REQ = 'GET_SHIPPER_QUOTATIONS_REQ';
export const GET_SHIPPER_QUOTATIONS_SUCCESS = 'GET_SHIPPER_QUOTATIONS_SUCCESS';
export const GET_SHIPPER_QUOTATIONS_FAIL = 'GET_SHIPPER_QUOTATIONS_FAIL';

export const POST_SHIPPER_QUOTATIONS_PRICE_REQ = "POST_SHIPPER_QUOTATIONS_PRICE_REQ";
export const POST_SHIPPER_QUOTATIONS_PRICE_SUCCESS = "POST_SHIPPER_QUOTATIONS_PRICE_SUCCESS";
export const POST_SHIPPER_QUOTATIONS_PRICE_FAIL = "POST_SHIPPER_QUOTATION_PRICE_FAIL";

export const GET_SHIPPER_QUOTATION_REQ = 'GET_SHIPPER_QUOTATION_REQ';
export const GET_SHIPPER_QUOTATION_SUCCESS = 'GET_SHIPPER_QUOTATION_SUCCESS';
export const GET_SHIPPER_QUOTATION_FAIL = 'GET_SHIPPER_QUOTATION_FAIL';

export const UPDATE_SHIPPER_QUOTATION_REQ = "UPDATE_SHIPPER_QUOTATION_REQ";
export const UPDATE_SHIPPER_QUOTATION_SUCCESS = "UPDATE_SHIPPER_QUOTATION_SUCCESS";
export const UPDATE_SHIPPER_QUOTATION_FAIL = "UPDATE_SHIPPER_QUOTATION_FAIL";

export const DELETE_SHIPPER_QUOTATION_REQ = "DELETE_SHIPPER_QUOTATION_REQ";
export const DELETE_SHIPPER_QUOTATION_SUCCESS = "DELETE_SHIPPER_QUOTATION_SUCCESS";
export const DELETE_SHIPPER_QUOTATION_FAIL = "DELETE_SHIPPER_QUOTATION_FAIL";

export const CREATE_SHIPPER_QUOTATION_REQ = "CREATE_SHIPPER_QUOTATION_REQ";
export const CREATE_SHIPPER_QUOTATION_SUCCESS = "CREATE_SHIPPER_QUOTATION_SUCCESS";
export const CREATE_SHIPPER_QUOTATION_FAIL = "CREATE_SHIPPER_QUOTATION_FAIL";

export const GET_SHIPPER_SEARCH_ADDRESS_REQ = "GET_SHIPPER_SEARCH_ADDRESS_REQ";
export const GET_SHIPPER_SEARCH_ADDRESS_SUCCESS = "GET_SHIPPER_SEARCH_ADDRESS_SUCCESS";
export const GET_SHIPPER_SEARCH_ADDRESS_FAIL = "GET_SHIPPER_SEARCH_ADDRESS_FAIL";

export const GET_SHIPPER_SEARCH_ADDRESS2_REQ = "GET_SHIPPER_SEARCH_ADDRESS2_REQ";
export const GET_SHIPPER_SEARCH_ADDRESS2_SUCCESS = "GET_SHIPPER_SEARCH_ADDRESS2_SUCCESS";
export const GET_SHIPPER_SEARCH_ADDRESS2_FAIL = "GET_SHIPPER_SEARCH_ADDRESS2_FAIL";

export const GET_REQUEST_QUOTE_REQ = 'GET_REQUEST_QUOTE_REQ';
export const GET_REQUEST_QUOTE_SUCCESS = 'GET_REQUEST_QUOTE_SUCCESS';
export const GET_REQUEST_QUOTE_FAIL = 'GET_REQUEST_QUOTE_FAIL';

export const GET_SHIP_TO_QUICK_AIRPORT_REQ = "GET_SHIP_TO_QUICK_AIRPORT_REQ";
export const GET_SHIP_TO_QUICK_AIRPORT_SUCCESS = "GET_SHIP_TO_QUICK_AIRPORT_SUCCESS";
export const GET_SHIP_TO_QUICK_AIRPORT_FAIL = "GET_SHIP_TO_QUICK_AIRPORT_FAIL";

export const GET_SEARCH_BY_ZIP_REQ = 'GET_SEARCH_BY_ZIP_REQ';
export const GET_SEARCH_BY_ZIP_SUCCESS = 'GET_SEARCH_BY_ZIP_SUCCESS';
export const GET_SEARCH_BY_ZIP_FAIL = 'GET_SEARCH_BY_ZIP_FAIL';

export const GET_COUNTRY_REQ = 'GET_COUNTRY_REQ';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAIL = 'GET_COUNTRY_FAIL';

export const CHECK_QUOTE_AVAILABLE_REQ = 'CHECK_QUOTE_AVAILABLE_REQ';
export const CHECK_QUOTE_AVAILABLE_SUCCESS = 'CHECK_QUOTE_AVAILABLE_SUCCESS';
export const CHECK_QUOTE_AVAILABLE_FAIL = 'CHECK_QUOTE_AVAILABLE_FAIL';

export const SEND_QUOTE_BY_EMAIL_REQ = 'SEND_QUOTE_BY_EMAIL_REQ';
export const SEND_QUOTE_BY_EMAIL_SUCCESS = 'SEND_QUOTE_BY_EMAIL_SUCCESS';
export const SEND_QUOTE_BY_EMAIL_FAIL = 'SEND_QUOTE_BY_EMAIL_FAIL';

export const SET_QUOTATION_DATA = 'SET_QUOTATION_DATA';

export const GET_AIRPORT_DEPATURE_LIST = 'GET_AIRPORT_DEPATURE_LIST';
export const STORE_RADIO_OPTION = 'STORE_RADIO_OPTION';
export const GET_EMPTY_COUNTRY_LIST = 'GET_EMPTY_COUNTRY_LIST';

export const POST_SHIPPER_QUOTATION_PDF_REQ = 'POST_SHIPPER_QUOTATION_PDF_REQ';
export const POST_SHIPPER_QUOTATION_PDF_SUCCESS = 'POST_SHIPPER_QUOTATION_PDF_SUCCESS';
export const POST_SHIPPER_QUOTATION_PDF_FAIL = 'POST_SHIPPER_QUOTATION_PDF_FAIL';

export const GET_SHIPPER_COMPANY_LIST_REQ = 'GET_SHIPPER_COMPANY_LIST_REQ';
export const GET_SHIPPER_COMPANY_LIST_SUCCESS = 'GET_SHIPPER_COMPANY_LIST_SUCCESS';
export const GET_SHIPPER_COMPANY_LIST_FAIL = 'GET_SHIPPER_COMPANY_LIST_FAIL';


// Package

export const GET_PACKAGE_REQ = 'GET_PACKAGE_REQ';
export const GET_PACKAGE_SUCCESS = 'GET_PACKAGE_SUCCESS';
export const GET_PACKAGE_FAIL = 'GET_PACKAGE_FAIL';

export const CREATE_PACKAGE_REQ = 'CREATE_PACKAGE_REQ';
export const CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS';
export const CREATE_PACKAGE_FAIL = 'CREATE_PACKAGE_FAIL';

export const GET_PACKAGES_REQ = 'GET_PACKAGES_REQ';
export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS';
export const GET_PACKAGES_FAIL = 'GET_PACKAGES_FAIL';

export const UPDATE_PACKAGE_REQ = 'UPDATE_PACKAGE_REQ';
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_FAIL = 'UPDATE_PACKAGE_FAIL';

export const DELETE_PACKAGE_REQ = 'DELETE_PACKAGE_REQ';
export const DELETE_PACKAGE_SUCCESS = 'DELETE_PACKAGE_SUCCESS';
export const DELETE_PACKAGE_FAIL = 'DELETE_PACKAGE_FAIL';

export const FETCH_REQ_PACKAGE_REQ = 'FETCH_REQ_PACKAGE_REQ';
export const FETCH_REQ_PACKAGE_SUCCESS = 'FETCH_REQ_PACKAGE_SUCCESS';
export const FETCH_REQ_PACKAGE_FAIL = 'FETCH_REQ_PACKAGE_FAIL';

export const FETCH_REQ_PACKAGE_REQ_HISTORY = 'FETCH_REQ_PACKAGE_REQ_HISTORY';
export const FETCH_REQ_PACKAGE_SUCCESS_HISTORY = 'FETCH_REQ_PACKAGE_SUCCESS_HISTORY';
export const FETCH_REQ_PACKAGE_FAIL_HISTORY = 'FETCH_REQ_PACKAGE_FAIL_HISTORY';

export const APPROVE_SUBS_REQ = 'APPROVE_SUBS_REQ';
export const APPROVE_SUBS_SUCCESS = 'APPROVE_SUBS_SUCCESS';
export const APPROVE_SUBS_FAIL = 'APPROVE_SUBS_FAIL';

// Notification

export const GET_NOTIFICATIONS_REQ = 'GET_NOTIFICATIONS_REQ';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const GET_NOTIFICATIONS_COUNT_REQ = 'GET_NOTIFICATIONS_COUNT_REQ';
export const GET_NOTIFICATIONS_COUNT_SUCCESS = 'GET_NOTIFICATIONS_COUNT_SUCCESS';
export const GET_NOTIFICATIONS_COUNT_FAIL = 'GET_NOTIFICATIONS_COUNT_FAIL';

export const NOTIF_MARK_AS_READ_REQ = 'NOTIF_MARK_AS_READ_REQ';
export const NOTIF_MARK_AS_READ_SUCCESS = 'NOTIF_MARK_AS_READ_SUCCESS';
export const NOTIF_MARK_AS_READ_FAIL = 'NOTIF_MARK_AS_READ_FAIL';

export const GET_SHIP_FROM_AIRPORT_REQ = 'GET_SHIP_FROM_AIRPORT_REQ';
export const GET_SHIP_FROM_AIRPORT_SUCCESS = 'GET_SHIP_FROM_AIRPORT_SUCCESS';
export const GET_SHIP_FROM_AIRPORT_FAIL = 'GET_SHIP_FROM_AIRPORT_FAIL';

export const GET_SHIP_TO_AIRPORT_REQ = 'GET_SHIP_TO_AIRPORT_REQ';
export const GET_SHIP_TO_AIRPORT_SUCCESS = 'GET_SHIP_TO_AIRPORT_SUCCESS';
export const GET_SHIP_TO_AIRPORT_FAIL = 'GET_SHIP_TO_AIRPORT_FAIL';

export const GET_REVIEWS_REQ = 'GET_REVIEWS_REQ';
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAIL = 'GET_REVIEWS_FAIL';

export const STORE_COMPANY_ID = 'STORE_COMPANY_ID';


export const GET_ERROR = 'GET_ERROR';
export const UPDATE_USER_IMAGEURL = 'UPDATE_USER_IMAGEURL';
export const UPDATE_LOGO_IMAGEURL = 'UPDATE_LOGO_IMAGEURL'


export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';

export const DOWNLOAD_STATUS_OF_IMAGE_REQ = 'DOWNLOAD_STATUS_OF_IMAGE_REQ';
export const DOWNLOAD_STATUS_OF_IMAGE_SUCCES = 'DOWNLOAD_STATUS_OF_IMAGE_SUCCESS';
export const DOWNLOAD_STATUS_OF_IMAGE_FAIL = 'DOWNLOAD_STATUS_OF_IMAGE_FAIL';

export const CHOSSE_CONSIGNEE_LOGO = 'CHOSSE_CONSIGNEE_LOGO';
export const CHOOSE_CLIENT_LOGO = 'CHOOSE_CLIENT_LOGO';

export const NOTIF_MAINMENU_COUNT_DATA_SUCCESS = 'NOTIF_MAINMENU_COUNT_DATA_SUCCESS';
export const NOTIF_MAINMENU_COUNT_DATA_FAIL = 'NOTIF_MAINMENU_COUNT_DATA_FAIL';

export const SET_NOTIFICATIONS_FLAG_STATUS = 'SET_NOTIFICATIONS_FLAG_STATUS';

export const NOTIF_LIST_MENU_SUBMENU_STATUS_SUCCESS = 'NOTIF_LIST_MENU_SUBMENU_STATUS_SUCCESS';