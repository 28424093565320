import {
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  GET_PROFILE_REQ,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_USER_IMAGEURL,UPDATE_LOGO_IMAGEURL,CHOSSE_CONSIGNEE_LOGO,CHOOSE_CLIENT_LOGO

} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  user: null,
  token: null,
  profile: null,
  consgineeLogo:null,
  clientLogo:null,
  
  
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case SIGNOUT_AUTH_SUCCESS: {
      return {
        ...state,
        user: null,
      };
    }
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case GET_PROFILE_REQ:
      return {
        ...state,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };

    case UPDATE_USER_IMAGEURL:

      let imageUrl={...state.profile,imageUrl:action.payload}
      return{
        ...state,
        profile:imageUrl
      }

      case UPDATE_LOGO_IMAGEURL:

      let profileImage={...state.profile,company:{...state?.profile.company,profileImage:action.payload}}
      return{
        ...state,
        profile:profileImage
      }

     

      case CHOSSE_CONSIGNEE_LOGO:

      let logoUrl={...state.profile,companyLogo:action.payload};
      return{
        ...state,
        profile:logoUrl
      }  
      case CHOOSE_CLIENT_LOGO:

      let clientLogoUrl={...state.profile,companyLogo:action.payload};
      return{
        ...state,
        profile:clientLogoUrl
      }  

    case GET_PROFILE_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default authReducer;
