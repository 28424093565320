import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Collapse, Icon, IconButton, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import VerticalItem from './VerticalItem';
import AppContext from '../../../utility/AppContext';
import Box from '@material-ui/core/Box';
import IntlMessages from '../../../utility/IntlMessages';
import useStyles from './VerticalCollapase.style';
import { useSelector } from 'react-redux';
import { checkPermission } from '../../../utility/Utils';
import { useDispatch } from 'react-redux';
import { toggleDrawer } from 'redux/actions';
import { notificationMenuCountData } from 'redux/actions/Notification';
const needsToBeOpened = (pathname, item) => {
  return pathname && isUrlInChildren(item, pathname);
};

const isUrlInChildren = (parent, url) => {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i++) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true;
      }
    }
    if (parent.children[i].url === url || url.includes(parent.children[i].url)) {
      return true;
    }
  }
  return false;
};

const VerticalCollapse = ({ item, level, navConfiguration, setNavconfiguration }) => {
  const role = useSelector((state) => state.auth.user.role);
  const dispatch = useDispatch()
  const { themeMode } = useContext(AppContext);
  const classes = useStyles({ level, themeMode });
  const { theme } = useContext(AppContext);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(() => needsToBeOpened(pathname, item));
  const navCollapsed = useSelector(({ settings }) => settings.navCollapsed);

  const { notificationMenuData } = useSelector((state) => state.notifications);

  useEffect(() => {
    dispatch(notificationMenuCountData());
  }, [])

  useEffect(() => {
    if (needsToBeOpened(pathname, item)) {
      //from here the logic for user leave the url path when he has logout
      let data = [...navConfiguration];
      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.role == role) {
          if ((data[i]?.open == true) && data[i].id == item?.id) {
            data[i].open = false;
            setNavconfiguration(data)
          }
          else if (data[i].id !== item?.id) {
            data[i].open = false;
            setNavconfiguration(data)
          }
          else {
            data[i].open = true;
            setNavconfiguration(data)
          }
        }
        else {

        }
      }
      setOpen(true);
    }
  }, []);

  // it was old code 

  // useEffect(() => {    

  //   if (needsToBeOpened(pathname, item)) {
  //     //from here the logic for user leave the url path when he has logout
  //     setOpen(true);
  //   }
  // }, [pathname,item]);

  const handleClick = () => {
    console.log('child click')
    dispatch(toggleDrawer(true))
    let data = [...navConfiguration];
    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.role == role) {
        if ((data[i]?.open == true) && data[i].id == item?.id) {
          data[i].open = false;
          setNavconfiguration(data)
        }
        else if (data[i].id !== item?.id) {
          data[i].open = false;
          setNavconfiguration(data)
        }
        else {
          data[i].open = true;
          setNavconfiguration(data)
        }

      }
      else {

      }
    }
    // setOpen(!open); this was an old code 
  };

  const handleClick1 = () => {
    console.log('child click')
    let data = [...navConfiguration];
    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.role == role) {
        if ((data[i]?.open == true) && data[i].id == item?.id) {
          data[i].open = false;
          setNavconfiguration(data)
        }
        else if (data[i].id !== item?.id) {
          data[i].open = false;
          setNavconfiguration(data)
        }
        else {
          data[i].open = true;
          setNavconfiguration(data)
        }
      }
      else {

      }
    }
    // setOpen(!open); this was an old code 
  };

  const { user } = useSelector(({ auth }) => auth);
  const hasPermission = useMemo(() => checkPermission(item.auth, user.role), [
    item.auth,
    user.role,
  ]);

  if (!hasPermission) {
    return null;
  }

  return (
    <>
      <ListItem
        button
        component="li"
        className={clsx(classes.navItem, 'nav-item', item.open && 'item.open')}
        onClick={navCollapsed ? handleClick1 : handleClick}
      >
        {item.icon && (
          <Box component="span" mr={6}>
            <Tooltip title={item.title} placement="right-start">
              {/* <Icon color="action" className={clsx('nav-item-icon', classes.listIcon)}>
                {item.icon}
              </Icon> */}
              <img color="action" className={clsx('nav-item-icon', classes.listIcon)} src={item.icon}></img>
            </Tooltip>
          </Box>
        )}
        <ListItemText
          classes={{ primary: clsx('nav-item-text', classes.listItemText) }}
          primary={<IntlMessages id={item.messageId} />}
        />
        <Box p={0} clone>
          <IconButton disableRipple>
            <Icon className="nav-item-icon-arrow" color="inherit">
              {(item.open && item?.id === item?.id) ? 'expand_more' : theme.direction === 'ltr' ? 'chevron_right' : 'chevron_left'}
            </Icon>
          </IconButton>
        </Box>
      </ListItem>

      {item.children && (
        <Collapse in={item.open} unmountOnExit className="collapse-children">
          {item.children.map((item) => (
            <React.Fragment key={item.id}>
              {item.type === 'collapse' && <VerticalCollapse item={item} level={level + 1} />}

              {item.type === 'item' && <VerticalItem item={item} level={level + 1} key={item.id} notificationMenuData={notificationMenuData} />}

            </React.Fragment>
          ))}
        </Collapse>
      )}
    </>
  );
};

VerticalCollapse.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.array,
  }),
};
VerticalCollapse.defaultProps = {};

export default React.memo(VerticalCollapse);
