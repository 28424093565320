import {
  GET_AGENT_REQ,
  GET_AGENT_SUCCESS,
  GET_AGENT_FAIL,
  CREATE_ECONOMICS_REQ,
  CREATE_ECONOMICS_SUCCESS,
  CREATE_ECONOMICS_FAIL,
  GET_ECONOMICS_REQ,
  GET_ECONOMICS_SUCCESS,
  GET_ECONOMICS_FAIL,
  GET_SINGLE_ECONOMICS_REQ,
  GET_SINGLE_ECONOMICS_SUCCESS,
  GET_SINGLE_ECONOMICS_SUCCESS_SURCHARGES,
  GET_SINGLE_ECONOMICS_FAIL_SURCHARGES,
  GET_SINGLE_ECONOMICS_FAIL,
  GET_GROUP_LIST_REQ,
  GET_GROUP_LIST_SUCCESS,
  GET_GROUP_LIST_FAIL
} from '../constants';

const INIT_STATE = {
  agentList: [],
  loading: false,
  economicsData: [],
  agenDataLoader: false,
  groupListLoading: false,
  groupData: [],
  singleEconomicsSurcharges: [],
  singleEconomics: {},
};

const economicsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AGENT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_AGENT_SUCCESS:
      return {
        ...state,
        loading: false,
        agentList: action.payload,
      };
    case GET_AGENT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CREATE_ECONOMICS_REQ:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ECONOMICS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_ECONOMICS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_ECONOMICS_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_ECONOMICS_SUCCESS:
      return {
        ...state,
        loading: false,
        economicsData: action.payload,
      };
    case GET_ECONOMICS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SINGLE_ECONOMICS_REQ:
      return {
        ...state,
        loading: true,
        agenDataLoader: true
      };
    case GET_SINGLE_ECONOMICS_SUCCESS:
      return {
        ...state,
        loading: true,
        singleEconomics: {
          ...action.payload
        },
        agenDataLoader: false,
      };
    case GET_SINGLE_ECONOMICS_FAIL:
      return {
        ...state,
        loading: false,
        agenDataLoader: false,
        singleEconomics: {},
      };

    case GET_SINGLE_ECONOMICS_SUCCESS_SURCHARGES:
      return {
        ...state,
        loading: true,
        singleEconomicsSurcharges: {
          ...action.payload
        },
        agenDataLoader: false,
      };
    case GET_SINGLE_ECONOMICS_FAIL_SURCHARGES:
      return {
        ...state,
        loading: false,
        agenDataLoader: false,
        singleEconomicsSurcharges: []
      };

    case GET_GROUP_LIST_REQ:
      return {
        ...state,
        groupListLoading: true,
      };
    case GET_GROUP_LIST_SUCCESS:
      return {
        ...state,
        groupListLoading: false,
        groupData: action.payload
      };
    case GET_GROUP_LIST_FAIL:
      return {
        ...state,
        groupListLoading: false,
      };

    default:
      return state;
  }
};
export default economicsReducer;
