import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';
import { toast } from 'react-toastify';
import { getProfile } from 'redux/actions';
import {DOWNLOAD_STATUS_OF_IMAGE_REQ,
  DOWNLOAD_STATUS_OF_IMAGE_SUCCES,
  DOWNLOAD_STATUS_OF_IMAGE_FAIL,
  
  CHOSSE_CONSIGNEE_LOGO,
  CHOOSE_CLIENT_LOGO
} from '../../../../../../../shared/constants/ActionTypes'

export const update_user_profile = (DATA) => async (dispatch, state) => {
  const put_data = {
    firstName: DATA.fName,
    lastName: DATA.lName,
    email: DATA.email,
    mobile: DATA.phone,
    replyToEmail: DATA.replyToEmail,
    countryCode:DATA?.countryCode
  };

  try {
    const res = await jwtAxios({
      method: 'PUT',
      url: `/profile/update`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: {
        ...put_data,
      },
    });

    if (res.status >= 200 || res.status <= 200) {
      dispatch(getProfile());
      toast.success('Record Updated Successfully');
    }
  } catch (error) {
    toast.error('Internal Server Error');
  }
};


export const updateLogo = (value,type) => {
  const post_data = {
    id: value,
  };

  var body = new FormData();
  // body.append('file',URL.createObjectURL(value))
  body.append('file', value);

  return async (dispatch, getState) => {
    // let updateAuthStore={...getState().auth.profile,imageUrl:URL.createObjectURL(value)}
    if (value != undefined && value != null && value != '') {
      try {
        dispatch({ type: DOWNLOAD_STATUS_OF_IMAGE_REQ });

        const res = await jwtAxios({
          url: `/uploadFile/logo`,
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          data: body,
        });

        if (200 <= res.status && res.status < 300) {
          // dispatch({type:DOWNLOAD_STATUS_OF_IMAGE_SUCCES})

          dispatch(downloadConsignee(res.data.fileName,type));
        }
      } catch (error) {
        // toast.error(`Internal Server Error`);
        // dispatch({type:DOWNLOAD_STATUS_OF_IMAGE_FAIL})
      }
    }
  };
};

//comment when deleteProfileLogo functionality need to be added
// export const deleteProfileLogo = (id) => async (dispatch) => {
//   try {
//     const response = await jwtAxios({
//       url: `/delete/logo`,
//       method: 'delete',
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem('token')}`,
//       },
//     });
//     if (response.status === 200) {

//       dispatch(getProfile());

//       return new Promise((resolve, reject) => {
//         resolve(response)
//       })
//     }
//   } catch (error) {

//     return new Promise((resolve, reject) => {
//       resolve(error)
//     })
//   }
// };




export const downloadConsignee = (value,type) => {
  return async (dispatch, getState) => {
    if (value != undefined && value != null && value != '' && value?.includes('blob') == false) {
      try {
        const res = await jwtAxios({
          url: `/downloadFile/${value}`,
          method: 'get',
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          responseType: 'blob',
        });

        if (200 <= res.status && res.status < 300) {
          if(type=='Consignee')
          dispatch({ type: CHOSSE_CONSIGNEE_LOGO, payload: URL.createObjectURL(res?.data) });
           else 
          dispatch({ type: CHOOSE_CLIENT_LOGO, payload: URL.createObjectURL(res?.data) });

          dispatch({ type: DOWNLOAD_STATUS_OF_IMAGE_SUCCES });

          return new Promise((resolve, reject) => {
            resolve(res);
          });
        }
      } catch (error) {
        dispatch({ type: DOWNLOAD_STATUS_OF_IMAGE_FAIL });

        // toast.error(`Internal Server Error`);
      }
    }
  };
};



