import {
  GET_PACKAGE_REQ,
  GET_PACKAGE_SUCCESS,
  GET_PACKAGE_FAIL,
  GET_PACKAGES_REQ,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAIL,
  CREATE_PACKAGE_REQ,
  CREATE_PACKAGE_SUCCESS,
  CREATE_PACKAGE_FAIL,
  UPDATE_PACKAGE_REQ,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAIL,
  DELETE_PACKAGE_REQ,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAIL,
  FETCH_REQ_PACKAGE_REQ,
  FETCH_REQ_PACKAGE_SUCCESS,
  FETCH_REQ_PACKAGE_FAIL,
  FETCH_REQ_PACKAGE_REQ_HISTORY,
  FETCH_REQ_PACKAGE_SUCCESS_HISTORY,
  FETCH_REQ_PACKAGE_FAIL_HISTORY,
  APPROVE_SUBS_REQ,
  APPROVE_SUBS_SUCCESS,
  APPROVE_SUBS_FAIL,
  GET_REVIEWS_REQ,
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_FAIL,
  STORE_COMPANY_ID
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  packages: [],
  loading: false,
  package: {},
  requestedAddons: [],
  requestedAddonsHistory: [],
  reviewRequestAddons:[],
  requestLoading:false,
  companyId:''
};

const adminPackageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PACKAGES_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        packages: action.payload,
      };
    case GET_PACKAGES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CREATE_PACKAGE_REQ:
    case UPDATE_PACKAGE_REQ:
    case DELETE_PACKAGE_REQ:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PACKAGE_SUCCESS:
    case UPDATE_PACKAGE_SUCCESS:
    case DELETE_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_PACKAGE_FAIL:
    case UPDATE_PACKAGE_FAIL:
    case DELETE_PACKAGE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_PACKAGE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        package: action.payload,
      };
    case GET_PACKAGE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case FETCH_REQ_PACKAGE_REQ:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REQ_PACKAGE_SUCCESS:
      return {
        ...state,
        requestedAddons: action.payload,
        loading: false,
      };
    case FETCH_REQ_PACKAGE_FAIL:
      return {
        ...state,
        loading: false,
      };
      case FETCH_REQ_PACKAGE_REQ_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REQ_PACKAGE_SUCCESS_HISTORY:
      return {
        ...state,
        requestedAddonsHistory: action.payload,
        loading: false,
      };
    case FETCH_REQ_PACKAGE_FAIL_HISTORY:
      return {
        ...state,
        loading: false,
      };
    case APPROVE_SUBS_REQ:
      return {
        ...state,
        loading: true,
      };
    case APPROVE_SUBS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case APPROVE_SUBS_FAIL:
      return {
        ...state,
        loading: false,
      };


      case STORE_COMPANY_ID:
        return{
          ...state,
          companyId:action.payload

        }
      case GET_REVIEWS_REQ:
      return {
        ...state,
        requestLoading: true,
      };
    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        reviewRequestAddons: action.payload,
        requestLoading: false,
      };
    case GET_REVIEWS_FAIL:
      return {
        ...state,
        requestLoading: false,
      };
    default:
      return state;
  }
};
export default adminPackageReducer;
