const initialState = {
  requesting: true,
  adminList: [],
  adminsEnrolled: [],
  frieghtForwarderAdminsListRowData: {},
  adminListRowInFreightForwarder: {},
  // adminsDeleted: [],
};

export const retrieveFreightForwarderAdminListReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case 'ADMIN_LIST':
      return {
        ...state,
        requesting: false,
        adminList: [...payload],
      };
    case 'ADMINS_ENROLLED':
      return {
        ...state,
        requesting: false,
        adminsEnrolled: [...payload],
      };
    case 'RETRIEVE_FRIEGHT_FORWARDER_ADMINS_LIST_ROW_DATA':
      return {
        ...state,
        requesting: false,
        frieghtForwarderAdminsListRowData: payload
      };
    case 'UPDATE_ADMIN_LIST_ROW_IN_FREIGHT_FORWARDER':
      return {
        ...state,
        requesting: false,
        adminListRowInFreightForwarder: payload
      };
    // case 'ADMINS_DELETED':
    //   return {
    //     ...state,
    //     requesting: false,
    //     adminsDeleted: [...payload],
    //   };
    default:
      return {
        ...state,
      };
  }
};
