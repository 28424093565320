const initialState = {
  requesting: true,
  data: {
    loading: true,
  },
  view_details: {},
  airlineRole: '',
  airlineWTRole: '',
};

export const airlineAgentGeneralRateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'RETRIEVE_GENERAL_RATE':
      return {
        ...state,
        requesting: false,
        data: {
          loading: false,
          ...payload,
        },
      };

    case 'RETRIEVE_LIST_AIRLINE_AGENT':
      return {
        ...state,
        airlineRole: payload,
        requesting: false,
      };

    case 'RETRIEVE_WEIGHT_RANGE_LIST_AIRLINE_AGENT':
      return {
        ...state,
        airlineWTRole: payload,
        requesting: false,
      };

    case 'RETRIEVE_FILTERED_GENERAL_RATE':
      return {
        ...state,
        requesting: false,
        data: {
          loading: false,
          ...payload,
        },
      };
    case 'VIEW_AIRFARE_DETAILS':
      return {
        ...state,
        requesting: false,
        view_details: payload,
      };
    default:
      return state;
  }
};
