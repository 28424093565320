import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Auth from './Auth';
import keyValidationReducer from './keyValidation';

import addressReducer from './Address';
import quotationReducer from './Quotation';
import economicsReducer from 'pages/mainPages/FreightForwarder/Admin/Economics/redux/reducer';

import { airlineAdminSpotRateReducer } from 'pages/mainPages/Airline/Admin/SpotRate/redux/reducers/reducers';
import { airlineAdminPriorityRateReducer } from 'pages/mainPages/Airline/Admin/PriorityRate/redux/reducers/reducers';
import { airlineAdminGeneralRateReducer } from 'pages/mainPages/Airline/Admin/GeneralRate/redux/reducers/reducers';

import { airlineAgentSpotRateReducer } from 'pages/mainPages/Airline/Agent/SpotRate/redux/reducers/reducers';
import { airlineAgentPriorityRateReducer } from 'pages/mainPages/Airline/Agent/PriorityRate/redux/reducers/reducers';
import { airlineAgentGeneralRateReducer } from 'pages/mainPages/Airline/Agent/GeneralRate/redux/reducers/reducers';

import adminPackageReducer from './AdminPackage';
import { requestsReceivedByCompaniesReducer } from '../../pages/mainPages/FreightForwarder/Admin/Shipper/redux/reducers/requestsReceivedByCompaniesReducer';
import ffAdminSubsReducer from '../../pages/mainPages/FreightForwarder/Admin/AccountManagement/redux/reducer/FFAdminSubscription';
import subscriptionReducer from '../../pages/paymentPage/BuySubscription/redux/reducer';
import { airfareReducer } from 'pages/mainPages/DataEntry/Airfare/redux/reducers';
import { shipperRequestStatusChangeByAdminReducer } from 'pages/mainPages/FreightForwarder/Admin/Shipper/redux/reducers/reducers';
import { addShipperReducer } from 'pages/mainPages/FreightForwarder/Admin/Shipper/redux/reducers/reducers';
import { shipperRequestToFreightForwardersReducer } from 'pages/mainPages/Shipper/FreightForwarders/redux/reducers/reducers';
import { airlineCompaniesReducer } from 'pages/mainPages/SuperAdmin/Airline/Companies/CompanyList/redux/reducers/reducers';
import { freightForwarderCompaniesReducer } from 'pages/mainPages/SuperAdmin/FreightForwarder/Companies/CompanyList/redux/reducers/reducers';
import ffAdminquotationReducer from '../../pages/mainPages/FreightForwarder/Admin/Quotation/redux/reducer/index';
import ffAgentquotationReducer from '../../pages/mainPages/FreightForwarder/Agent/Quotation/redux/reducer/index';
import ffAdminAddressReducer from '../../pages/mainPages/FreightForwarder/Admin/Address/redux/reducer/index';
import ffAgentAddressReducer from '../../pages/mainPages/FreightForwarder/Agent/Address/redux/reducer/index';
import { retrieveAirlineAdminListReducer } from '../../pages/mainPages/SuperAdmin/Airline/Admins/redux/reducers';
import { retrieveFreightForwarderAdminListReducer } from 'pages/mainPages/SuperAdmin/FreightForwarder/Admin/redux/reducers';
import { FreightForwarderAdminWarehouseReducer } from 'pages/mainPages/FreightForwarder/Admin/WareHouse/redux/reducers';
import { FreightForwarderAgentWarehouseReducer } from 'pages/mainPages/FreightForwarder/Agent/WareHouse/redux/reducers';
import notificationsReducer from './Notification';
//import supAdminquotationReducer from "pages/mainPages/SuperAdmin/Quotation/redux/reducer";
//import supAdminAddressReducer from "pages/mainPages/SuperAdmin/Address/redux/reducer";
import { super_admin_statistics } from '../../pages/mainPages/SuperAdmin/Statistics/redux/reducers';
import { retrieve_agent_details_reducer } from '../../pages/mainPages/FreightForwarder/Admin/Agents/redux/reducers/index';
import { search_airport_list_reducer } from '../../pages/mainPages/Airline/Admin/Airports/redux/reducer/index';
import { airlineWeightRangeReducer } from '../../pages/mainPages/Airline/Admin/WeightRange/redux/reducers/reducers';
import { retrieveUsaAddressReducer } from '../../shared/components/AutoFillZipAddress/redux/reducer/index';
import economicsRangesReducer from 'pages/mainPages/FreightForwarder/Admin/EconomicsRanges/redux/reducer/index';
import { airportsReducer } from '../../pages/mainPages/SuperAdmin/Airport/redux/reducer/index';
import { retrieveShipperListReducer } from 'pages/mainPages/SuperAdmin/Shippers/redux/reducers';
import { retrieveFreightForwarderAgentListReducer } from 'pages/mainPages/SuperAdmin/FreightForwarder/Agents/redux/reducers';
import { retrieveFreightForwarderQuotationListReducer } from '../../pages/mainPages/SuperAdmin/FreightForwarder/Quotations/redux/reducers';
import { retrieveAirlineAgentListReducer } from 'pages/mainPages/SuperAdmin/Airline/Agents/redux/reducers';
// import { airlineAdminSurChargeReducer } from 'pages/mainPages/Airline/Admin/SurCharge/redux/reducers/index';
import { retrieveExtraSurchareListReducer } from '../../pages/mainPages/Airline/Admin/SurCharge/redux/reducers';
import { superAdminKeyGeneratorReducer } from 'pages/mainPages/SuperAdmin/KeyGenerator/redux/reducers/reducers.js';
// import ffClientReducer from 'pages/mainPages/Shipper/Quotation/redux/reducer';
import ffClientReducer from '../../pages/mainPages/FreightForwarder/Client/Quotation/redux/reducer'
import ffConsigneeReducer from '../../pages/mainPages/Shipper/Quotation/redux/reducer';
import { AccountManagementReducer } from '../../pages/mainPages/SuperAdmin/AccountManagement/redux/reducer';
import {FeedBackSuperAdminReducer} from  '../../pages/mainPages/SuperAdmin/Feedback/redux/reducer'
import AgentSubscription from '../../pages/mainPages/FreightForwarder/Agent/AccountManagement/redux/reducer/FFAdminSubscription';
const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    keyValidation: keyValidationReducer,
    address: addressReducer,
    quotation: quotationReducer,
    retrieveUsaAddress: retrieveUsaAddressReducer,

    // AIRLINE SCREEN
    airlineAdminGeneralRate: airlineAdminGeneralRateReducer,
    airlineAdminSpotRate: airlineAdminSpotRateReducer,
    airlineAdminPriorityRate: airlineAdminPriorityRateReducer,
    retirveExtraSurchareList: retrieveExtraSurchareListReducer,
    airlineAgentGeneralRate: airlineAgentGeneralRateReducer,
    airlineAgentSpotRate: airlineAgentSpotRateReducer,
    airlineAgentPriorityRate: airlineAgentPriorityRateReducer,
    airlineWeightRange: airlineWeightRangeReducer,
    airlineCompanies: airlineCompaniesReducer,
    // airlineAdminSurCharge: airlineAdminSurChargeReducer,

    airports: airportsReducer,
    accountSubscriptions:AccountManagementReducer,
    feedbackSuperAdmin:FeedBackSuperAdminReducer,

    freightForwarderCompanies: freightForwarderCompaniesReducer,
    economics: economicsReducer,
    adminPackage: adminPackageReducer,
    requestsReceivedByCompanies: requestsReceivedByCompaniesReducer,
    shipperStatusInAdmin: shipperRequestStatusChangeByAdminReducer,
    ffAdminSubscription: ffAdminSubsReducer,
    ffAgentSubscription:AgentSubscription,
    airfare: airfareReducer,
    subscriptions: subscriptionReducer,
    addShipper: addShipperReducer,
    shipperRequestToFreightForwarders: shipperRequestToFreightForwardersReducer,
    ffAdminQuotation: ffAdminquotationReducer,
    ffAgentQuotation: ffAgentquotationReducer,
    ffAdminAddress: ffAdminAddressReducer,
    ffAgentAddress: ffAgentAddressReducer,
    ffClient:ffClientReducer,
    ffConsignee:ffConsigneeReducer,

    FreightForwarderAdminWarehouse: FreightForwarderAdminWarehouseReducer,
    FreightForwarderAgentWarehouse: FreightForwarderAgentWarehouseReducer,
    notifications: notificationsReducer,
    //supAdminQuotation: supAdminquotationReducer,
    //supAdminAddress: supAdminAddressReducer,

    // SUPER ADMIN SCREEN
    retrieveShipperList: retrieveShipperListReducer,
    retrieveFreightForwarderAdminList: retrieveFreightForwarderAdminListReducer,
    retrieveFreightForwarderAgentList: retrieveFreightForwarderAgentListReducer,
    retrieveAirlineAdminList: retrieveAirlineAdminListReducer,
    retrieveAirlineAgentList: retrieveAirlineAgentListReducer,
    retrieveFreightForwarderQuotationList: retrieveFreightForwarderQuotationListReducer,
    super_admin_statistics: super_admin_statistics,
    retrieve_agent_details: retrieve_agent_details_reducer,
    search_airport_list: search_airport_list_reducer,
    economicsRanges: economicsRangesReducer,
    superAdminKeyGenerator: superAdminKeyGeneratorReducer,
  });

export default reducers;
