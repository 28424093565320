const initialState = {
  requesting: true,
  airfareList: [],
  airfareDetails: {
    requesting: true,
  },
  airlineCompaniesList: [],
    airfareDetailsPerPage:[
        
    ]
};

export const airfareReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'RETRIEVE_AIRFARE_LIST':
      return {
        ...state,
        requesting: false,
        airfareList: [...payload],
      };

    case 'RETRIEVE_AIRFARE_DETAILS':
      return {
        ...state,
        requesting: false,
        airfareDetails: {
          ...payload,
        },
      };

        case 'RETRIEVE_AIRFARE_DETAILS_LIST_PER_PAGE':
            return {
                ...state,
                requesting: false,
                airfareDetailsPerPage: {
                    ...payload
                }
            }
    case 'RETRIEVE_AIRLINE_COMPANIES_LIST':
      return {
        ...state,
        requesting: false,
        airlineCompaniesList: [...payload],
      };

    default:
      return state;
  }
};
