export const GET_SUBSCRIPTION_REQ = 'GET_SUBSCRIPTION_REQ';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_FAIL = 'GET_SUBSCRIPTION_FAIL';

export const ADD_ADDONS_REQ = 'ADD_ADDONS_REQ';
export const ADD_ADDONS_SUCCESS = 'ADD_ADDONS_SUCCESS';
export const ADD_ADDONS_FAIL = 'ADD_ADDONS_FAIL';

export const GET_TOTAL_ROLES_REQ = 'GET_TOTAL_ROLES_REQ';
export const GET_TOTAL_ROLES_SUCCESS = 'GET_TOTAL_ROLES_SUCCESS';
export const GET_TOTAL_ROLES_FAIL = 'GET_TOTAL_ROLES_FAIL';

export const GET_APPROVED_ADDON_REQ = 'GET_APPROVED_ADDON_REQ';
export const GET_APPROVED_ADDON_SUCCESS = 'GET_APPROVED_ADDON_SUCCESS';
export const GET_APPROVED_ADDON_FAIL = 'GET_APPROVED_ADDON_FAIL';

export const GET_AGENT_APPROVED_ADDON_REQ = 'GET_AGENT_APPROVED_ADDON_REQ';
export const GET_AGENT_APPROVED_ADDON_SUCCESS = 'GET_AGENT_APPROVED_ADDON_SUCCESS';
export const GET_AGENT_APPROVED_ADDON_FAIL = 'GET_AGENT_APPROVED_ADDON_FAIL';

export const PAY_NOW_REQ = 'PAY_NOW_REQ';
export const PAY_NOW_SUCCESS = 'PAY_NOW_SUCCESS';
export const PAY_NOW_FAIL = 'PAY_NOW_FAIL';

export const GET_SUBSCRIPTION_STATS_REQ = 'GET_SUBSCRIPTION_STATS_REQ';
export const GET_SUBSCRIPTION_STATS_SUCCESS = 'GET_SUBSCRIPTION_STATS_SUCCESS';
export const GET_SUBSCRIPTION_STATS_FAIL = 'GET_SUBSCRIPTION_STATS_FAIL';

export const GET_SUBSCRIPTION_DETAILS_BY_USER_REQ = 'GET_SUBSCRIPTION_DETAILS_BY_USER_REQ';
export const GET_SUBSCRIPTION_DETAILS_BY_USER_SUCCESS = 'GET_SUBSCRIPTION_DETAILS_BY_USER_SUCCESS';
export const GET_SUBSCRIPTION_DETAILS_BY_USER_FAIL = 'GET_SUBSCRIPTION_DETAILS_BY_USER_FAIL';

export const GET_AD_ONS_DETAILS_VIEW_REQ = 'GET_AD_ONS_DETAILS_VIEW_REQ';
export const GET_AD_ONS_DETAILS_VIEW_SUCCESS = 'GET_AD_ONS_DETAILS_VIEW_SUCCESS';
export const GET_AD_ONS_DETAILS_VIEW_FAIL = 'GET_AD_ONS_DETAILS_VIEW_FAIL';

export const GET_CLIENT_SECRET_KEY = 'GET_CLIEN_SECRET_KEY';
export const SAVE_ADDONS_DATA = 'SAVE_ADDONS_DATA';
export const COST_ROLE_TYPE = 'COST_ROLE_TYPE';
export const SAVE_TRANSACTION_ID = 'SAVE_TRANSACTION_ID';
export const PAYPAL_STATUS = 'PAYPAL_STATUS';
export const GET_TRANSACTION_DETAIL_REQ = 'GET_TRANSACTION_DETAIL_REQ';
export const GET_TRANSACTION_DETAIL_SUCCESS = 'GET_TRANSACTION_DETAIL_SUCCESS';
export const GET_TRANSACTION_DETAIL_FAIL = 'GET_TRANSACTION_DETAIL_FAIL';

export const GET_RECEIPT_DISCLAIMER_DETAIL_DATA_FF_AGENT = 'GET_RECEIPT_DISCLAIMER_DETAIL_DATA_FF_AGENT';

export const GET_TRANSACTION_PAYMENT_DETAILS_FF_AGENT = 'GET_TRANSACTION_PAYMENT_DETAILS_FF_AGENT';
