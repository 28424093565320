import React, { useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { makeStyles, Popover } from '@material-ui/core';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Scrollbar from '../Scrollbar';
import IntlMessages from '../../utility/IntlMessages';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import NotificationItem from './NotificationItem';
import { Fonts } from '../../../shared/constants/AppEnums';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAllNotifications } from '../../../redux/actions/Notification';
import { markAsReadNotification } from 'redux/actions/Notification';
import { SET_NOTIFICATIONS_FLAG_STATUS } from 'shared/constants/ActionTypes';

const useStyles = makeStyles((theme) => ({
  crPopover: {
    '& .MuiPopover-paper': {
      width: 260,
      [theme.breakpoints.up('sm')]: {
        width: 300,
      },
      [theme.breakpoints.up('xl')]: {
        width: 380,
      },
    },
    '& .scroll-submenu': {
      maxHeight: 300,
      [theme.breakpoints.up('xl')]: {
        maxHeight: 480,
      },
    },
  },
  btnPopover: {
    borderRadius: 0,
    width: '100%',
    textTransform: 'capitalize',
  },
  notiBtn: {
    justifyContent: 'flex-start',
    width: '100%',
    height: 56,
    fontSize: 16,
    borderRadius: 0,
    // paddingLeft: '1rem',
    // paddingRight: '1rem',
    color: theme.palette.grey[800],
    '&:hover, &:focus': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      width: 'auto',
      // borderLeft: 'solid 1px',
      // borderLeftColor: theme.palette.grey[200],
      color: theme.palette.grey[400],
      '&:hover, &:focus': {
        color: theme.palette.text.primary,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: '1.5rem',
    //   paddingRight: '1.5rem',
    // },
    // [theme.breakpoints.up('xl')]: {
    //   paddingLeft: '2.5rem',
    //   paddingRight: '2.5rem',
    // },
  },
  notiIcon: {
    fontSize: 25,
    height: 40,
    width: 40,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('xl')]: {
      fontSize: 30,
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  badge: {
    marginRight: 8,
  },
}));

const role = [
  {
    Shipper: 'shipper',
  },
  { 'Data Entity': 'data-entry' },
  { 'FF Admin': 'freight-forwarder/admin' },
  { 'FF Agent': 'freight-forwarder/agent' },
  { 'Airline Admin': 'airline/admin' },
  { 'Airline Agent': 'airlene/agent' },
  { 'Super Admin': 'super-admin' },
];

const Notifications = (props) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const history = useHistory();

  const { profile } = useSelector((state) => state.auth);

  const [anchorNotification, setAnchorNotification] = React.useState(null);

  const { notification, loading: notificationLoading, notificationCount, notificationFlagStatus } = useSelector(
    (state) => state.notifications,
  );

  useEffect(() => {
    dispatch(getAllNotifications());
  }, []);

  const onClickNotificationButton = (event) => {
    setAnchorNotification(event.currentTarget);
  };

  useEffect(() => {
    if (anchorNotification !== null && notificationFlagStatus === true) {
      setAnchorNotification(null);
      dispatch({ type: SET_NOTIFICATIONS_FLAG_STATUS, payload: false });
    }
  }, [notificationFlagStatus]);

  return (
    <>
      <Tooltip title="Notifications" arrow>
        <IconButton
          className={clsx(classes.notiBtn, 'notiBtn')}
          aria-label="show 17 new notifications"
          color="inherit"
          onClick={onClickNotificationButton}
        >
          <Badge className={classes.badge} badgeContent={notificationCount} color="secondary">
            {/* <NotificationsActiveIcon className={clsx(classes.notiIcon, 'notiIcon')} /> */}
            <img src="/assets/images/sideMenu/header-notifications.png" className={clsx(classes.notiIcon, 'notiIcon')} />
          </Badge>
          <Hidden mdUp>
            <Box
              ml={4}
              fontSize={16}
              fontFamily="Poppins"
              fontWeight={Fonts.REGULAR}
              component="span"
            >
              <IntlMessages id="common.notifications" />
            </Box>
          </Hidden>
        </IconButton>
      </Tooltip>

      <Popover
        anchorEl={anchorNotification}
        id="language-switcher"
        className={classes.crPopover}
        keepMounted
        open={Boolean(anchorNotification)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setAnchorNotification(null)}
      >
        <Box>
          <Box px={5} py={3}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box component="h5" fontFamily="Poppins" fontSize={15}>
                <IntlMessages id="common.notifications" />({notificationCount})
              </Box>
              {notification.filter((n) => n.status === 'unread').length >= 1 && (
                <Box>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    style={{ fontSize: '0.7rem' }}
                    onClick={() =>
                      dispatch(
                        markAsReadNotification({
                          notificationIds: notification
                            .filter((notif) => notif.status === 'unread')
                            .map((item) => item.id),
                        }),
                      )
                    }
                  >
                    Mark All As Read
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Scrollbar className="scroll-submenu">
            <List
              className={classes.list}
            // onClick={() => {
            //   setAnchorNotification(null);
            // }}
            >
              {notification.map((item, index) => (
                <NotificationItem key={item.id} item={item} />
              ))}
            </List>
          </Scrollbar>
          {notification.length >= 5 && (
            <Box mt={2}>
              <Button
                className={classes.btnPopover}
                variant="contained"
                color="primary"
                onClick={() => {
                  const urlRole = role
                    .filter((item) => Object.keys(item)[0] === profile.role)
                    .map((item) => Object.values(item));

                  history.push(`/${urlRole[0]}/notification`);
                  setAnchorNotification(null);
                }}
              >
                <IntlMessages id="common.viewAll" />
              </Button>
            </Box>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default Notifications;
