import React from 'react';

export const errorRouteConfig = [
  {
    routes: [
      {
        path: '/error-pages/error-404',
        component: React.lazy(() => import('../../pages/errorPages/Error404')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/error-500',
        component: React.lazy(() => import('../../pages/errorPages/Error500')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/coming-soon',
        component: React.lazy(() => import('../../pages/errorPages/ComingSoon')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/maintenance',
        component: React.lazy(() => import('../../pages/errorPages/Maintenance')),
      },
    ],
  },
];
