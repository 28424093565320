const initialState = {
  requesting: true,
  approvedCompanies: [],
  unapprovedCompanies: [],
  approvalStatus: {},
  activeStatus: {},
  view_details: {},
  userList: [],
  extraSurcharge: [],
  localAirlinesCompany: [],
  history: [],
};

export const airlineCompaniesReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case '@@superAdmin/airline/history/data':
      return {
        ...state,
        requesting: false,
        history: payload,
      };

    case '@@superAdmin/airline/approvedCompanies':
      return {
        ...state,
        requesting: false,
        approvedCompanies: payload,
      };
    case 'LOCAL_AIRLINES':
      return {
        ...state,
        requesting: false,
        localAirlinesCompany: payload,
      };

    case '@@superAdmin/airline/unapprovedCompanies':
      return {
        ...state,
        requesting: false,
        unapprovedCompanies: payload,
      };

    case '@@superAdmin/airline/approveCompany':
      return {
        ...state,
        requesting: false,
        approvalStatus: payload,
      };
    case '@@activateAirline/super-admin/airline/companies':
      return {
        ...state,
        requesting: false,
        activeStatus: payload,
      };

    case 'RETRIEVE_EXTRA_SURCHARGE':
      return {
        ...state,
        extraSurcharge: payload
      }
    case 'VIEW_AIRFARE_DETAILS':
      return {
        ...state,
        view_details: payload,
      };
    case 'USER_LIST':
      return {
        ...state,
        userList: payload,
      };
    case '@@deactivateAirline/super-admin/airline/companies':
      return {
        ...state,
        requesting: false,
        activeStatus: payload,
      };
    default:
      return state;
  }
};
