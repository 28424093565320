// import {
//   GET_QUOTATIONS_REQ,
//   GET_QUOTATIONS_SUCCESS,
//   GET_QUOTATIONS_FAIL,
//   GET_QUOTATION_REQ,
//   GET_QUOTATION_SUCCESS,
//   GET_QUOTATION_FAIL,
//   UPDATE_QUOTATION_REQ,
//   UPDATE_QUOTATION_SUCCESS,
//   UPDATE_QUOTATION_FAIL,
//   DELETE_QUOTATION_REQ,
//   DELETE_QUOTATION_SUCCESS,
//   DELETE_QUOTATION_FAIL,
//   CREATE_QUOTATION_REQ,
//   CREATE_QUOTATION_SUCCESS,
//   CREATE_QUOTATION_FAIL,
//   GET_SEARCH_ADDRESS_REQ,
//   GET_SEARCH_ADDRESS_SUCCESS,
//   GET_SEARCH_ADDRESS_FAIL,
//   GET_SEARCH_ADDRESS2_REQ,
//   GET_SEARCH_ADDRESS2_SUCCESS,
//   GET_SEARCH_ADDRESS2_FAIL,
//   GET_SHIPPER_COMPANY_LIST_REQ,
//   GET_SHIPPER_COMPANY_LIST_SUCCESS,
//   GET_SHIPPER_COMPANY_LIST_FAIL,
//   GET_SHIP_FROM_AIRPORT_REQ,
//   GET_SHIP_FROM_AIRPORT_SUCCESS,
//   GET_SHIP_FROM_AIRPORT_FAIL,
//   GET_SHIP_TO_AIRPORT_REQ,
//   GET_SHIP_TO_AIRPORT_SUCCESS,
//   GET_SHIP_TO_AIRPORT_FAIL,
// } from '../../shared/constants/ActionTypes';

// const INIT_STATE = {
//   quotationList: [],
//   loading: false,
//   singleQuotation: {},
//   addressResult: [],
//   addressResult2: [],
//   companyList: [],
//   shipFromAirportList: [],
//   shipToAirportList: [],
// };

// const quotationReducer = (state = INIT_STATE, action) => {
//   switch (action.type) {
//     case GET_QUOTATIONS_REQ:
//     case GET_QUOTATION_REQ:
//       return {
//         ...state,
//         loading: true,
//       };
//     case GET_QUOTATIONS_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         quotationList: action.payload,
//       };

//     case GET_QUOTATION_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         singleQuotation: action.payload,
//       };
//     case GET_QUOTATIONS_FAIL:
//     case GET_QUOTATION_FAIL:
//       return {
//         ...state,
//         loading: false,
//       };

//     case CREATE_QUOTATION_REQ:
//     case UPDATE_QUOTATION_REQ:
//     case DELETE_QUOTATION_REQ:
//       return {
//         ...state,
//         loading: true,
//       };
//     case CREATE_QUOTATION_SUCCESS:
//     case UPDATE_QUOTATION_SUCCESS:
//     case DELETE_QUOTATION_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//       };
//     case CREATE_QUOTATION_FAIL:
//     case UPDATE_QUOTATION_FAIL:
//     case DELETE_QUOTATION_FAIL:
//       return {
//         ...state,
//         loading: false,
//       };

//     case GET_SEARCH_ADDRESS_REQ:
//       return {
//         ...state,
//         loading: true,
//       };
//     case GET_SEARCH_ADDRESS_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         addressResult: action.payload,
//       };
//     case GET_SEARCH_ADDRESS_FAIL:
//       return {
//         ...state,
//         loading: false,
//       };
//     case GET_SEARCH_ADDRESS2_REQ:
//       return {
//         ...state,
//         loading: true,
//       };
//     case GET_SEARCH_ADDRESS2_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         addressResult2: action.payload,
//       };
//     case GET_SEARCH_ADDRESS2_FAIL:
//       return {
//         ...state,
//         loading: false,
//       };
//     case GET_SHIPPER_COMPANY_LIST_REQ:
//       return {
//         ...state,
//         loading: true,
//       };
//     case GET_SHIPPER_COMPANY_LIST_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         companyList: action.payload,
//       };
//     case GET_SHIPPER_COMPANY_LIST_FAIL:
//       return {
//         ...state,
//         loading: false,
//       };

//     case GET_SHIP_FROM_AIRPORT_REQ:
//       return {
//         ...state,
//         loading: true,
//       };
//     case GET_SHIP_FROM_AIRPORT_SUCCESS:
//       return {
//         ...state,
//         shipFromAirportList: action.payload,
//         loading: false,
//       };
//     case GET_SHIP_FROM_AIRPORT_FAIL:
//       return {
//         ...state,
//         loading: false,
//       };
//     case GET_SHIP_TO_AIRPORT_REQ:
//       return {
//         ...state,
//         loading: true,
//       };
//     case GET_SHIP_TO_AIRPORT_SUCCESS:
//       return {
//         ...state,
//         shipToAirportList: action.payload,
//         loading: false,
//       };

//     case GET_SHIP_TO_AIRPORT_FAIL:
//       return {
//         ...state,
//         loading: false,
//       };
//     default:
//       return state;
//   }
// };
// export default quotationReducer;



import {
  POST_SHIPPER_QUOTATIONS_PRICE_REQ,
  POST_SHIPPER_QUOTATIONS_PRICE_SUCCESS,
  POST_SHIPPER_QUOTATIONS_PRICE_FAIL,
  GET_SHIPPER_QUOTATIONS_REQ,
  GET_SHIPPER_QUOTATIONS_SUCCESS,
  GET_SHIPPER_QUOTATIONS_FAIL,
  GET_SHIPPER_QUOTATION_REQ,
  GET_SHIPPER_QUOTATION_SUCCESS,
  GET_SHIPPER_QUOTATION_FAIL,
  UPDATE_SHIPPER_QUOTATION_REQ,
  UPDATE_SHIPPER_QUOTATION_SUCCESS,
  UPDATE_SHIPPER_QUOTATION_FAIL,
  DELETE_SHIPPER_QUOTATION_REQ,
  DELETE_SHIPPER_QUOTATION_SUCCESS,
  DELETE_SHIPPER_QUOTATION_FAIL,
  CREATE_SHIPPER_QUOTATION_REQ,
  CREATE_SHIPPER_QUOTATION_SUCCESS,
  CREATE_SHIPPER_QUOTATION_FAIL,
  GET_SHIPPER_SEARCH_ADDRESS_REQ,
  GET_SHIPPER_SEARCH_ADDRESS_SUCCESS,
  GET_SHIPPER_SEARCH_ADDRESS_FAIL,
  GET_SHIPPER_SEARCH_ADDRESS2_REQ,
  GET_SHIPPER_SEARCH_ADDRESS2_SUCCESS,
  GET_SHIPPER_SEARCH_ADDRESS2_FAIL,
  GET_REQUEST_QUOTE_REQ,
  GET_REQUEST_QUOTE_SUCCESS,
  GET_REQUEST_QUOTE_FAIL,
  GET_SHIP_FROM_AIRPORT_REQ,
  GET_SHIP_FROM_AIRPORT_SUCCESS,
  GET_SHIP_FROM_AIRPORT_FAIL,
  GET_SHIP_TO_AIRPORT_REQ,
  GET_SHIP_TO_AIRPORT_SUCCESS,
  GET_SHIP_TO_AIRPORT_FAIL,
  GET_COUNTRY_REQ,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAIL,
  GET_SHIP_TO_QUICK_AIRPORT_REQ,
  GET_SHIP_TO_QUICK_AIRPORT_SUCCESS,
  GET_SHIP_TO_QUICK_AIRPORT_FAIL,
  GET_SEARCH_BY_ZIP_REQ,
  GET_SEARCH_BY_ZIP_SUCCESS,
  GET_SEARCH_BY_ZIP_FAIL,
  CHECK_QUOTE_AVAILABLE_REQ,
  CHECK_QUOTE_AVAILABLE_SUCCESS,
  CHECK_QUOTE_AVAILABLE_FAIL,
  SET_QUOTATION_DATA,
  SEND_QUOTE_BY_EMAIL_REQ,
  SEND_QUOTE_BY_EMAIL_SUCCESS,
  SEND_QUOTE_BY_EMAIL_FAIL,
  GET_AIRPORT_DEPATURE_LIST,
  STORE_RADIO_OPTION,
  GET_EMPTY_COUNTRY_LIST,
  POST_SHIPPER_QUOTATION_PDF_REQ,
  POST_SHIPPER_QUOTATION_PDF_SUCCESS,
  POST_SHIPPER_QUOTATION_PDF_FAIL,
  GET_SHIPPER_COMPANY_LIST_REQ,
  GET_SHIPPER_COMPANY_LIST_SUCCESS,
  GET_SHIPPER_COMPANY_LIST_FAIL,
} from "../../shared/constants/ActionTypes";

const INIT_STATE = {
  quotationList: [],
  userSelection: '',
  loading: false,
  singleQuotation: {},
  addressResult: [],
  addressResult2: [],
  reqQuoteList: [],
  shipFromAirportList: [],
  shipToAirportList: [],
  quotationResult: {},
  countryList: [],
  shipToQuickAirportList: [],
  addressByZip: {},
  storedQuotation: {},
  airportOfDepartureList: [],
  pdfrequest: false,
  pdfData: [],
  quotationListLoading: true,
  companyList: [],
  stateData:[]
};

const quotationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SHIPPER_QUOTATIONS_REQ:
    case GET_SHIPPER_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIPPER_QUOTATIONS_SUCCESS:
      return {
        ...state,
        quotationListLoading: false,
        quotationList: action.payload,
      };

    case POST_SHIPPER_QUOTATIONS_PRICE_REQ:
      return {
        ...state,
        loading: true,
      };

    case POST_SHIPPER_QUOTATIONS_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        quotationList: action.payload,
      };

    case POST_SHIPPER_QUOTATIONS_PRICE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_SHIPPER_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        singleQuotation: action.payload,
      };
    case GET_SHIPPER_QUOTATIONS_FAIL:
    case GET_SHIPPER_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_SHIPPER_QUOTATION_REQ:
    case UPDATE_SHIPPER_QUOTATION_REQ:
    case DELETE_SHIPPER_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_SHIPPER_QUOTATION_SUCCESS:
    case DELETE_SHIPPER_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CREATE_SHIPPER_QUOTATION_SUCCESS:
      return {
        ...state,
        quotationResult: action.payload,
        loading: false,
      };
    case CREATE_SHIPPER_QUOTATION_FAIL:
    case UPDATE_SHIPPER_QUOTATION_FAIL:
    case DELETE_SHIPPER_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_SHIPPER_SEARCH_ADDRESS_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIPPER_SEARCH_ADDRESS_SUCCESS:

      return {

        ...state,
        loading: false,
        addressResult: action.payload,
      };
    case GET_SHIPPER_SEARCH_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIPPER_SEARCH_ADDRESS2_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIPPER_SEARCH_ADDRESS2_SUCCESS:
      return {
        ...state,
        loading: false,
        addressResult2: action.payload,
      };
    case GET_SHIPPER_SEARCH_ADDRESS2_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_REQUEST_QUOTE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_REQUEST_QUOTE_SUCCESS:
      return {
        ...state,
        reqQuoteList: action.payload,
        loading: false,
      };
    case GET_REQUEST_QUOTE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIP_FROM_AIRPORT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIP_FROM_AIRPORT_SUCCESS:
      return {
        ...state,
        shipFromAirportList: action.payload,
        loading: false,
      };
    case GET_SHIP_FROM_AIRPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIP_TO_AIRPORT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIP_TO_AIRPORT_SUCCESS:
      return {
        ...state,
        shipToAirportList: action.payload,
        loading: false,
      };

    case GET_SHIP_TO_AIRPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_COUNTRY_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countryList: action.payload,
      };
    case GET_COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIP_TO_QUICK_AIRPORT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIP_TO_QUICK_AIRPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        shipToQuickAirportList: action.payload,
      };
    case GET_SHIP_TO_QUICK_AIRPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SEARCH_BY_ZIP_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SEARCH_BY_ZIP_SUCCESS:
      return {
        ...state,
        loading: false,
        addressByZip: action.payload,
        stateData:action.payload.places	
      };
    case GET_SEARCH_BY_ZIP_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CHECK_QUOTE_AVAILABLE_REQ:
      return {
        ...state,
        loading: true,
      };
    case CHECK_QUOTE_AVAILABLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHECK_QUOTE_AVAILABLE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SET_QUOTATION_DATA:
      return {
        ...state,
        storedQuotation: action.payload,
      };
    case SEND_QUOTE_BY_EMAIL_REQ:
      return {
        ...state,
        loading: true,
      };
    case SEND_QUOTE_BY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SEND_QUOTE_BY_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_AIRPORT_DEPATURE_LIST: {
      return {
        ...state,
        airportOfDepartureList: action.payload
      }
    }
    case STORE_RADIO_OPTION: {
      return {
        ...state,
        userSelection: action.payload
      }
    }
    case GET_EMPTY_COUNTRY_LIST: {
      return {
        ...state,
        shipToQuickAirportList: []

      }
    }
    case POST_SHIPPER_QUOTATION_PDF_REQ: {
      return {
        ...state,
        pdfrequest: true
      }
    }
    case POST_SHIPPER_QUOTATION_PDF_FAIL: {
      return {
        ...state,
        pdfrequest: false
      }

    }
    case POST_SHIPPER_QUOTATION_PDF_SUCCESS: {
      return {
        ...state,
        pdfData: action.payload,
        pdfrequest: false
      }
    }
    case GET_SHIPPER_COMPANY_LIST_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIPPER_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        companyList: action.payload,
      };
    case GET_SHIPPER_COMPANY_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default quotationReducer;

