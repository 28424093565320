import React, { lazy } from 'react';

export const truckingRouteConfig = [
  {
    auth: ['ROLE_TRUCKING_ADMIN'],
    routes: [
      // AIRLINE STATISTICS
      {
        path: '/trucking/statistics',
        component: lazy(() => import('pages/mainPages/Trucking/Statistics/Statistics')),
      },
    ],
  },
];
