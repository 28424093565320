import { airlineAdminRouteConfig } from './airlineAdmin';
import { airlineAgentRouteConfig } from './airlineAgent';
import { dataEntryRouteConfig } from './dataEntry';
import { ffAdminRouteConfig } from './ffAdmin';
import { ffAgentRouteConfig } from './ffAgent';
import { shipperRouteConfig } from './shipper';
import { ffClientRouteConfig } from './ffClient';
import { superAdminRouteConfig } from './superAdmin';
import { truckingRouteConfig } from './truckingAdmin';

export const mainRouteConfig = [
  ...superAdminRouteConfig,
  ...airlineAdminRouteConfig,
  ...airlineAgentRouteConfig,
  ...dataEntryRouteConfig,
  ...ffAdminRouteConfig,
  ...ffAgentRouteConfig,
  ...ffClientRouteConfig,
  ...shipperRouteConfig,
  ...truckingRouteConfig,
];
