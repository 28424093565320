const initialState = {
  requesting: true,
  data: {
    loading: true,
  },
  freight_forwarder_company_list: [],
  view_details: {},
  retrieveAirlineAgentDetails: []
};

export const airlineAgentSpotRateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'RETRIEVE_SPOT_RATE':
      return {
        ...state,
        requesting: false,
        data: {
          loading: false,
          ...payload,
        },
      };

    case 'RETRIEVE_FREIGHT_FORWARDER_COMPANY_LIST':
      return {
        ...state,
        requesting: false,
        freight_forwarder_company_list: [...payload],
      };
    case 'RETRIEVE_FILTERED_SPOT_RATE':
      return {
        ...state,
        requesting: false,
        data: {
          loading: false,
          ...payload,
        },
      };
    case 'VIEW_AIRFARE_DETAILS':
      return {
        ...state,
        requesting: false,
        view_details: payload,
      };
    case 'RETRIEVE_AIRLINE_AGENT_AIRFARE_RECORD':
      return {
        ...state,
        requesting: false,
        retrieveAirlineAgentDetails: payload,
      };

    default:
      return state;
  }
};
