const initialState = {
  requesting: true,
  retrieveWarehouse: {},
  retrieveWarehouseList: [],
};

export const FreightForwarderAgentWarehouseReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@@retrieveWarehouse':
      return {
        ...state,
        requesting: false,
        retrieveWarehouse: {
          ...payload,
        },
      };
    case '@@retrieveWarehouseList':
      return {
        ...state,
        requesting: false,
        retrieveWarehouseList: [...payload],
      };
    default:
      return {
        ...state,
      };
  }
};
