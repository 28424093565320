const initialState = {
  requesting: true,
  data: {
    loading: true,
  },
  freight_forwarder_company_list: [],
  view_details: { },
};

export const airlineAgentPriorityRateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'RETRIEVE_PRIORITY_RATE':
      return {
        ...state,
        requesting: false,
        data: {
          loading: false,
          ...payload,
        },
      };

    case 'RETRIEVE_FREIGHT_FORWARDER_COMPANY_LIST':
      return {
        ...state,
        requesting: false,
        freight_forwarder_company_list: [...payload],
      };

    case 'RETRIEVE_FILTERED_PRIORITY_RATE':
      return {
        ...state,
        requesting: false,
        data: {
          loading: false,
          ...payload,
        },
      };

    case 'VIEW_AIRFARE_DETAILS':
      return {
        ...state,
        requesting: false,
        view_details: payload,
      };
    default:
      return state;
  }
};
