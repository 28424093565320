import {
  GET_SHIPPER_QUOTATIONS_REQ,
  GET_SHIPPER_QUOTATIONS_SUCCESS,
  GET_SHIPPER_QUOTATIONS_FAIL,
  POST_FFADMIN_QUOTATIONS_PRICE_REQ,
  POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS,
  POST_FFADMIN_QUOTATIONS_PRICE_FAIL,
  GET_FFADMIN_QUOTATIONS_REQ,
  GET_FFADMIN_QUOTATIONS_SUCCESS,
  GET_FFADMIN_QUOTATIONS_FAIL,
  GET_FFADMIN_QUOTATION_REQ,
  GET_FFADMIN_QUOTATION_SUCCESS,
  GET_FFADMIN_QUOTATION_FAIL,
  UPDATE_FFADMIN_QUOTATION_REQ,
  UPDATE_FFADMIN_QUOTATION_SUCCESS,
  UPDATE_FFADMIN_QUOTATION_FAIL,
  DELETE_FFADMIN_QUOTATION_REQ,
  DELETE_FFADMIN_QUOTATION_SUCCESS,
  DELETE_FFADMIN_QUOTATION_FAIL,
  CREATE_FFADMIN_QUOTATION_REQ,
  CREATE_FFADMIN_QUOTATION_SUCCESS,
  CREATE_FFADMIN_QUOTATION_FAIL,
  GET_FFADMIN_SEARCH_ADDRESS_REQ,
  GET_FFADMIN_SEARCH_ADDRESS_SUCCESS,
  GET_FFADMIN_SEARCH_ADDRESS_FAIL,
  GET_FFADMIN_SEARCH_ADDRESS2_REQ,
  GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS,
  GET_FFADMIN_SEARCH_ADDRESS2_FAIL,
  GET_REQUEST_QUOTE_REQ,
  GET_REQUEST_QUOTE_SUCCESS,
  GET_REQUEST_QUOTE_FAIL,
  GET_SHIP_FROM_AIRPORT_REQ,
  GET_SHIP_FROM_AIRPORT_SUCCESS,
  GET_SHIP_FROM_AIRPORT_FAIL,
  GET_SHIP_TO_AIRPORT_REQ,
  GET_SHIP_TO_AIRPORT_SUCCESS,
  GET_SHIP_TO_AIRPORT_FAIL,
  GET_COUNTRY_REQ,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAIL,
  GET_SHIP_TO_QUICK_AIRPORT_REQ,
  GET_SHIP_TO_QUICK_AIRPORT_SUCCESS,
  GET_SHIP_TO_QUICK_AIRPORT_FAIL,
  GET_SEARCH_BY_ZIP_REQ,
  GET_SEARCH_BY_ZIP_SUCCESS,
  GET_SEARCH_BY_ZIP_FAIL,
  CHECK_QUOTE_AVAILABLE_REQ,
  CHECK_QUOTE_AVAILABLE_SUCCESS,
  CHECK_QUOTE_AVAILABLE_FAIL,
  SET_QUOTATION_DATA,
  SEND_QUOTE_BY_EMAIL_REQ,
  SEND_QUOTE_BY_EMAIL_SUCCESS,
  SEND_QUOTE_BY_EMAIL_FAIL,
  GET_AIRPORT_DEPATURE_LIST,
  STORE_RADIO_OPTION,
  GET_EMPTY_COUNTRY_LIST,
  POST_FFADMIN_QUOTATION_PDF_REQ,
  POST_FFADMIN_QUOTATION_PDF_SUCCESS,
  POST_FFADMIN_QUOTATION_PDF_FAIL,
  GET_UPDATED_QUOTATION_LIST,
  GET_SHIPPER_LIST_REQ,
  GET_SHIPPER_LIST_SUCCESS,
  GET_SHIPPER_LIST_FAIL,
  SAVE_QUOTATION_DATA,
  SAVE_CHECKBOX_DATA,
  EDIT_SAVE_QUOTATION,
  EDIT_QUOTATION_REQ,
  EDIT_QUOTATION_SUCCESS,
  EDIT_QUOTATION_FAIL,
  SET_PREVIOUS_STATE,
  SET_UPDATE_PREVIOUS_STATE,
  SET_QUOTATION_UPDATE_UNIT,
  SET_SHIPPER_QUOTATION_REQ,
  SET_SHIPPER_QUOTATION_SUCCESS,
  SET_SHIPPER_QUOTATION_FAIL,
  GET_TRUKING_DATA,
  GET_RECALCULATE_VALUE,
  GET_DELIVERY_ZIPCODE_REQ,
  GET_DELIVERY_ZIPCODE_SUCCESS,
  GET_DELIVERY_ZIPCODE_FAIL,
  GET_UPDATE_DELIVERY_ZIPCODE_REQ,
  GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS,
  GET_UPDATE_DELIVERY_ZIPCODE_FAIL,
  GET_UPDATED_PICKUP_ZIPCODE_REQ,
  GET_UPDATED_PICKUP_ZIPCODE_SUCCESS,
  GET_UPDATED_PICKUP_ZIPCODE_FAIL,
  GET_UNASSIGNED_LIST_REQ,
  GET_UNASSIGNED_LIST_SUCCESS,
  GET_UNASSIGNED_LIST_FAIL,
  GET_ASSIGNED_LIST_REQ,
  GET_ASSIGNED_LIST_SUCCESS,
  GET_ASSIGNED_LIST_FAIL,
  SET_CREATE_SHIPPER_QUOTATION_REQ,
  SET_CREATE_SHIPPER_QUOTATION_FAIL,
  SET_CREATE_SHIPPER_QUOTATION_SUCCESS,
  GET_CLIENT_COUNTRY_LIST,
  GET_CLIENT_COUNTRY_BASED_DATA,
  GET_SEND_REQUEST_STATUS,
  GET_FEED_BACK_LIST_REQ_FOR_CLIENT_AND_SHIPPER,
  GET_FEEDBACK_LIST_SUCCESS_FOR_CLIENT_AND_SHIPPER,
  GET_FEEDBACL_LIST_FAIL_FOR_CLIENT_AND_SHIPPER,
  GET_FFCLIENT_SEARCH_ADDRESS2_REQ_QUOTEFORMODEL,
  GET_FFCLIENT_SEARCH_ADDRESS2_SUCCESS_QUOTEFORMODEL,
  GET_FFCLIENT_SEARCH_ADDRESS2_FAIL_QUOTEFORMODEL,
  GET_FFCLIENT_REQ_QUOTEFORMODEL_LIST,
  GET_FFCLIENT_SUCCESS_QUOTEFORMODEL_LIST,
  GET_FFCLIENT_FAIL_QUOTEFORMODEL_LIST,
  GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_REQ,
  GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS,
  GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_FAIL,
  GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS_SAVE_RECORD,
  GET_COMBINED_PDF,
  PRINT_MANUAL_QUOTATION_REQ,
  PRINT_MANUAL_QUOTATION_SUCCESS,
  PRINT_MANUAL_QUOTATION_FAIL,
  SAVE_MANUAL_QUOTATION_REQ,
  SAVE_MANUAL_QUOTATION_SUCCESS,
  SAVE_MANUAL_QUOTATION_FAIL,
  CREATE_TRUKCING_QUOTATION_REQ,
  CREATE_TRUKCING_QUOTATION_SUCCESS,
  CREATE_TRUKCING_QUOTATION_FAIL,
  GET_UNIT_MEASUREMENT,
  GET_AIRLINE_LIST1,
  GET_AIRLINE_LIST,
  SAVED_QUOTATION_ID,
  GET_USA_AIRPORTS_LIST_REQ,
  GET_USA_AIRPORTS_LIST_SUCCESS,
  GET_USA_AIRPORTS_LIST_FAIL,
  GET_MANUAL_QUOTATION_SUCCESS,
  GET_MANUAL_QUOTATION_REQ,
  GET_MANUAL_QUOTATION_FAIL,
  PRINT_MANUAL_QUOTATION_DETAIL_REQ,
  SHARE_MANUAL_QUOTATION_DETAIL_REQ,
  SHARE_MANUAL_QUOTATION_DETAIL_SUCCESS,
  SHARE_MANUAL_QUOTATION_DETAIL_FAIL,
  PRINT_MANUAL_QUOTATION_DETAIL_SUCCESS,
  PRINT_MANUAL_QUOTATION_DETAIL_FAIL,
  SHARE_MANUAL_QUOTATION_REQ,
  SHARE_MANUAL_QUOTATION_SUCCESS,
  SHARE_MANUAL_QUOTATION_FAIL,
  GET_LOCAL_AIRPORTS_LIST_SUCCESS,
  GET_DISCLAIMER_DETAIL_DATA,
  GET_USA_AIRPORTS_TIER2_LIST_SUCCESS,
  GET_PREFERRED_AIRPORTS_REQUEST,
  GET_PREFERRED_AIRPORTS_SUCCESS,
  GET_PREFERRED_AIRPORTS_FAIL,
  GET_PREFERRED_AIRPORTS_INITIAL
} from '../constants';

const INIT_STATE = {
  quotationList: [],
  userSelection: '',
  loading: false,
  singleQuotation: {},
  singleQuotation2: {},
  addressResult: [],
  addressResult2: [],
  reqQuoteList: [],
  shipFromAirportList: [],
  shipToAirportList: [],
  quotationResult: {},
  // shipperQuotataionCreateDetail: {},
  countryList: [],
  shipToQuickAirportList: [],
  addressByZip: {},
  stateData: [],
  storedQuotation: {},
  airportOfDepartureList: [],
  pdfrequest: false,
  pdfData: [],
  quotationListLoading: true,
  updatedQuotation: [],
  shipperLoading: true,
  shipperData: [],
  saveQuotation: {},
  checkBoxOption: {},
  date: '',
  unit: '',
  editSaveQuotaion: {},
  editSaveQuotationLoading: false,
  departure: '',
  destination: '',
  truckingCarriersDummyVariable: [],
  previousUnit: 'Imperial / Metric',
  getUpdatePreviousUnit: '',
  getQuoteUpdateUnit: '',
  shipperQuotation: {},
  // shipperQuotataionCreateDetail: {},
  statusFromUpdate: false,
  preAirportOfDepatureDetails: '',
  preAirportOfDestinationDetails: '',
  deliveryAdressByzip: {},
  deliveryPlace: [],
  singleQuotationDetail: {},
  confirmStatus: '',
  comment: '',
  pickUpAdress: {},
  pickAdressData: [],
  updatedAddress: {},
  updatedArrayData: [],
  unassignedLoading: false,
  unassignedList: [],
  assignedListLoading: false,
  assignedList: [],
  clientCountryList: [],
  countryData: [],
  clientSendRequestStatus: false,
  feedBackList: [],
  feedBacklistReq: false,
  addressResult2QuoteForModel: [],
  quoteForModelList: [],
  inquirerNamesByCompanyName: [],
  inquirerNamesByCompanyNameFinal: [],
  commonLoaderForManual: false,
  quoteRefrenceId: '',
  truckingQuotation: {},
  truckingQuotationLoading: false,
  unitType: '',
  manualQuoteData: {},
  savedQuotationId: '',
  airlineList1: [],
  airlineList2: [],
  checkForSubmission: false,
  checkFormodal: false,
  editManualQuoteData: {},
  usaAirportsList: [],
  manualQuoationReq: false,
  manualQuotationData: {},
  commonLoaderForManaualQuotationDetail: false,
  localUsaAirports: [],
  storeManualQuotationData:{},
  disclaimerData:{},
  usaAirportsListTier2: [],
  preferredAirportsList: [],
};

const ffClientReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FEED_BACK_LIST_REQ_FOR_CLIENT_AND_SHIPPER:
      return {
        ...state,
        feedBacklistReq: true,
      };
    case SAVED_QUOTATION_ID:
      return {
        ...state,
        savedQuotationId: action.payload
      }
    case GET_MANUAL_QUOTATION_REQ:
      return {
        ...state,
        manualQuoationReq: true,
      };

    case GET_MANUAL_QUOTATION_FAIL:
      return {
        ...state,
        manualQuoationReq: false,
      };

    case GET_MANUAL_QUOTATION_SUCCESS:
      let manaulData = {};
      if (action.payload === '') {
        manaulData = {}
      }
      else {
        manaulData = action.payload?.replayQuotation
      }
      return {
        ...state,
        manualQuoationReq: false,
        manualQuotationData: manaulData,
        storeManualQuotationData:action.payload
      };
    case GET_FEEDBACK_LIST_SUCCESS_FOR_CLIENT_AND_SHIPPER:
      return {
        ...state,
        feedBacklistReq: false,
        feedBackList: action.payload,
      };
    case GET_FEEDBACL_LIST_FAIL_FOR_CLIENT_AND_SHIPPER:
      return {
        ...state,
        feedBacklistReq: false,
      };

      case GET_DISCLAIMER_DETAIL_DATA:
        return {
          ...state,
          disclaimerData: action?.payload
        }

    case GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        inquirerNamesByCompanyName: action.payload,
      };
    case GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS_SAVE_RECORD:
      return {
        ...state,
        loading: false,
        inquirerNamesByCompanyNameFinal: action.payload,
      };

    case GET_SHIPPER_QUOTATIONS_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIPPER_QUOTATIONS_SUCCESS:
      return {
        ...state,
        quotationListLoading: false,
        quotationList: action.payload,
        singleQuotation: {},
        singleQuotationDetail: {},
        confirmStatus: '',
      };
    case GET_SHIPPER_QUOTATIONS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case 'RETRIEVE_PRE_AIRPORT_OF_DEPARTURE_DETAILS':
      return {
        ...state,
        preAirportOfDepatureDetails: action.payload,
        requesting: false,
      };

    case 'RETRIEVE_PRE_AIRPORT_OF_DESTINATION_DETAILS':
      return {
        ...state,
        preAirportOfDestinationDetails: action.payload,
        requesting: false,
      };

      case 'EDIT_MANUAL_QUOATION':
        return {
          ...state,
          editManualQuoteData: action.payload
        }

    case GET_FFADMIN_QUOTATIONS_REQ:
    case GET_FFADMIN_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_FFADMIN_QUOTATIONS_SUCCESS:
      return {
        ...state,
        quotationListLoading: false,
        quotationList: action.payload,
      };

    case POST_FFADMIN_QUOTATIONS_PRICE_REQ:
      return {
        ...state,
        loading: true,
      };

    case POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        quotationList: action.payload,
      };

    case POST_FFADMIN_QUOTATIONS_PRICE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_FFADMIN_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        singleQuotation: action.payload.replayQuotation,
        singleQuotationDetail: action.payload.quotation,
        confirmStatus: action.payload.confirmStatus,
        comment: action?.payload?.comment,

        singleQuotation2: action?.payload?.replayQuotation1,
      };
    case GET_FFADMIN_QUOTATIONS_FAIL:
    case GET_FFADMIN_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_FFADMIN_QUOTATION_REQ:
    case UPDATE_FFADMIN_QUOTATION_REQ:
    case DELETE_FFADMIN_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_FFADMIN_QUOTATION_SUCCESS:
    case DELETE_FFADMIN_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CREATE_FFADMIN_QUOTATION_SUCCESS:
      return {
        ...state,
        // truckingCarriersDummyVariable: action.payload.truckingCarriers,
        quotationResult: action.payload,
        // shipperQuotataionCreateDetail: action.payload,
        loading: false,
        statusFromUpdate: false,
      };
    case CREATE_FFADMIN_QUOTATION_FAIL:
    case UPDATE_FFADMIN_QUOTATION_FAIL:
    case DELETE_FFADMIN_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_FFADMIN_SEARCH_ADDRESS_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_FFADMIN_SEARCH_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        addressResult: action.payload,
      };
    case GET_FFADMIN_SEARCH_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_FFADMIN_SEARCH_ADDRESS2_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS:
      return {
        ...state,
        loading: false,
        addressResult2: action.payload,
      };
    case GET_FFADMIN_SEARCH_ADDRESS2_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_REQUEST_QUOTE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_REQUEST_QUOTE_SUCCESS:
      return {
        ...state,
        reqQuoteList: action.payload,
        loading: false,
      };
    case GET_REQUEST_QUOTE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIP_FROM_AIRPORT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIP_FROM_AIRPORT_SUCCESS:
      return {
        ...state,
        shipFromAirportList: action.payload,
        loading: false,
      };
    case GET_SHIP_FROM_AIRPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIP_TO_AIRPORT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIP_TO_AIRPORT_SUCCESS:
      return {
        ...state,
        shipToAirportList: action.payload,
        loading: false,
      };

    case GET_SHIP_TO_AIRPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_COUNTRY_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countryList: action.payload,
      };
    case GET_COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIP_TO_QUICK_AIRPORT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIP_TO_QUICK_AIRPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        shipToQuickAirportList: action.payload,
      };
    case GET_SHIP_TO_QUICK_AIRPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SEARCH_BY_ZIP_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SEARCH_BY_ZIP_SUCCESS:
      return {
        ...state,
        loading: false,
        addressByZip: action.payload,
        stateData: action.payload.places,
      };
    case GET_SEARCH_BY_ZIP_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CHECK_QUOTE_AVAILABLE_REQ:
      return {
        ...state,
        loading: true,
      };
    case CHECK_QUOTE_AVAILABLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHECK_QUOTE_AVAILABLE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SET_QUOTATION_DATA:
      return {
        ...state,
        storedQuotation: action.payload,
      };
    case SEND_QUOTE_BY_EMAIL_REQ:
      return {
        ...state,
        loading: true,
      };
    case SEND_QUOTE_BY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SEND_QUOTE_BY_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_AIRPORT_DEPATURE_LIST: {
      return {
        ...state,
        airportOfDepartureList: action.payload,
      };
    }
    case STORE_RADIO_OPTION: {
      return {
        ...state,
        userSelection: action.payload,
      };
    }
    case GET_EMPTY_COUNTRY_LIST: {
      return {
        ...state,
        shipToQuickAirportList: [],
      };
    }
    case POST_FFADMIN_QUOTATION_PDF_REQ: {
      return {
        ...state,
        pdfrequest: true,
      };
    }
    case POST_FFADMIN_QUOTATION_PDF_FAIL: {
      return {
        ...state,
        pdfrequest: false,
      };
    }
    case POST_FFADMIN_QUOTATION_PDF_SUCCESS: {
      return {
        ...state,
        pdfData: action.payload,
        pdfrequest: false,
      };
    }
    case GET_UPDATED_QUOTATION_LIST: {
      return {
        ...state,
        updatedQuotation: action.payload,
      };
    }

    case GET_SHIPPER_LIST_REQ:

    case GET_SHIPPER_LIST_SUCCESS:
      return {
        shipperLoading: false,
        ...state,
        shipperData: action.payload,
        shipperQuotation: {},
        // quotationResult: {},
      };

    case SAVE_QUOTATION_DATA:
      return {
        ...state,
        saveQuotation: action.payload.data,
        date: action.payload.date,
        unit: action.payload.unit,
        departure: action.payload.airpDep,
        destination: action.payload.airpDest,
      };

    case SAVE_CHECKBOX_DATA:
      return {
        ...state,
        checkBoxOption: action.payload,
      };
    case GET_SHIPPER_LIST_FAIL:

    case EDIT_SAVE_QUOTATION:
      return {
        ...state,
        editSaveQuotaion: action.payload,
      };

    case EDIT_QUOTATION_REQ:
      return {
        ...state,
        editSaveQuotationLoading: true,
      };

    case EDIT_QUOTATION_SUCCESS:
      return {
        ...state,
        // singleQuotation: action.payload,
        quotationResult: action.payload,

        editSaveQuotationLoading: false,
      };

    case EDIT_QUOTATION_FAIL:
      return {
        ...state,
        editSaveQuotationLoading: false,
      };

    case SET_PREVIOUS_STATE:
      return {
        ...state,
        previousUnit: action.payload,
      };

    case SET_UPDATE_PREVIOUS_STATE:
      return {
        ...state,
        getUpdatePreviousUnit: action.payload,
      };
    case SET_QUOTATION_UPDATE_UNIT:
      return {
        ...state,
        getQuoteUpdateUnit: action.payload,
      };

    case SET_SHIPPER_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };
    case SET_SHIPPER_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        shipperQuotation: action.payload,
        quotationResult: {},
      };
    case SET_SHIPPER_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case SET_CREATE_SHIPPER_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };
    case SET_CREATE_SHIPPER_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        quotationResult: action.payload,
        // shipperQuotataionCreateDetail: action.payload,
      };
    case SET_CREATE_SHIPPER_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_TRUKING_DATA:
      return {
        ...state,
        truckingCarriersDummyVariable: action.payload,
      };
    case GET_RECALCULATE_VALUE: {
      return {
        ...state,
        statusFromUpdate: true,
      };
    }

    case GET_DELIVERY_ZIPCODE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_DELIVERY_ZIPCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryAdressByzip: action.payload,
        deliveryPlace: action.payload.places,
      };
    case GET_DELIVERY_ZIPCODE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_UPDATE_DELIVERY_ZIPCODE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedAddress: action.payload,
        updatedArrayData: action.payload.places,
      };
    case GET_UPDATE_DELIVERY_ZIPCODE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_UPDATED_PICKUP_ZIPCODE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_UPDATED_PICKUP_ZIPCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        pickUpAdress: action.payload,
        pickAdressData: action.payload.places,
      };
    case GET_UPDATED_PICKUP_ZIPCODE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_UNASSIGNED_LIST_REQ:
      return {
        ...state,
        unassignedLoading: true,
      };
    case GET_UNASSIGNED_LIST_SUCCESS:
      return {
        ...state,
        unassignedLoading: false,
        unassignedList: action.payload,
      };

    case GET_UNASSIGNED_LIST_FAIL:
      return {
        ...state,
        unassignedLoading: false,
      };

    case GET_ASSIGNED_LIST_REQ:
      return {
        ...state,
        assignedListLoading: true,
      };
    case GET_ASSIGNED_LIST_SUCCESS:
      return {
        ...state,
        assignedListLoading: false,
        assignedList: action.payload,
      };

    case GET_ASSIGNED_LIST_FAIL:
      return {
        ...state,
        assignedListLoading: false,
      };
    case GET_CLIENT_COUNTRY_LIST:
      return {
        ...state,
        clientCountryList: action.payload,
      };

    case GET_CLIENT_COUNTRY_BASED_DATA:
      return {
        ...state,
        countryData: action.payload,
      };

    case GET_SEND_REQUEST_STATUS:
      return {
        ...state,
        clientSendRequestStatus: action.payload,
      };

    case GET_FFCLIENT_SEARCH_ADDRESS2_REQ_QUOTEFORMODEL:
      return {
        ...state,
        loading: true,
      };

    case GET_FFCLIENT_SEARCH_ADDRESS2_SUCCESS_QUOTEFORMODEL:
      return {
        ...state,
        loading: false,
        addressResult2QuoteForModel: action.payload,
      };

    case GET_FFCLIENT_SEARCH_ADDRESS2_FAIL_QUOTEFORMODEL:
      return {
        ...state,
        loading: false,
      };

    case GET_FFCLIENT_REQ_QUOTEFORMODEL_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_FFCLIENT_SUCCESS_QUOTEFORMODEL_LIST:
      return {
        ...state,
        loading: false,
        quoteForModelList: action.payload,
      };

    case GET_FFCLIENT_FAIL_QUOTEFORMODEL_LIST:
      return {
        ...state,
        loading: false,
      };

    case GET_COMBINED_PDF:
      return {
        ...state,
        loading: action.payload
      }

    case PRINT_MANUAL_QUOTATION_REQ:
      return {
        ...state,
        commonLoaderForManual: true,
      };
    case PRINT_MANUAL_QUOTATION_SUCCESS:
      return {
        ...state,
        commonLoaderForManual: false,
        // stateData: action.payload.places,
      };
    case PRINT_MANUAL_QUOTATION_FAIL:
      return {
        ...state,
        commonLoaderForManual: false,
      };


    case SAVE_MANUAL_QUOTATION_REQ:
      return {
        ...state,
        commonLoaderForManual: true,
      };
    case SAVE_MANUAL_QUOTATION_SUCCESS:
      return {
        ...state,
        commonLoaderForManual: false,
        // stateData: action.payload.places,
      };
    case SAVE_MANUAL_QUOTATION_FAIL:
      return {
        ...state,
        commonLoaderForManual: false,
      };

      case SHARE_MANUAL_QUOTATION_REQ:
        return {
          ...state,
          commonLoaderForManual: true,
        };
      case SHARE_MANUAL_QUOTATION_SUCCESS:
        return {
          ...state,
          commonLoaderForManual: false,
          // stateData: action.payload.places,
        };
      case SHARE_MANUAL_QUOTATION_FAIL:
        return {
          ...state,
          commonLoaderForManual: false,
        };

    case 'SAVE_REFRENCE_ID':
      return {
        ...state,
        quoteRefrenceId: action.payload
      }
    case 'EMPTY_REFRENCE_ID':
      return {
        ...state,
        quoteRefrenceId: action.payload
      }

    case CREATE_TRUKCING_QUOTATION_REQ:
      return {
        ...state,
        truckingQuotationLoading: true,
      };
    case CREATE_TRUKCING_QUOTATION_SUCCESS:
      return {
        ...state,
        truckingQuotationLoading: false,
        truckingQuotation: action.payload,
      };
    case CREATE_TRUKCING_QUOTATION_FAIL:
      return {
        ...state,
        truckingQuotationLoading: false,
      };

    case GET_UNIT_MEASUREMENT:
      return {
        ...state,
        unitType: action.payload
      }

    case 'REMOVE_SAVE_DATA_FROM_NEW_QUOTE':
      return {
        ...state,
        manualQuoteData: {},
      };

    case 'UPDATE_SUBMIT':
      return {
        ...state,
        checkForSubmission: action.payload
      }
    case 'UPDATE_MODAL':
      return {
        ...state,
        checkFormodal: action.payload,
      };

    case GET_AIRLINE_LIST1:
      return {
        ...state,
        airlineList2: action.payload,
      };
    case GET_AIRLINE_LIST:
      return {
        ...state,
        airlineList1: action.payload,
      };
    case GET_USA_AIRPORTS_LIST_SUCCESS:
      return {
        ...state,
        usaAirportsList: action.payload
      }
    case GET_LOCAL_AIRPORTS_LIST_SUCCESS:
      return {
        ...state,
        localUsaAirports: action.payload
      }
    case GET_USA_AIRPORTS_TIER2_LIST_SUCCESS:
      return {
        ...state,
        usaAirportsListTier2: action.payload
      }
    case GET_PREFERRED_AIRPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_PREFERRED_AIRPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        preferredAirportsList: action.payload
      }
    case GET_PREFERRED_AIRPORTS_FAIL:
      return {
        ...state,
        loading: false
      }
    case GET_PREFERRED_AIRPORTS_INITIAL:
      return {
        ...state,
        preferredAirportsList: []
      }
      case SHARE_MANUAL_QUOTATION_DETAIL_REQ:
        return {
          ...state,
          commonLoaderForManaualQuotationDetail: true,
        };
      case SHARE_MANUAL_QUOTATION_DETAIL_SUCCESS:
        return {
          ...state,
          commonLoaderForManaualQuotationDetail: false,
          // stateData: action.payload.places,
        };
      case SHARE_MANUAL_QUOTATION_DETAIL_FAIL:
        return {
          ...state,
          commonLoaderForManaualQuotationDetail: false,
        };
  
      case PRINT_MANUAL_QUOTATION_DETAIL_REQ:
        return {
          ...state,
          commonLoaderForManaualQuotationDetail: true,
        };
      case PRINT_MANUAL_QUOTATION_DETAIL_SUCCESS:
        return {
          ...state,
          commonLoaderForManaualQuotationDetail: false,
          // stateData: action.payload.places,
        };
      case PRINT_MANUAL_QUOTATION_DETAIL_FAIL:
        return {
          ...state,
          commonLoaderForManaualQuotationDetail: false,
        };

    default:
      return state;
  }
};
export default ffClientReducer;
