import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useStyles from './index.style';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import ContentView from '@crema/core/ContentView';
import UserInfo from 'shared/components/UserInfo';
import Scrollbar from '@crema/core/Scrollbar';
import Navigation from '../../Navigation/VerticleNav/index';
import AppHeader from './AppHeader';
import { Box, useMediaQuery } from '@material-ui/core';
import { toggleNavCollapsed, toggleDrawer } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ffAdminNavConfig } from 'navConfig/ffAdmin';
import { airlineAdminNavConfig } from 'navConfig/airlineAdmin';
import { airlineAgentNavConfig } from 'navConfig/airlineAgent';
import { dataEntryNavConfig } from 'navConfig/dataEntry';
import { ffAgentNavConfig } from 'navConfig/ffAgent';
import { shipperNavConfig } from 'navConfig/shipper';
import { ffClientNavConfig } from 'navConfig/ffClient';
import { superAdminNavConfig } from 'navConfig/superAdmin';
// import AppBreadcrumbs from 'shared/components/AppBreadcrumbs';
import Footer from 'shared/components/Footer';
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";


const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  marginTop: '70px',
  [theme.breakpoints.down('480')]: {
    marginTop: '56px',
  },

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    // duration:theme.transitions.duration.enteringScreen
    duration: 700,

    // duration: 1000
  }),
  // overflowX: "hidden",
  // backgroundColor: "#32383e",

});



const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    // duration: theme.transitions.duration.leavingScreen
    duration: 700,
  }),
  // overflowX: "hidden",
  // backgroundColor: "#32383e",
  marginTop: '70px',
  [theme.breakpoints.down('480')]: {
    marginTop: '56px',
  },

  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});





const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  // flexShrink: 0,
  // position:'absolute',

  whiteSpace: "nowrap",
  boxSizing: "border-box",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
  }
  ),

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

export default function StandardLayout() {
  const classes = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);



  const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));

  // States
  const { user } = useSelector((state) => state.auth);
  const [currentNavConfig, setCurrentNavConfig] = useState([]);
  const navCollapsed = useSelector(({ settings }) => settings.navCollapsed);
  const openDrawer = useSelector(({ settings }) => settings.openDrawer);

  // const styling={
  //   change:{
  //     position:'absolute'
  //   },
  //   change1:{
  //     flexShrink:0
  //   }
  // }

  // Lifecycle Hook
  useEffect(() => {
    user?.role === 'ROLE_FFADMIN' && setCurrentNavConfig(ffAdminNavConfig);
    user?.role === 'ROLE_AIRLINE_ADMIN' && setCurrentNavConfig(airlineAdminNavConfig);
    user?.role === 'ROLE_AIRLINE_AGENT' && setCurrentNavConfig(airlineAgentNavConfig);
    user?.role === 'ROLE_DATAENTRY' && setCurrentNavConfig(dataEntryNavConfig);
    user?.role === 'ROLE_FFAGENT' && setCurrentNavConfig(ffAgentNavConfig);
    user?.role === 'ROLE_CONSIGNEE' && setCurrentNavConfig(shipperNavConfig);
    user?.role === 'ROLE_FFCLIENT' && setCurrentNavConfig(ffClientNavConfig);
    user?.role === 'ROLE_SUPERADMIN' && setCurrentNavConfig(superAdminNavConfig);
  }, [user]);

  // Functions
  const handleToggleDrawer = () => {
    dispatch(toggleNavCollapsed());
  };





  function drawerFuntion() {
    if (navCollapsed == false) {
      dispatch(toggleDrawer(true))
      setOpen(true)
    }
  }

  function closeDrawer() {
    if (navCollapsed == false)
      setOpen(false)
    dispatch(toggleDrawer(false))

  }


  return (
    <div className={classes.root} >
      <CssBaseline />

      <AppHeader />
      <Drawer
        //   onMouseEnter={drawerFuntion}
        onMouseLeave={(navCollapsed == false && openDrawer == true) ? closeDrawer : ''}
        variant="permanent"
        open={(navCollapsed == false && openDrawer == true) || (navCollapsed == true && openDrawer == false)}
        classes={{
          paper: classes.drawerPaper,
        }}
        // style={navCollapsed?styling.change1:styling.change}
        onClose={() => handleToggleDrawer()}
      >
        {/* <UserInfo handleToggleDrawer={handleToggleDrawer} /> */}
        {/* <Divider /> */}
        <Scrollbar scrollToTop={false}
          className={classes.drawerScrollAppSidebar}
        >
          <Navigation />
        </Scrollbar>
      </Drawer>

      <main
        className={clsx(navCollapsed ? classes.content1 : classes.content, {
          [classes.contentShift]: navCollapsed
        })}
      >
        {/* <div className={classes.drawerHeader} /> */}

        <>
          <Box className={classes.mainContent}>
            <Box className={classes.mainContainer}>
              {/* <div
                style={{
                  marginLeft: '18px',
                  marginRight: '35px',
                  display: 'inline-block',
                }}
              >
                <AppBreadcrumbs currentNavConfig={currentNavConfig} pathname={pathname} />
              </div> */}

              <ContentView />
            </Box>
          </Box>
          <Footer />
        </>
      </main>
    </div>
  );
}
