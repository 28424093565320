const initialState = {
  shipper_list: [],
  companyList:[],
 
};

export const retrieveShipperListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'RETRIEVE_SHIPPER_LIST':
      return {
        shipper_list: [...payload],
      };

     case 'RETRIEVE_COMPANY_LIST':
       return{
          companyList:[...payload]
       } 

     
    default:
      return {
        ...state,
      };
  }
};
