export const ffAgentNavConfig = [
  // Quotes
  {
    id: "quotationManagement",
    title: "Quotes",
    messageId: "sidebar.quotationManagement",
    //type: "collapse",
    type: "item",
    icon: ['/assets/images/sideMenu/quotes-active.png', '/assets/images/sideMenu/quotes.png'],
    role: ["ROLE_FFAGENT"],
    url: ["/freight-forwarder/agent/quotation/create",
      '/freight-forwarder/agent/quotation/price',
      '/freight-forwarder/agent/quotation/Airfreight-quote/price',
      '/freight-forwarder/agent/quotation/manual/view',
      '/freight-forwarder/agent/quotation/list/view',
      '/freight-forwarder/agent/quotation/details/',
      '/freight-forwarder/agent/quotation/manual/details',
      '/freight-forwarder/agent/quotation/update/',
      '/freight-forwarder/agent/quotation/inquerier/list/view'
    ],
    // children: [
    //   {
    //     id: "quotationCreate",
    //     title: "New Quote",
    //     messageId: "sidebar.quotationManagement.quotationCreate",
    //     type: "item",
    //     url: "/freight-forwarder/agent/quotation/create",
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: "quotationList",
    //     title: "My Quotes",
    //     messageId: "sidebar.quotationManagement.quotationList",
    //     type: "item",
    //     url: "/freight-forwarder/agent/quotation/list/view",
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'quoteInquerier',
    //     title: 'Quote Inquerier',
    //     messageId: 'sidebar.quotationManagement.quoteInquerier',
    //     type: 'item',
    //     url: '/freight-forwarder/agent/quotation/inquerier/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'manualQuote',
    //     title: 'Manual Quote',
    //     messageId: 'sidebar.quotationManagement.manualQuote',
    //     type: 'item',
    //     url: '/freight-forwarder/agent/quotation/manual/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Incoming Quotes
  {
    id: "incomingQuotesManagement",
    title: "Incoming Quotes",
    messageId: "sidebar.incomingQuotesManagement",
    type: "item",
    icon: ['/assets/images/sideMenu/incoming-quotes-active.png', '/assets/images/sideMenu/incoming-quotes.png'],
    role: ["ROLE_FFAGENT"],
    url: ["/freight-forwarder/agent/quotation/shipper/requests/list/view",
      '/freight-forwarder/agent/quotation/shipper/request/details/view/',
      '/freight-forwarder/agent/shipperRequest/quotation/price',
      '/freight-forwarder/agent/shipperRequest/Airfreight/price',
      '/freight-forwarder/agent/quotation/shippers/incoming/quote/details/manualquotation/',
    ],
    // children: [
    //   {
    //     id: "incomingQuote",
    //     title: "Client Quotes - Incoming",
    //     messageId: "sidebar.incomingQuotesManagement.incomingQuote",
    //     type: "item",
    //     url: "/freight-forwarder/agent/quotation/shipper/requests/list/view",
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Tracking
  {
    id: "trackingManagement",
    title: "Tracking",
    messageId: "sidebar.trackingManagement",
    type: "item",
    icon: ['/assets/images/sideMenu/tracking-active.png', '/assets/images/sideMenu/tracking.png'],
    role: ["ROLE_FFAGENT"],
    url: ['/freight-forwarder/agent/tracking'],
    // children: [
    //   {
    //     id: 'tracking',
    //     title: 'Tracking',
    //     messageId: 'sidebar.trackingManagement.tracking',
    //     type: 'item',
    //     url: '/freight-forwarder/agent/tracking',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Margins
  {
    id: 'marginsManagement',
    title: 'Margins',
    messageId: 'sidebar.marginsManagement',
    type: 'item',
    icon: ['/assets/images/sideMenu/margin-active.png', '/assets/images/sideMenu/margin.png'],
    role: ['ROLE_FFAGENT'],
    url: ['/freight-forwarder/agent/economics/list/view',
      // '/freight-forwarder/agent/trucking-ranges/create',
      // '/freight-forwarder/agent/airline-ranges/create',
      '/freight-forwarder/agent/preset/margin/setup',
    ],
    // children: [
    //   {
    //     id: 'marginsViewByAgent',
    //     title: 'Margins View By Agent',
    //     messageId: 'sidebar.marginsManagement.marginsViewByAgent',
    //     type: 'item',
    //     url: '/freight-forwarder/agent/economics/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'createMarginsTrucking',
    //     title: 'Trucking Margins Setup',
    //     messageId: 'sidebar.marginsRanges.createTruckingMarginsSetup',
    //     type: 'item',
    //     url: '/freight-forwarder/agent/trucking-ranges/create',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'createAirfreightMarginsSetup',
    //     title: 'Airfreight Margins Setup',
    //     messageId: 'sidebar.marginsRanges.createAirfreightMarginsSetup',
    //     type: 'item',
    //     url: '/freight-forwarder/agent/airline-ranges/create',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Client
  {
    id: "shipperManagement",
    title: "Client",
    messageId: "sidebar.shipperManagement",
    type: "item",
    icon: ['/assets/images/sideMenu/client-active.png', '/assets/images/sideMenu/client.png'],
    role: ["ROLE_FFAGENT"],
    // url: [
    //   '/agent/key-management/freightforwarder/agent/clientlist',
    //   "/freight-forwarder/agent/shipper/add",
    // ],
    url: ['/freight-forwarder/agent/shippers/requests/list/view',
      '/agent/key-management/freightforwarder/agent/clientlist',
      "/freight-forwarder/agent/shipper/add",
      '/agent/key-management/freightforwarder/agent/clientslist/action',
      '/freight-forwarders/agent/renewkey/payment/success',
      '/freight-forwarders/agent/renewkey/payment/success',
      '/freight-forwarder/agent/client/checkout_payment'
    ],
    // children: [
    //   {
    //     id: "addshipper",
    //     title: "Send Request",
    //     messageId: "sidebar.shipperManagement.addshipper",
    //     type: "item",
    //     url: "/freight-forwarder/agent/shipper/add",
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: "shipperRequests",
    //     title: "Received Client List",
    //     messageId: "sidebar.shipperManagement.shipperRequest",
    //     type: "item",
    //     url: "/freight-forwarder/agent/shippers/requests/list/view",
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Warehouse
  {
    id: "wareHouseManagement",
    title: "Warehouse",
    messageId: "sidebar.wareHouseManagement",
    type: "item",
    icon: ['/assets/images/sideMenu/warehouse-active.png', '/assets/images/sideMenu/warehouse.png'],
    role: ["ROLE_FFAGENT"],
    url: ["/freight-forwarder/agent/warehosue/create",
      '/freight-forwarder/agent/warehosue/list/view',
      '/freight-forwarder/agent/warehouse/edit/',
    ],
    // children: [
    //   {
    //     id: "addWareHouse",
    //     title: "Create",
    //     messageId: "sidebar.wareHouseManagement.addWareHouse",
    //     type: "item",
    //     url: "/freight-forwarder/agent/warehosue/create",
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: "wareHouseList",
    //     title: "List",
    //     messageId: "sidebar.wareHouseManagement.wareHouseList",
    //     type: "item",
    //     url: "/freight-forwarder/agent/warehosue/list/view",
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Address Book
  {
    id: "addressManagement",
    title: "Address Book",
    messageId: "sidebar.addressManagement",
    type: "item",
    icon: ['/assets/images/sideMenu/address-book-active.png', '/assets/images/sideMenu/address-book.png'],
    role: ["ROLE_FFAGENT"],
    url: ["/freight-forwarder/agent/directory/address/new",
      '/freight-forwarder/agent/directory/address/list/view',
      '/freight-forwarder/agent/directory/address/edit/'],
    // children: [
    //   {
    //     id: "addAddress",
    //     title: "Add Address",
    //     messageId: "sidebar.addressManagement.addAddress",
    //     type: "item",
    //     url: "/freight-forwarder/agent/directory/address/new",
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: "customerList",
    //     title: "Address List",
    //     messageId: "sidebar.addressManagement.addressList",
    //     type: "item",
    //     url: "/freight-forwarder/agent/directory/address/list/view",
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Account Management
  {
    id: "adminAccountManagement",
    title: "Account Management",
    messageId: "sidebar.adminAccountManagement",
    type: "item",
    icon: ['/assets/images/sideMenu/account-management-active.png', '/assets/images/sideMenu/account-management.png'],
    role: ["ROLE_FFAGENT"],
    url: ["/freight-forwarder/agent/subscription/details/view",
      '/freight-forwarder/agent/checkout_payment',
      '/agent/key-management/freightforwarder/agent/details',
      '/freight-forwarder/agent/transaction-list/freightforwarder/view',
      '/freight-forwarder/agent/add/reassign/',
      '/freight-forwarder/agent/key-management/unassign-key/add-shipper/',
      '/freight-forwarder/agent/transaction-list/details/view',
      '/freight-forwarders/agent/subscription/payment/success',
      '/agent/key-management/freightforwarder/agent/clientdetails/action',
      '/freight-forwarder/agent/key-management/payment-success',
      '/freight-forwarder/agent/key-management/checkout_payment'
    ],
    // children: [
    //   {
    //     id: "subscriptionDetails",
    //     title: "Subscription Details",
    //     messageId: "sidebar.adminAccountManagement.subscriptionDetails",
    //     type: "item",
    //     url: "/freight-forwarder/agent/subscription/details/view",
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'keyManagement',
    //     title: 'Key Management',
    //     messageId: 'sidebar.adminAccountManagement.keyManagement',
    //     type: 'item',
    //     url: '/agent/key-management/freightforwarder/agent/details',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'transaction',
    //     title: 'Transaction Details',
    //     messageId: 'sidebar.adminAccountManagement.transaction',
    //     type: 'item',
    //     url: '/freight-forwarder/agent/transaction-list/freightforwarder/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Statistics
  {
    id: "statistics",
    title: "Statistics",
    messageId: "sidebar.statistics",
    type: "item",
    icon: ['/assets/images/sideMenu/statistics-active.png', '/assets/images/sideMenu/statistics.png'],
    role: ["ROLE_FFAGENT"],
    url: ["/freight-forwarder/agent/statistics"],
    // children: [
    //   {
    //     id: "statistics",
    //     title: "Statistics",
    //     messageId: "sidebar.statistics.statistics",
    //     type: "item",
    //     url: "/freight-forwarder/agent/statistics",
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Settings
  {
    id: "profileManagement",
    title: "Settings",
    messageId: "sidebar.profile",
    type: "item",
    icon: ['/assets/images/sideMenu/settings-active.png', '/assets/images/sideMenu/settings.png'],
    role: ["ROLE_FFAGENT"],
    url: ["/freight-forwarder/agent/profile/update",
      '/freight-forwarder/agent/profile/quote/email/msg/setting',
      '/freight-forwarder/agent/profile/measurements/system'],
    // children: [
    //   {
    //     id: "profile",
    //     title: "View Profile",
    //     messageId: "sidebar.adminProfile.update",
    //     type: "item",
    //     url: "/freight-forwarder/agent/profile/update",
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'quoteEmailMsgSetting',
    //     title: 'Quote Email Message',
    //     messageId: 'sidebar.adminProfile.quoteEmailMsgSetting',
    //     type: 'item',
    //     url: '/freight-forwarder/agent/profile/quote/email/msg/setting',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Documents
  // {
  //   id: "documentsManagement",
  //   title: "Documents",
  //   messageId: "sidebar.documentsManagement",
  //   type: "item",
  //   icon: '/assets/images/sideMenu/documents.png',
  //   role: ["ROLE_FFAGENT"],
  //   children: [],
  // },

  // Notifications
  {
    id: "notification",
    title: "Notifications",
    messageId: "sidebar.notification",
    type: "item",
    icon: ['/assets/images/sideMenu/notification-active.png', '/assets/images/sideMenu/notification.png'],
    role: ["ROLE_FFAGENT"],
    url: ["/freight-forwarder/agent/notification",],
    // children: [
    //   {
    //     id: "notification",
    //     title: "Notifications",
    //     messageId: "sidebar.notification",
    //     type: "item",
    //     url: "/freight-forwarder/agent/notification",
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Feedback
  {
    id: 'feedBackManagement',
    title: 'Feedback',
    messageId: 'sidebar.feedBackManagement',
    type: 'item',
    icon: ['/assets/images/sideMenu/feedback-active.png', '/assets/images/sideMenu/feedback.png'],
    role: ['ROLE_FFAGENT'],
    url: ['/freight-forwarder/agent/addfeedback',
      '/freight-forwarder/agent/feedback/view',
      '/freight-forwarder/agent/feedback/feedbacklist/view/'],
    // children: [
    //   {
    //     id: 'feedback',
    //     title: 'Feedback',
    //     messageId: 'sidebar.feedBackManagement.feedback',
    //     type: 'item',
    //     url: '/freight-forwarder/agent/feedback/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Tutorial
  {
    id: 'ytTutorial',
    title: 'Tutorial',
    messageId: 'sidebar.ytTutorial',
    type: 'item',
    icon: ['/assets/images/sideMenu/youtube-active.png', '/assets/images/sideMenu/youtube.png'],
    url: ['/freight-forwarder/agent/tutorial/view'],
    role: ['ROLE_FFAGENT'],
  },
];
