import { makeStyles } from '@material-ui/core/styles';
import { Fonts, ThemeMode } from '../../../../shared/constants/AppEnums';

const useStyles = makeStyles((theme) => {
  return {
    navItem: {
      height: 40,
      marginTop: 2,
      marginBottom: 2,
      cursor: 'pointer',
      textDecoration: 'none !important',
      width: 'calc(100% - -7px)',
      borderRadius: '0 15px 15px 0',
      paddingLeft: theme.direction === 'ltr' ? (props) => 24 + 3 * props.level : 12,
      paddingRight: theme.direction === 'rtl' ? (props) => 24 + 40 * props.level : 12,
      '&.nav-item-header': {
        textTransform: 'uppercase',
      },
      '&.active': {
        //backgroundColor: theme.palette.primary.main,
        pointerEvents: 'none',
        transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
        '& .nav-item-text': {
          //color: theme.palette.common.white + '!important',
          color: '#0C6AC9',
          fontSize: 16,
          fontWeight: Fonts.REGULAR,
        },
        '& .nav-item-icon': {
          // color: theme.palette.common.white + '!important',
          color: 'white',
          fontSize: 20,
          height: 20,
          width: 20,
        },
      },

      '&:hover, &:focus': {
        '& .nav-item-text2': {
          // color: (props) => props.themeMode === ThemeMode.LIGHT ? theme.palette.primary.main : '#fff',
          color: '#0C6AC9'
        },

        '& .nav-item-icon': {
          // color: (props) =>
          //   props.themeMode === ThemeMode.LIGHT ? theme.palette.primary.main : '#fff',
          color: 'white',
          height: 20,
          width: 20,
        },

        '& .nav-item-icon-arrow': {
          // color: (props) =>
          //   props.themeMode === ThemeMode.LIGHT ? theme.palette.primary.main : '#fff',
          color: 'white'
        },
      },
      '& .nav-item-icon': {
        // color: theme.palette.sidebar.textColor,
        color: 'white',
        height: 20,
        width: 20,
      },
      '& .nav-item-text': {
        // color: theme.palette.sidebar.textColor,
        // fontWeight: Fonts.MEDIUM,
        color: '#858A8C',
        fontSize: 16,
        fontWeight: Fonts.REGULAR,
      },
      '& .nav-item-text1': {
        color: '#0C6AC9',
        fontSize: 14,
        fontWeight: Fonts.REGULAR,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& .nav-item-text2': {
        color: '#858A8C',
        fontSize: 14,
        fontWeight: Fonts.REGULAR,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },

      '& .notification-bg': {
        height: 22,
        width: 22,
        borderRadius: 12,
        backgroundColor: '#F04F47',
        // paddingLeft: 5,
        // paddingRight: 5,
        marginRight: 0,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      '& .notification-count': {
        color: '#FFFFFF',
        fontSize: 10,
        // marginTop: 4,
      },
    },
    navItemChildren: {
      height: 40,
      marginTop: 2,
      marginBottom: 2,
      cursor: 'pointer',
      textDecoration: 'none !important',
      width: 'calc(100% -13px)',
      borderRadius: '0 15px 15px 0',
      paddingLeft: theme.direction === 'ltr' ? (props) => 24 + 3 * props.level : 12,
      paddingRight: theme.direction === 'rtl' ? (props) => 24 + 40 * props.level : 12,
      '&.nav-item-header': {
        textTransform: 'uppercase',
      },
      '&.active': {
        //backgroundColor: theme.palette.primary.main,
        pointerEvents: 'none',
        transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
        '& .nav-item-text': {
          // color: theme.palette.common.white + '!important',
          // fontWeight: Fonts.MEDIUM,
          color: '#0C6AC9',
          fontSize: 16,
          fontWeight: Fonts.REGULAR,
        },
        '& .nav-item-icon': {
          // color: theme.palette.common.white + '!important',
          color: 'white',
          height: 20,
          width: 20,
        },
      },

      '&:hover, &:focus': {
        '& .nav-item-text': {
          color: (props) => props.themeMode === ThemeMode.LIGHT ? theme.palette.primary.main : '#fff',
        },

        '& .nav-item-icon': {
          // color: (props) =>
          //   props.themeMode === ThemeMode.LIGHT ? theme.palette.primary.main : '#fff',
          color: 'white',
          height: 20,
          width: 20,
        },

        '& .nav-item-icon-arrow': {
          // color: (props) =>
          //   props.themeMode === ThemeMode.LIGHT ? theme.palette.primary.main : '#fff',
          color: 'white',
        },
      },
      '& .nav-item-icon': {
        // color: theme.palette.sidebar.textColor,
        color: 'white',
        height: 20,
        width: 20,
      },
      '& .nav-item-text': {
        // color: theme.palette.sidebar.textColor,
        // fontWeight: Fonts.MEDIUM,
        color: '#858A8C',
        fontSize: 16,
        fontWeight: Fonts.REGULAR,
      },
    },
    listIcon: {
      fontSize: 10,
      //backgroundColor: theme.palette.sidebar.textColor,
      height: 12,
      width: 12,
      // borderRadius: 20,
      [theme.breakpoints.up('xl')]: {
        // fontSize: 20,
      },
    },
    listItemText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      // whiteSpace: 'nowrap',
      fontWeight: Fonts.REGULAR,
    },
  };
});
export default useStyles;
