export const truckingNavConfig = [
  {
    id: 'trackingManagement',
    title: 'Tracking Management',
    messageId: 'sidebar.trackingManagement',
    type: 'collapse',
    icon: 'waterfall_chart',
    role: ['ROLE_TRUCKING_ADMIN'],
    children: [],
  },
  {
    id: 'documentsManagement',
    title: 'Documents Management',
    messageId: 'sidebar.documentsManagement',
    type: 'collapse',
    icon: 'note_alt',
    role: ['ROLE_TRUCKING_ADMIN'],
    children: [],
  },
  {
    id: 'statistics',
    title: 'statistics',
    messageId: 'sidebar.statistics',
    type: 'collapse',
    icon: 'note_alt',
    role: ['ROLE_TRUCKING_ADMIN'],
    children: [
      {
        id: 'statistics',
        title: 'statistics',
        messageId: 'sidebar.statistics.statistics',
        type: 'item',
        url: ['/trucking/statistics'],
      },
    ],
  },
];
