import React from 'react';
import { Redirect } from 'react-router-dom';
import { createRoutes } from '../@crema/utility/Utils';
import { mainRouteConfig } from './mainRoutes';
import { errorRouteConfig } from './errorRoutes';
import { authRouteConfig } from './authRoutes';
import { initialUrl } from '../shared/constants/AppConst';

const routeConfig = [...mainRouteConfig, ...errorRouteConfig, ...authRouteConfig];

const routes = [
  ...createRoutes(routeConfig),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to="/error-pages/error-404" />,
  },
];

export default routes;
