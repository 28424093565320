import { SET_INITIAL_PATH, TOGGLE_NAV_COLLAPSED,TOGGLE_DRAWER } from '../../shared/constants/ActionTypes';

export const toggleNavCollapsed = () => {
  return (dispatch) => dispatch({ type: TOGGLE_NAV_COLLAPSED });
};

export const toggleDrawer =(data)=>{
  return (dispatch)=>dispatch({type:TOGGLE_DRAWER,payload:data})
}

export const setInitialPath = (initialPath) => {
  return (dispatch) => dispatch({ type: SET_INITIAL_PATH, payload: initialPath });
};

export const zoomIn =(data)=>{
  return (dispatch)=>dispatch({type:'SET_ZOOM_IN',payload:data})
}

export const zoomOut =(data)=>{
  return (dispatch)=>dispatch({type:'SET_ZOOM_OUT',payload:data})
}

export const initialScreenWidth =(data)=>{
  return (dispatch)=>dispatch({type:'INITIAL_SCREEN_WIDTH',payload:data})
}