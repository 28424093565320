import {
  GET_ADDRESSES_REQ,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESSES_FAIL,
  GET_ADDRESS_REQ,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAIL,
  UPDATE_ADDRESS_REQ,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAIL,
  DELETE_ADDRESS_REQ,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAIL,
  CREATE_ADDRESS_REQ,
  CREATE_ADDRESS_SUCCESS,
  CREATE_ADDRESS_FAIL,
  GET_ADDRESS2_REQ,
  GET_ADDRESS2_SUCCESS,
  GET_ADDRESS2_FAIL,
  SET_ADDRESS_DATA,
  SET_ADDRESS_DATA1,
  SET_ADDRESS_DATA2,
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  addresses: [],
  loading: false,
  addressData: {},
  addressData2: {},
};

const addressReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADDRESSES_REQ:
    case GET_ADDRESS_REQ:
    case GET_ADDRESS2_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_ADDRESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        addresses: action.payload,
      };

    case GET_ADDRESS2_SUCCESS:
      return {
        ...state,
        addressData2: action.payload,
      };

    case GET_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        addressData: action.payload,
      };
    case GET_ADDRESSES_FAIL:
    case GET_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_ADDRESS_REQ:
    case UPDATE_ADDRESS_REQ:
    case DELETE_ADDRESS_REQ:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ADDRESS_SUCCESS:
    case UPDATE_ADDRESS_SUCCESS:
    case DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_ADDRESS_FAIL:
    case UPDATE_ADDRESS_FAIL:
    case DELETE_ADDRESS_FAIL:
    case GET_ADDRESS2_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SET_ADDRESS_DATA:
      return {
        ...state,
        addressData: {},
        addressData2: {},
      };

    case SET_ADDRESS_DATA1:
      return {
        ...state,
        addressData: {},
      };

    case SET_ADDRESS_DATA2:
      return {
        ...state,
        addressData2: {},
      };

    default:
      return state;
  }
};
export default addressReducer;
