const init_state = {
      // feedBackList:{},
      feedBackListData: [],
      feedBackDetailsData:{},
    };
    
    export const FeedBackSuperAdminReducer = (state = init_state, { type, payload }) => {
      switch (type) {
        case `RETRIEVE_SUBSCRIPTION_DATA`:
          return {
            ...state,
            pieChartData: {...payload},
          };
        case `RETRIEVE_FEEDBACK_LIST`:
          return {
            ...state,
            feedBackListData: [...payload],
          };
        case "RETRIEVE_FEEDBACK_DETAILS":
          return {
            ...state,
            feedBackDetailsData:{...payload}
          }
        default:
          return {
            ...state,
          };
      }
    };