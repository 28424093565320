const initialState = {
  quotation_list: [],
  quotaitionLoading: false,
  quotaionDetail: [],
  quotationdetatilLoading: false,
  singleQuotationDetail:{},
  quoteModalQuotation:{},
  quoteListQuotationDetail:{},
  quoteListQuotationDetailReq:false
};

export const retrieveFreightForwarderQuotationListReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {

    case 'RETRIEVE_COMPANY_REQ':
      return {
        quotaitionLoading: true,
        ...state
      }
    case 'FREIGHT_FORWARDER_QUOTATION_LIST':
      return {
        ...state,
        quotation_list: payload.content,
        quotaitionLoading: false
      };
    case 'RETRIEVE_COMPANY_FAIL':
      return {
        ...state,
        quotaitionLoading: false,
      }
    case 'GET_COMPANY_REQ':
      return {
        quotationdetatilLoading: true,
        ...state
      }
    case 'GET_COMPANY_SUCCESS':
      return {
        ...state,
        quotaionDetail: payload.data,
        quotationdetatilLoading: false
      };
    case 'GET_COMPANY_FAIL':
      return {
        ...state,
        quotationdetatilLoading: false,
      }


      case 'GET_COMPANY_DETAIL_REQ':
        return {
          quotationdetatilLoading: true,
          ...state
        }
      case 'GET_COMPANY_DETAIL_SUCCESS':
        return {
          ...state,
          singleQuotationDetail: payload.quotation,
          quoteModalQuotation:payload.replayQuotation,
          quotationdetatilLoading: false
        };
      case 'GET_COMPANY_DETAIL_FAIL':
        return {
          ...state,
          quotationdetatilLoading: false,
        }

        case 'GET_QUOTATION_DETAIL_REQ':
          return {
            quoteListQuotationDetailReq: true,
            ...state
          }
        case 'GET_QUOTATION_DETAIL_SUCCESS':
          return {
            ...state,
            quoteListQuotationDetail: payload,
            quoteListQuotationDetailReq: false
          };
        case 'GET_QUOTATION_DETAIL_FAIL':
          return {
            ...state,
            quoteListQuotationDetailReq: false,
          }

    default:
      return {
        ...state,
      };
  }
};
