export const GET_FFADMIN_ADDRESSES_REQ = 'GET_FFADMIN_ADDRESSES_REQ';
export const GET_FFADMIN_ADDRESSES_SUCCESS = 'GET_FFADMIN_ADDRESSES_SUCCESS';
export const GET_FFADMIN_ADDRESSES_FAIL = 'GET_FFADMIN_ADDRESSES_FAIL';

export const UPDATE_FFADMIN_ADDRESS_REQ = 'UPDATE_FFADMIN_ADDRESS_REQ';
export const UPDATE_FFADMIN_ADDRESS_SUCCESS = 'UPDATE_FFADMIN_ADDRESS_SUCCESS';
export const UPDATE_FFADMIN_ADDRESS_FAIL = 'UPDATE_FFADMIN_ADDRESS_FAIL';

export const DELETE_FFADMIN_ADDRESS_REQ = 'DELETE_FFADMIN_ADDRESS_REQ';
export const DELETE_FFADMIN_ADDRESS_SUCCESS = 'DELETE_FFADMIN_ADDRESS_SUCCESS';
export const DELETE_FFADMIN_ADDRESS_FAIL = 'DELETE_FFADMIN_ADDRESS_FAIL';

export const CREATE_FFADMIN_ADDRESS_REQ = 'CREATE_FFADMIN_ADDRESS_REQ';
export const CREATE_FFADMIN_ADDRESS_SUCCESS = 'CREATE_FFADMIN_ADDRESS_SUCCESS';
export const CREATE_FFADMIN_ADDRESS_FAIL = 'CREATE_FFADMIN_ADDRESS_FAIL';

export const GET_FFADMIN_ADDRESS_REQ = 'GET_FFADMIN_ADDRESS_REQ';
export const GET_FFADMIN_ADDRESS_SUCCESS = 'GET_FFADMIN_ADDRESS_SUCCESS';
export const GET_FFADMIN_ADDRESS_FAIL = 'GET_FFADMIN_ADDRESS_FAIL';

export const GET_FFADMIN_ADDRESS2_REQ = 'GET_FFADMIN_ADDRESS2_REQ';
export const GET_FFADMIN_ADDRESS2_SUCCESS = 'GET_FFADMIN_ADDRESS2_SUCCESS';
export const GET_FFADMIN_ADDRESS2_FAIL = 'GET_FFADMIN_ADDRESS2_FAIL';

export const SET_FFADMIN_ADDRESS_DATA = 'SET_FFADMIN_ADDRESS_DATA';
export const SET_FFADMIN_ADDRESS_DATA1 = 'SET_FFADMIN_ADDRESS_DATA1';
export const SET_FFADMIN_ADDRESS_DATA2 = 'SET_FFADMIN_ADDRESS_DATA2';
