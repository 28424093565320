import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoutes } from 'react-router-config';
import qs from 'qs';
import AppContext from './AppContext';
import { useAuthToken } from './AppHooks';
import { Loader } from '../index';
import PropTypes from 'prop-types';
import { checkPermission } from './Utils';
import { setInitialPath } from '../../redux/actions';
import { initialUrl } from 'shared/constants/AppConst';

const AuthRoutes = ({ children }) => {
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();

  const history = useHistory();
  const { routes, changeNavStyle, updateThemeStyle, updateThemeMode, setRTL } = useContext(
    AppContext,
  );

  const [loading, user] = useAuthToken();
  const initialPath = useSelector(({ settings }) => settings.initialPath);
  const currentRoute = matchRoutes(routes, pathname)[0].route;
  let isPermitted = checkPermission(currentRoute.auth, user ? user.role : null);

  const { profile } = useSelector((state) => state.auth);

  useEffect(() => {
    function setInitPath() {
      if (
        initialPath === '/' &&
        [
          '/sign-in',
          '/sign-up',
          '/confirm-signup',
          '/password/reset',
          '/error-pages/error-404',
          '/forgot-password',
          '/airline/sign-up',
          '/trucking/sign-up',
          '/shipper/sign-up',
          '/data-entry/sign-up',
          '/freight-forwarder/sign-up',
          '/freightforwarder/key/validate',
          '/shipper/key/validate',
          '/airline/key/validate',
        ].indexOf(pathname) === -1
      ) {
        if (isPermitted) {
          dispatch(setInitialPath(pathname));
        } else {
          dispatch(setInitialPath(undefined));
        }
      }
    }

    setInitPath();
  }, [dispatch, isPermitted, initialPath, pathname]);

  useEffect(() => {
    function handleQueryParams() {
      const query = qs.parse(search.split('?')[1]);
      if (query.layout) {
        changeNavStyle(query.layout);
      }
      if (query.mode) {
        updateThemeMode(query.mode);
      }
      if (query.rtl) {
        setRTL(true);
      }
      if (query.style) {
        updateThemeStyle(query.style);
      }
    }

    if (search) {
      handleQueryParams();
    }
  }, [changeNavStyle, updateThemeStyle, updateThemeMode, setRTL, search]);

  useEffect(() => {
    if (!loading) {
      if (!user && !isPermitted) {
        history.push('/sign-in'); // allowed route
      } else if (user && !isPermitted) {
        history.push('/error-pages/error-404'); // Not found
      }

      // else if (user && isPermitted && profile?.subscription === "NO") {
      //   if (user.role === "ROLE_FFADMIN") {
      //     if (
      //       pathname !== "/" ||
      //       pathname !== "/sign-in" ||
      //       pathname !== "signup"
      //     ) {
      //       history.push("/freight-forwarder/admin/subscription/payment");
      //     }
      //   }
      // }
      else if (user && isPermitted) {
        if (pathname === '/' || pathname === '/sign-in' || pathname === '/sign-up') {
          dispatch(setInitialPath(undefined));
          history.push(
            user.role === 'ROLE_CONSIGNEE'
              ? '/shipper/quotation/create'
              : user.role === 'ROLE_FFADMIN'
                ? '/freight-forwarder/admin/quotation/create'
                // ? '/freight-forwarder/admin/profile/update'
                : user.role === 'ROLE_SUPERADMIN'
                  // ? '/super-admin/freight-forwarder/admins/list/view'
                  ? '/super-admin/freight-forwarder/companies/approved-companies'
                  : user.role === 'ROLE_AIRLINE_ADMIN'
                    ? '/airline/admin/profile/update'
                    : user.role === 'ROLE_AIRLINE_AGENT'
                      ? '/airline/agent/priority-rate/create'
                      : user.role === 'ROLE_FFAGENT'
                        ? '/freight-forwarder/agent/quotation/create'
                        : user.role === 'ROLE_DATAENTRY'
                          ? '/data-entry/airfare/create'
                          : user.role === 'ROLE_TRUCKING_ADMIN'
                            ? '/trucking/statistics'
                            : user.role === 'ROLE_FFCLIENT'
                              ? '/freight-forwarder/client/quotation/create'
                              : initialUrl,
          );
        } else if (
          initialPath &&
          initialUrl !== initialPath &&
          (initialPath !== '/' || initialPath !== '/sign-in' || initialPath !== '/sign-up')
        ) {
          history.push(initialPath);
        }
      }
    }
  }, [user, loading, initialPath, isPermitted, pathname, history, profile]);

  return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
