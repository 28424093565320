const initialState = {
  requesting: true,
  data: {
    loading: true,
  },
  view_details: {},
  // airline_admin_history_menu: [],
  surcharge_list_by_airline_id_in_air_admin: [],
  weight_range_details_by_airline_id_in_air_admin: [],
  iataCodeData: [],
  generalSurcharges:[],
};

export const airlineAdminGeneralRateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'RETRIEVE_GENERAL_RATE':
      return {
        ...state,
        requesting: false,
        data: {
          loading: false,
          ...payload,
        },
      };

    // case 'RETRIEVE_AIRLINE_ADMIN_HISTORY_MENU':
    //   return {
    //     ...state,
    //     airline_admin_history_menu: {
    //       ...payload,
    //     },
    //     requesting: false,
    //   };

    case 'RETRIEVE_IATA_CODE_DATA':
      return {
        ...state,
        requesting: false,
        iataCodeData: [...payload],
      };

    case 'RETRIEVE_FILTERED_GENERAL_RATE':
      return {
        ...state,
        requesting: false,
        data: {
          loading: false,
          ...payload,
        },
      };

    case 'VIEW_AIRFARE_DETAILS':
      return {
        ...state,
        requesting: false,
        view_details: payload,
      };

    case 'RETRIEVE_SURCHARGE_LIST_BY_AIRLINE_ID_IN_AIR_ADMIN':
      return {
        ...state,
        surcharge_list_by_airline_id_in_air_admin: [...payload],
      };
      case 'RETRIEVE_GENERAL_SURCHARGES':
        return {
          ...state,
          generalSurcharges: [...payload],
        };
  
    case 'RETRIEVE_WEIGHT_RANGE_DETAILS_BY_AIRLINE_ID_IN_AIR_ADMIN':
      return {
        ...state,
        requesting: false,
        weight_range_details_by_airline_id_in_air_admin: [...payload],
      };

    default:
      return state;
  }
};
