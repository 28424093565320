import { SET_INITIAL_PATH, TOGGLE_NAV_COLLAPSED,TOGGLE_DRAWER } from '../../shared/constants/ActionTypes';

const initialSettings = {
  navCollapsed: false,
  initialPath: '/',
  openDrawer:false,
  zoomFactor:1,
  initialWidth:1
};

const settingsReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        // navCollapsed: false,
      };

    case TOGGLE_NAV_COLLAPSED:
      return {
        ...state,
        navCollapsed: !state.navCollapsed,
      };

    case SET_INITIAL_PATH:
      return {
        ...state,
        initialPath: action.payload,
      };

    case "INITIAL_SCREEN_WIDTH":
      return{
        ...state,
        zoomFactor:action.payload,
        initialWidth:action.payload
      }

    case TOGGLE_DRAWER:
      return{
        ...state,
        openDrawer:action.payload
      }

    case 'SET_ZOOM_IN':
      return{
        ...state,
        zoomFactor:action.payload
      }

      case 'SET_ZOOM_OUT':
        return{
          ...state,
          zoomFactor:action.payload
        }
    default:
      return state;
  }
};

export default settingsReducer;
