export const superAdminNavConfig = [
  // Freight Forwarder
  {
    id: 'freightForwarder',
    title: 'Freight Forwarder',
    messageId: 'sidebar.freightForwarder',
    type: 'item',
    // icon: '/assets/images/sideMenu/freight-forwarder.png',
    icon: ['/assets/images/sideMenu/freight-forwarder-active.png', '/assets/images/sideMenu/freight-forwarder.png'],
    role: ['ROLE_SUPERADMIN'],
    url: [
      '/super-admin/freight-forwarder/companies/approved-companies',
      '/super-admin/freight-forwarder/companies/unapproved-companies',
      '/super-admin/freight-forwarder/companies/add-new-company',
      '/super-admin/freight-forwarder/admin-enrolled/',
      '/super-admin/freight-forwarder/agents-enrolled/',
      '/super-admin/freight-forwarder/company-details/'
    ]
    // children: [
    //   {
    //     id: 'freightForwarderCompanies',
    //     title: 'Company List',
    //     messageId: 'sidebar.pages.company.list',
    //     type: 'item',
    //     url: ['/super-admin/freight-forwarder/companies/list/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    //   {
    //     id: 'freightForwarderQuotations',
    //     title: 'Quote List',
    //     messageId: 'sidebar.superAdminFreightForwarder.quotation.list',
    //     type: 'item',
    //     url: ['/super-admin/freight-forwarder/quotations/list/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png'],
    //   },
    // ],
  },

  // Clients - Non Freight Forwarders
  {
    id: 'superAdminShipper',
    title: 'Clients - Non Freight Forwarders',
    messageId: 'sidebar.superAdminShipper',
    type: 'item',
    icon: ['/assets/images/sideMenu/freight-forwarder-active.png', '/assets/images/sideMenu/freight-forwarder.png'],
    role: ['ROLE_SUPERADMIN'],
    url: ['/super-admin/shippers/list/view']
    // children: [
    //   {
    //     id: 'airlineAdmin',
    //     title: 'List',
    //     messageId: 'sidebar.pages.list',
    //     type: 'item',
    //     url: ['/super-admin/shippers/list/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png'],
    //   },
    // ],
  },

  // Quote List
  {
    id: 'quoteList',
    title: 'Quotes Analysis',
    messageId: 'sidebar.quoteList',
    type: 'item',
    icon: ['/assets/images/sideMenu/incoming-quotes-active.png', '/assets/images/sideMenu/incoming-quotes.png'],
    role: ['ROLE_SUPERADMIN'],
    url: ['/super-admin/freight-forwarder/quotations/list/view',
      '/super-admin/freight-forwarde/quotations/list/view',
      '/super-admin/freight-forwarder/quotation/details',
      '/super-admin/freight-forwarder/quotation/manual/details'
    ]
    // children: [
    //   {
    //     id: 'airlineAdmin',
    //     title: 'List',
    //     messageId: 'sidebar.pages.list',
    //     type: 'item',
    //     url: ['/super-admin/shippers/list/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png'],
    //   },
    // ],
  },

  // Tracking
  {
    id: 'trackingManagement',
    title: 'Tracking',
    messageId: 'sidebar.trackingManagement',
    type: 'item',
    icon: ['/assets/images/sideMenu/tracking-active.png', '/assets/images/sideMenu/tracking.png'],
    role: ['ROLE_SUPERADMIN'],
    url: ['/super-admin/tracking'],

    // children: [
    //   {
    //     id: 'tracking',
    //     title: 'Tracking',
    //     messageId: 'sidebar.trackingManagement.tracking',
    //     type: 'item',
    //     url: ['/super-admin/tracking'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    // ],
  },

  // Key Generator
  {
    id: 'superAdminKeyGenerator',
    title: 'Key Generator',
    messageId: 'sidebar.superAdminKeyGenerator',
    type: 'item',
    icon: ['/assets/images/sideMenu/key-generator-active.png', '/assets/images/sideMenu/key-generator.png'],
    role: ['ROLE_SUPERADMIN'],
    url: [
      '/super-admin/key-generator/airline-key/list/view',
      '/super-admin/key-generator/freightforwarder/view',
      `/super-admin/key-generator/freightforwarder/details`,
      '/super-admin/key-generator/reassign/',
      '/super-admin/key-generator/client/add/reassign/',
    ],
    // children: [
    //   {
    //     id: 'airlineKeys',
    //     title: 'Airline Keys',
    //     messageId: 'sidebar.superAdminKeyGenerator.airlineKeys',
    //     type: 'item',
    //     url: ['/super-admin/key-generator/airline-key/list/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    //   {
    //     id: 'freightForwarder',
    //     title: 'Freight Forwarder',
    //     messageId: 'sidebar.superAdminKeyGenerator.freightForwarder',
    //     type: 'item',
    //     url: ['/super-admin/key-generator/freightforwarder/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    // ],
  },

  // Account Management
  {
    id: 'accountManagement',
    title: 'Account Management',
    messageId: 'sidebar.superAdminAccountManagement',
    type: 'item',
    icon: ['/assets/images/sideMenu/account-management-active.png', '/assets/images/sideMenu/account-management.png'],
    role: ['ROLE_SUPERADMIN'],
    url: [
      '/super-admin/admins/account-management/package/new',
      '/super-admin/admins/account-management/packages/list/view',
      '/super-admin/admins/account-management/addons/request/list/view',
      '/super-admin/admins/account-management/package/edit/',
      '/super-admin/admins/account-management/addons/request/details',
    ],
    // children: [
    //   {
    //     id: 'addPackage',
    //     title: 'Add Plan',
    //     messageId: 'sidebar.superAdminAccountManagement.create',
    //     type: 'item',
    //     url: ['/super-admin/admins/account-management/package/new'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png'],
    //   },
    //   {
    //     id: 'packageList',
    //     title: 'Manage Plans',
    //     messageId: 'sidebar.superAdminAccountManagement.list',
    //     type: 'item',
    //     url: ['/super-admin/admins/account-management/packages/list/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png'],
    //   },
    //   {
    //     id: 'addonsRequest',
    //     title: 'Add-ons Request',
    //     messageId: 'sidebar.superAdminAccountManagement.addonsRequest',
    //     type: 'item',
    //     url: ['/super-admin/admins/account-management/addons/request/list/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png'],
    //   },
    // ],
  },

  // Statistics
  {
    id: 'statistics',
    title: 'Statistics',
    messageId: 'sidebar.statistics',
    type: 'item',
    icon: ['/assets/images/sideMenu/statistics-active.png', '/assets/images/sideMenu/statistics.png'],
    role: ['ROLE_SUPERADMIN'],
    url: [
      '/super-admin/statistics',
    ],
    // children: [
    //   {
    //     id: 'statistics',
    //     title: 'Statistics',
    //     messageId: 'sidebar.statistics.statistics',
    //     type: 'item',
    //     url: ['/super-admin/statistics'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    // ],
  },

  // Airport
  {
    id: 'airportManagement',
    title: 'Airport',
    messageId: 'sidebar.superAdminAirportManagement',
    type: 'item',
    icon: ['/assets/images/sideMenu/airport-active.png', '/assets/images/sideMenu/airport.png'],
    role: ['ROLE_SUPERADMIN'],
    url: [
      '/super-admin/admins/airport-management/create/new',
      '/super-admin/admins/airport-management/list/view',
      '/super-admin/admins/airport-management/edit/',
    ],
    // children: [
    //   {
    //     id: 'addAirport',
    //     title: 'Add Airport',
    //     messageId: 'sidebar.superAdminAirportManagement.create',
    //     type: 'item',
    //     url: ['/super-admin/admins/airport-management/create/new'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png'],
    //   },
    //   {
    //     id: 'airportList',
    //     title: 'Airport List',
    //     messageId: 'sidebar.superAdminAirportManagement.list',
    //     type: 'item',
    //     url: ['/super-admin/admins/airport-management/list/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png'],
    //   },
    // ],
  },

  // Airlines
  {
    id: 'superAdminAirline',
    title: 'Airlines',
    messageId: 'sidebar.superAdminAirline',
    type: 'item',
    icon: ['/assets/images/sideMenu/airline-active.png', '/assets/images/sideMenu/airline.png'],
    role: ['ROLE_SUPERADMIN'],
    url: [
      '/super-admin/airline/companies/approved-airlines',
      '/super-admin/airline/companies/unapproved-airlines',
      '/super-admin/airline/companies/add-new-airline',
      '/super-admin/airline/admins/list/view',
      '/super-admin/airline/airfares/abc/',
      '/super-admin/airline/airfares/details/update',
      '/super-admin/airline/admins-enrolled/',
      '/super-admin/airline/surcharges/surcharge',
      '/super-admin/airline/admin/general-rate/surcharge',
      '/super-admin/airline/admin/sur-charge/create',
      '/super-admin/airline/companies/localdb-airlines',
      '/super-admin/airline/localdb/airline/',
      '/super-admin/airline/localdbairfares/details/update',
      '/super-admin/airline/companies/update/surcharges',
      '/super-admin/airline/companies/update/localdb/surcharges',
    ],
    // children: [
    //   {
    //     id: 'airlineCompanies',
    //     title: 'Airlines',
    //     messageId: 'sidebar.superAdminAirline.companies',
    //     type: 'item',
    //     url: ['/super-admin/airline/companies/list/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    //   {
    //     id: 'airlineAdmin',
    //     title: 'Administrators',
    //     messageId: 'sidebar.superAdminAirline.admin',
    //     type: 'item',
    //     url: ['/super-admin/airline/admins/list/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png'],
    //   },
    // ],
  },

  // Documents
  // {
  //   id: 'documentsManagement',
  //   title: 'Documents',
  //   messageId: 'sidebar.documentsManagement',
  //   type: 'collapse',
  //   icon: ['/assets/images/sideMenu/documents-active.png', '/assets/images/sideMenu/documents.png'],
  //   role: ['ROLE_SUPERADMIN'],
  //   children: [],
  // },

  // Notifications
  {
    id: 'notification',
    title: 'Notifications',
    messageId: 'sidebar.notification',
    type: 'item',
    icon: ['/assets/images/sideMenu/notification-active.png', '/assets/images/sideMenu/notification.png'],
    role: ['ROLE_SUPERADMIN'],
    url: [
      '/super-admin/notification',
    ],
    // children: [
    //   {
    //     id: 'notification',
    //     title: 'Notifications',
    //     messageId: 'sidebar.notification',
    //     type: 'item',
    //     url: ['/super-admin/notification'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    // ],
  },

  // Feedback
  {
    id: 'feedBackManagement',
    title: 'Feedback',
    messageId: 'sidebar.feedBackManagement',
    type: 'item',
    icon: ['/assets/images/sideMenu/feedback-active.png', '/assets/images/sideMenu/feedback.png'],
    role: ['ROLE_SUPERADMIN'],
    url: [
      '/super-admin/feedback/view',
      '/super-admin/freight-forwarder/Feedback/list/view',
    ],
    // children: [
    //   {
    //     id: 'feedback',
    //     title: 'Feedback',
    //     messageId: 'sidebar.feedBackManagement.feedback',
    //     type: 'item',
    //     url: ['/super-admin/feedback/view'],
    //     icon: ['/assets/images/sideMenu/sub-menu.png', '/assets/images/sideMenu/sub-menu.png']
    //   },
    // ], 
  },

  // Tutorial
  {
    id: 'ytTutorial',
    title: 'Tutorial',
    messageId: 'sidebar.ytTutorial',
    type: 'item',
    icon: ['/assets/images/sideMenu/youtube-active.png', '/assets/images/sideMenu/youtube.png'],
    role: ['ROLE_SUPERADMIN'],
    url: ['/super-admin/tutorial/addtutorial'],
  },


];