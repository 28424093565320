import React, { lazy } from 'react';

export const dataEntryRouteConfig = [
  //AIRFARE ROUTES
  {
    auth: ['ROLE_DATAENTRY'],
    routes: [
      {
        path: '/data-entry/airfare/list/view',
        component: lazy(() => import('pages/mainPages/DataEntry/Airfare/AirfareRatesList')),
      },
      {
        path: '/data-entry/airfare/create',
        component: lazy(() => import('pages/mainPages/DataEntry/Airfare/CreateAirfare')),
      },
      {
        path: '/data-entry/airfare/upload',
        component: lazy(() => import('pages/mainPages/DataEntry/Airfare/UploadAirfare')),
      },
      {
        path: '/data-entry/airfare/edit/:id',
        component: lazy(() => import('pages/mainPages/DataEntry/Airfare/AirfareRatesDetails')),
      },
      {
        path: '/data-entry/airfare/statistics',
        component: lazy(() => import('pages/mainPages/DataEntry/Statistics/Statistics')),
      },


      // DownloadFormatFile
      {
        path: '/data-entry/airfare/download',
        component: lazy(() => import('pages/mainPages/DataEntry/Airfare/DownloadFormatFile')),
      },

      // Weight Range
      {
        path: '/data-entry/wieght-range/create',
        component: lazy(() => import('pages/mainPages/DataEntry/WeightRange/Create')),
      },
      {
        path: '/data-entry/wieght-range/update',
        component: lazy(() => import('pages/mainPages/DataEntry/WeightRange/Update')),
      },

      // Sur Charge
      {
        path: '/data-entry/sur-charge/create',
        component: lazy(() => import('pages/mainPages/DataEntry/SurCharge/Create')),
      },
      {
        path: '/data-entry/sur-charge/update',
        component: lazy(() => import('pages/mainPages/DataEntry/SurCharge/Update')),
      },

      // Notification
      {
        path: '/data-entry/notification',
        component: React.lazy(() => import('pages/mainPages/DataEntry/Notification/Notification')),
      },

      //Tutorial View
      {
        path: '/data-entry/tutorial/view',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Tutorial/ViewTutorial'),
        ),
      },
    ],
  },
];
