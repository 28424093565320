import axios from 'axios';
import store from '../../../../redux/store/index';

// export const baseUrlFormat = 'http://3.134.15.140:8080/api'; // Development
// export const baseUrlFormat = 'http://3.131.16.169:8080/api'; // Demo
// export const baseUrlFormat = 'http://18.190.6.112:8080/api'; // Alpha
// export const baseUrlFormat = 'http://18.189.152.156:8080/api'; // Beta
export const baseUrlFormat = 'https://betav1.goinhive.com/api'; // Beta

const jwtAxios = axios.create({
  // baseURL: 'http://3.134.15.140:8080/api', //YOUR_API_URL HERE
  // baseURL: 'http://3.131.16.169:8080/api', //YOUR_API_URL HERE
  // baseURL: 'http://18.190.6.112:8080/api', //YOUR_API_URL HERE 
  // baseURL: 'http://18.189.152.156:8080/api', //YOUR_API_URL HERE
  baseURL: 'https://betav1.goinhive.com/api', //YOUR_API_URL HERE

  headers: {
    'Content-Type': 'application/json',
    // 'Authorization': `Bearer ${localStorage.getItem('token')}`
  },
});

jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);

export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common['x-auth-token'] = token;
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['x-auth-token'];
    localStorage.removeItem('token');
  }
};

export default jwtAxios;
