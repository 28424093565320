export const GET_ECONOMICS_RANGES_REQ = 'GET_ECONOMICS_RANGES_REQ';
export const GET_ECONOMICS_RANGES_SUCCESS = 'GET_ECONOMICS_RANGES_SUCCESS';
export const GET_ECONOMICS_RANGES_FAIL = 'GET_ECONOMICS_RANGES_FAIL';

export const UPDATE_ECONOMICS_RANGE_REQ = 'UPDATE_ECONOMICS_RANGE_REQ';
export const UPDATE_ECONOMICS_RANGE_SUCCESS = 'UPDATE_ECONOMICS_RANGE_SUCCESS';
export const UPDATE_ECONOMICS_RANGE_FAIL = 'UPDATE_ECONOMICS_RANGE_FAIL';

export const CREATE_ECONOMICS_RANGE_REQ = 'CREATE_ECONOMICS_RANGE_REQ';
export const CREATE_ECONOMICS_RANGE_SUCCESS = 'CREATE_ECONOMICS_RANGE_SUCCESS';
export const CREATE_ECONOMICS_RANGE_FAIL = 'CREATE_ECONOMICS_RANGE_FAIL';

export const DEL_ECONOMICS_RANGE_REQ = 'DEL_ECONOMICS_RANGE_REQ';
export const DEL_ECONOMICS_RANGE_SUCCESS = 'DEL_ECONOMICS_RANGE_SUCCESS';
export const DEL_ECONOMICS_RANGE_FAIL = 'DEL_ECONOMICS_RANGE_FAIL';

export const GET_SINGLE_ECONOMICS_SUCCESS='GET_SINGLE_ECONOMICS_SUCCESS'