const initialState = {
  requesting: true,
  agentList: [],
  agentsEnrolled: [],
};

export const retrieveAirlineAgentListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'AGENT_LIST':
      return {
        ...state,
        requesting: false,
        agentList: payload,
      };
    case 'AGENT_ENROLLED':
      return {
        ...state,
        requesting: false,
        agentsEnrolled: [...payload],
      };
    default:
      return {
        ...state,
      };
  }
};
