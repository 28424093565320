const initialState = {
  requesting: true,
  data: {
    loading: true,
  },
  airlineCompanyList: [],
  assignKeyListForSuperAdmin: [],
  companyNameDropdown: [],
  keyManagementcompanyNameDropdown: [],
};

export const superAdminKeyGeneratorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_RETRIVE_AIRLINE_COMPANY_LIST':
      return {
        ...state,
        requesting: false,
        airlineCompanyList: payload,
      };

    case 'GET_ASSIGN_KEY_LIST_FOR_SUPERADMIN':
      return {
        ...state,
        requesting: false,
        assignKeyListForSuperAdmin: payload,
      };

    case 'GET_COMPANY_NAME_DROPDOWN':
      return {
        ...state,
        requesting: false,
        companyNameDropdown: payload,
      };

    case 'GET_KEY_MANAGEMENT_COMPANY_NAME_DROPDOWN':
      return {
        ...state,
        requesting: false,
        keyManagementcompanyNameDropdown: payload,
      };

    default:
      return state;
  }
};
