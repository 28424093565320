import {
  POST_FFAGENT_QUOTATIONS_PRICE_REQ,
  POST_FFAGENT_QUOTATIONS_PRICE_SUCCESS,
  POST_FFAGENT_QUOTATIONS_PRICE_FAIL,
  GET_FFAGENT_QUOTATIONS_REQ,
  GET_FFAGENT_QUOTATIONS_SUCCESS,
  GET_FFAGENT_QUOTATIONS_FAIL,
  GET_FFAGENT_QUOTATION_REQ,
  GET_FFAGENT_QUOTATION_SUCCESS,
  GET_FFAGENT_QUOTATION_FAIL,
  UPDATE_FFAGENT_QUOTATION_REQ,
  UPDATE_FFAGENT_QUOTATION_SUCCESS,
  UPDATE_FFAGENT_QUOTATION_FAIL,
  DELETE_FFAGENT_QUOTATION_REQ,
  DELETE_FFAGENT_QUOTATION_SUCCESS,
  DELETE_FFAGENT_QUOTATION_FAIL,
  CREATE_FFAGENT_QUOTATION_REQ,
  CREATE_FFAGENT_QUOTATION_SUCCESS,
  CREATE_FFAGENT_QUOTATION_FAIL,
  GET_FFAGENT_SEARCH_ADDRESS_REQ,
  GET_FFAGENT_SEARCH_ADDRESS_SUCCESS,
  GET_FFAGENT_SEARCH_ADDRESS_FAIL,
  GET_FFAGENT_SEARCH_ADDRESS2_REQ,
  GET_FFAGENT_SEARCH_ADDRESS2_SUCCESS,
  GET_FFAGENT_SEARCH_ADDRESS2_FAIL,
  GET_REQUEST_QUOTE_REQ,
  GET_REQUEST_QUOTE_SUCCESS,
  GET_REQUEST_QUOTE_FAIL,
  GET_SHIP_FROM_AIRPORT_REQ,
  GET_SHIP_FROM_AIRPORT_SUCCESS,
  GET_SHIP_FROM_AIRPORT_FAIL,
  GET_SHIP_TO_AIRPORT_REQ,
  GET_SHIP_TO_AIRPORT_SUCCESS,
  GET_SHIP_TO_AIRPORT_FAIL,
  GET_COUNTRY_REQ,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAIL,
  GET_SHIP_TO_QUICK_AIRPORT_REQ,
  GET_SHIP_TO_QUICK_AIRPORT_SUCCESS,
  GET_SHIP_TO_QUICK_AIRPORT_FAIL,
  GET_SEARCH_BY_ZIP_REQ,
  GET_SEARCH_BY_ZIP_SUCCESS,
  GET_SEARCH_BY_ZIP_FAIL,
  CHECK_QUOTE_AVAILABLE_REQ,
  CHECK_QUOTE_AVAILABLE_SUCCESS,
  CHECK_QUOTE_AVAILABLE_FAIL,
  SET_QUOTATION_DATA,
  SEND_QUOTE_BY_EMAIL_REQ,
  SEND_QUOTE_BY_EMAIL_SUCCESS,
  SEND_QUOTE_BY_EMAIL_FAIL,
  GET_AIRPORT_DEPATURE_LIST,
  STORE_RADIO_OPTION,
  GET_EMPTY_COUNTRY_LIST,
  POST_FFAGENT_QUOTATION_PDF_REQ,
  POST_FFAGENT_QUOTATION_PDF_SUCCESS,
  POST_FFAGENT_QUOTATION_PDF_FAIL,
  GET_UPDATED_QUOTATION_LIST,
  GET_SHIPPER_LIST_REQ,
  GET_SHIPPER_LIST_SUCCESS,
  GET_SHIPPER_LIST_FAIL,
  SAVE_QUOTATION_DATA,
  SAVE_CHECKBOX_DATA,
  EDIT_SAVE_QUOTATION,
  EDIT_QUOTATION_REQ,
  EDIT_QUOTATION_SUCCESS,
  EDIT_QUOTATION_FAIL,
  SET_PREVIOUS_STATE,
  SET_UPDATE_PREVIOUS_STATE,
  SET_QUOTATION_UPDATE_UNIT,
  SET_SHIPPER_QUOTATION_REQ,
  SET_SHIPPER_QUOTATION_SUCCESS,
  SET_SHIPPER_QUOTATION_FAIL,
  GET_TRUKING_DATA,
  GET_RECALCULATE_VALUE,
  GET_DELIVERY_ZIPCODE_REQ,
  GET_DELIVERY_ZIPCODE_SUCCESS,
  GET_DELIVERY_ZIPCODE_FAIL,
  GET_UPDATE_DELIVERY_ZIPCODE_REQ,
  GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS,
  GET_UPDATE_DELIVERY_ZIPCODE_FAIL,
  GET_UPDATED_PICKUP_ZIPCODE_REQ,
  GET_UPDATED_PICKUP_ZIPCODE_SUCCESS,
  GET_UPDATED_PICKUP_ZIPCODE_FAIL,
  GET_UNASSIGNED_LIST_REQ,
  GET_UNASSIGNED_LIST_SUCCESS,
  GET_UNASSIGNED_LIST_FAIL,
  GET_ASSIGNED_LIST_REQ,
  GET_ASSIGNED_LIST_SUCCESS,
  GET_ASSIGNED_LIST_FAIL,
  SET_CREATE_SHIPPER_QUOTATION_SUCCESS,
  SET_CREATE_SHIPPER_QUOTATION_FAIL,
  SET_CREATE_SHIPPER_QUOTATION_REQ,
  SET_TRUKING_CARRIER_DATA,
  GET_USER_KEY_MANAGEMENT_SUCCESS,
  GET_KEY_COUNT_SUCCESS,
  GET_KEY_USER_ROLE_SELECT_SUCCESS,
  GET_NO_0F_QUOTATION,
  GET_KEY_MANAGEMENT_ASSIGNED_KEY_SUCCESS,
  GET_KEY_MANAGEMENT_ASSIGNED_KEY_REQ,
  GET_KEY_MANAGEMENT_ASSIGNED_KEY_FAIL,
  GET_KEY_MANAGEMENT_UNASSIGNED_KEY_REQ,
  GET_KEY_MANAGEMENT_UNASSIGNED_KEY_SUCCESS,
  GET_KEY_MANAGEMENT_UNASSIGNED_KEY_FAIL,
  SET_INCOMING_SHIPPER_QUOTATION_REQ,
  SET_INCOMING_SHIPPER_QUOTATION_SUCCESS,
  SET_INCOMING_SHIPPER_QUOTATION_FAIL,
  GET_FEED_BACK_LIST_REQ,
  GET_FEEDBACK_LIST_SUCCESS,
  GET_FEEDBACL_LIST_FAIL,
  GET_FFAGENT_REQ_QUOTEFORMODEL_LIST,
  GET_FFAGENT_SUCCESS_QUOTEFORMODEL_LIST,
  GET_FFAGENT_FAIL_QUOTEFORMODEL_LIST,
  UPDATE_QUOTATION_RESULT_DATA,
  GET_AIRLINE_LIST,
  GET_AIRLINE_LIST1,
  GET_MANUAL_QUOTATION_REQ,
  GET_MANUAL_QUOTATION_FAIL,
  GET_MANUAL_QUOTATION_SUCCESS,
  GET_PRINT_LOADER_STATUS,
  SAVE_DATA_FROM_NEW_QUOTE,
  REMOVE_SAVE_DATA_FROM_NEW_QUOTE

} from '../constants';

const INIT_STATE = {
  quotationList: [],
  userSelection: '',
  loading: false,
  singleQuotation: {},
  addressResult: [],
  addressResult2: [],
  reqQuoteList: [],
  shipFromAirportList: [],
  shipToAirportList: [],
  quotationResult: {},
  countryList: [],
  shipToQuickAirportList: [],
  addressByZip: [],
  stateData: [],
  storedQuotation: {},
  airportOfDepartureList: [],
  pdfrequest: false,
  pdfData: [],
  quotationListLoading: true,
  updatedQuotation: [],
  shipperLoading: true,
  shipperData: [],
  saveQuotation: {},
  checkBoxOption: {},
  date: '',
  unit: '',
  editSaveQuotaion: {},
  editSaveQuotationLoading: false,
  departure: '',
  destination: '',
  truckingCarriersDummyVariable: [],
  previousUnit: 'Imperial / Metric',
  getUpdatePreviousUnit: '',
  getQuoteUpdateUnit: '',
  shipperQuotation: {},
  statusFromUpdate: false,
  preAirportOfDepatureDetails: '',
  preAirportOfDestinationDetails: '',
  deliveryAdressByzip: [],
  deliveryPlace: [],
  singleQuotationDetail: {},
  pickUpAdress: [],
  pickAdressData: [],
  updatedAddress: [],
  updatedArrayData: [],
  unassignedLoading: false,
  unassignedList: [],
  assignedListLoading: false,
  assignedList: [],
  shipperQuotationLoading: false,
  shipperQuotation: {},
  shipperQuotationResult: {},
  trukingCarrierData: [],
  confirmStatus: '',
  comment: '',
  listOfUser: [],
  statisticData: {},
  listOfRoleFilterData: [],
  noOfQuotaion: [],
  listOfassignData: [],
  listLoading: false,
  listOfUnassignData: [],
  unassignListLoading: false,
  feedBackList: [],
  feedBacklistReq: false,
  getPrintLoaderStatus: '',
  quoteForModelList: [],
  airlineList1: [],
  airlineList2: [],
  manualQuoationReq: false,
  manualQuotationData: {},
  manualQuoteData: {}
};

const ffAgentquotationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'RETRIEVE_PRE_AIRPORT_OF_DEPARTURE_DETAILS':
      return {
        ...state,
        preAirportOfDepatureDetails: action.payload,
        requesting: false,
      };

    case 'SAVE_DATA_FROM_NEW_QUOTE':
      return {
        ...state,
        manualQuoteData: action.payload
      }
    case 'REMOVE_SAVE_DATA_FROM_NEW_QUOTE':
      return {
        ...state,
        manualQuoteData: {}
      }

    case 'RETRIEVE_PRE_AIRPORT_OF_DESTINATION_DETAILS':
      return {
        ...state,
        preAirportOfDestinationDetails: action.payload,
        requesting: false,
      };

    case SET_INCOMING_SHIPPER_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };

    case SET_INCOMING_SHIPPER_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        singleQuotation: action.payload.replayQuotation,
        singleQuotationDetail: action.payload.quotation,
        confirmStatus: action.payload.confirmStatus,
        comment: action?.payload?.comment,
      };

    case SET_INCOMING_SHIPPER_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_FFAGENT_QUOTATIONS_REQ:
    case GET_FFAGENT_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_FFAGENT_QUOTATIONS_SUCCESS:
      return {
        ...state,
        quotationListLoading: false,
        quotationList: action.payload,
      };

    case POST_FFAGENT_QUOTATIONS_PRICE_REQ:
      return {
        ...state,
        loading: true,
      };

    case POST_FFAGENT_QUOTATIONS_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        quotationList: action.payload,
      };

    case POST_FFAGENT_QUOTATIONS_PRICE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_FFAGENT_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        singleQuotation: action.payload.replayQuotation,
        singleQuotationDetail: action.payload.quotation,
      };
    case GET_FFAGENT_QUOTATIONS_FAIL:
    case GET_FFAGENT_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_FFAGENT_QUOTATION_REQ:
    case UPDATE_FFAGENT_QUOTATION_REQ:
    case DELETE_FFAGENT_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_FFAGENT_QUOTATION_SUCCESS:
    case DELETE_FFAGENT_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CREATE_FFAGENT_QUOTATION_SUCCESS:
      return {
        ...state,
        // truckingCarriersDummyVariable: action.payload.truckingCarriers,
        quotationResult: action.payload,
        loading: false,
        statusFromUpdate: false,
      };
    case CREATE_FFAGENT_QUOTATION_FAIL:
    case UPDATE_FFAGENT_QUOTATION_FAIL:
    case DELETE_FFAGENT_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_FFAGENT_SEARCH_ADDRESS_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_FFAGENT_SEARCH_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        addressResult: action.payload,
      };
    case GET_FFAGENT_SEARCH_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_FFAGENT_SEARCH_ADDRESS2_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_FFAGENT_SEARCH_ADDRESS2_SUCCESS:
      return {
        ...state,
        loading: false,
        addressResult2: action.payload,
      };
    case GET_FFAGENT_SEARCH_ADDRESS2_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_REQUEST_QUOTE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_REQUEST_QUOTE_SUCCESS:
      return {
        ...state,
        reqQuoteList: action.payload,
        loading: false,
      };
    case GET_REQUEST_QUOTE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIP_FROM_AIRPORT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIP_FROM_AIRPORT_SUCCESS:
      return {
        ...state,
        shipFromAirportList: action.payload,
        loading: false,
      };
    case GET_SHIP_FROM_AIRPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIP_TO_AIRPORT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIP_TO_AIRPORT_SUCCESS:
      return {
        ...state,
        shipToAirportList: action.payload,
        loading: false,
      };

    case GET_SHIP_TO_AIRPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_COUNTRY_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countryList: action.payload,
      };
    case GET_COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIP_TO_QUICK_AIRPORT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIP_TO_QUICK_AIRPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        shipToQuickAirportList: action.payload,
      };
    case GET_SHIP_TO_QUICK_AIRPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SEARCH_BY_ZIP_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SEARCH_BY_ZIP_SUCCESS:
      return {
        ...state,
        loading: false,
        addressByZip: action.payload,
        stateData: action.payload.places,
      };
    case GET_SEARCH_BY_ZIP_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CHECK_QUOTE_AVAILABLE_REQ:
      return {
        ...state,
        loading: true,
      };
    case CHECK_QUOTE_AVAILABLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHECK_QUOTE_AVAILABLE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SET_QUOTATION_DATA:
      return {
        ...state,
        storedQuotation: action.payload,
      };
    case SEND_QUOTE_BY_EMAIL_REQ:
      return {
        ...state,
        loading: true,
      };
    case SEND_QUOTE_BY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SEND_QUOTE_BY_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_AIRPORT_DEPATURE_LIST: {
      return {
        ...state,
        airportOfDepartureList: action.payload,
      };
    }
    case STORE_RADIO_OPTION: {
      return {
        ...state,
        userSelection: action.payload,
      };
    }
    case GET_EMPTY_COUNTRY_LIST: {
      return {
        ...state,
        shipToQuickAirportList: [],
      };
    }
    case POST_FFAGENT_QUOTATION_PDF_REQ: {
      return {
        ...state,
        pdfrequest: true,
      };
    }
    case POST_FFAGENT_QUOTATION_PDF_FAIL: {
      return {
        ...state,
        pdfrequest: false,
      };
    }
    case POST_FFAGENT_QUOTATION_PDF_SUCCESS: {
      return {
        ...state,
        pdfData: action.payload,
        pdfrequest: false,
      };
    }
    case GET_UPDATED_QUOTATION_LIST: {
      return {
        ...state,
        updatedQuotation: action.payload,
      };
    }

    case GET_SHIPPER_LIST_REQ:

    case GET_SHIPPER_LIST_SUCCESS:
      return {
        shipperLoading: false,
        ...state,
        shipperData: action.payload,
      };

    case SAVE_QUOTATION_DATA:
      return {
        ...state,
        saveQuotation: action.payload.data,
        date: action.payload.date,
        unit: action.payload.unit,
        departure: action.payload.airpDep,
        // destination: action.payload.airpDest,
        destination: action?.payload?.airpDest?.value ? action?.payload?.airpDest?.value : action?.payload?.airpDest,
      };

    case SAVE_CHECKBOX_DATA:
      return {
        ...state,
        checkBoxOption: action.payload,
      };
    case GET_SHIPPER_LIST_FAIL:

    case EDIT_SAVE_QUOTATION:
      return {
        ...state,
        editSaveQuotaion: action.payload,
      };

    case EDIT_QUOTATION_REQ:
      return {
        ...state,
        editSaveQuotationLoading: true,
      };

    case EDIT_QUOTATION_SUCCESS:
      return {
        ...state,
        // singleQuotation: action.payload,
        quotationResult: action.payload,

        editSaveQuotationLoading: false,
      };

    case EDIT_QUOTATION_FAIL:
      return {
        ...state,
        editSaveQuotationLoading: false,
      };

    case SET_PREVIOUS_STATE:
      return {
        ...state,
        previousUnit: action.payload,
      };

    case SET_UPDATE_PREVIOUS_STATE:
      return {
        ...state,
        getUpdatePreviousUnit: action.payload,
      };
    case SET_QUOTATION_UPDATE_UNIT:
      return {
        ...state,
        getQuoteUpdateUnit: action.payload,
      };

    case SET_SHIPPER_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };

    case SET_SHIPPER_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        shipperQuotation: action.payload,
      };
    case SET_SHIPPER_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_TRUKING_DATA:
      return {
        ...state,
        truckingCarriersDummyVariable: action.payload,
      };
    case GET_RECALCULATE_VALUE: {
      return {
        ...state,
        statusFromUpdate: true,
      };
    }

    case GET_DELIVERY_ZIPCODE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_DELIVERY_ZIPCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryAdressByzip: action.payload,
        deliveryPlace: action.payload.places,
      };
    case GET_DELIVERY_ZIPCODE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_UPDATE_DELIVERY_ZIPCODE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedAddress: action.payload,
        updatedArrayData: action.payload.places,
      };
    case GET_UPDATE_DELIVERY_ZIPCODE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_UPDATED_PICKUP_ZIPCODE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_UPDATED_PICKUP_ZIPCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        pickUpAdress: action.payload,
        pickAdressData: action.payload.places,
      };
    case GET_UPDATED_PICKUP_ZIPCODE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_UNASSIGNED_LIST_REQ:
      return {
        ...state,
        unassignedLoading: true,
      };
    case GET_UNASSIGNED_LIST_SUCCESS:
      return {
        ...state,
        unassignedLoading: false,
        unassignedList: action.payload,
      };

    case GET_UNASSIGNED_LIST_FAIL:
      return {
        ...state,
        unassignedLoading: false,
      };

    case GET_ASSIGNED_LIST_REQ:
      return {
        ...state,
        assignedListLoading: true,
      };
    case GET_ASSIGNED_LIST_SUCCESS:
      return {
        ...state,
        assignedListLoading: false,
        assignedList: action.payload,
      };

    case GET_ASSIGNED_LIST_FAIL:
      return {
        ...state,
        assignedListLoading: false,
      };

    case SET_CREATE_SHIPPER_QUOTATION_REQ: {
      return {
        ...state,
        shipperQuotationLoading: true,

      };
    }
    case SET_CREATE_SHIPPER_QUOTATION_SUCCESS: {
      return {
        ...state,
        shipperQuotationLoading: false,
        shipperQuotationResult: action.payload,
      }
    }

    case SET_TRUKING_CARRIER_DATA:
      return {
        ...state,
        trukingCarrierData: action.payload
      }

    case SET_CREATE_SHIPPER_QUOTATION_FAIL: {

      return {
        ...state,
        shipperQuotationLoading: false,

      }
    }
    case GET_USER_KEY_MANAGEMENT_SUCCESS:
      return {
        ...state,
        listOfUser: action.payload
      }

    case GET_KEY_COUNT_SUCCESS:
      return {
        ...state,
        statisticData: action.payload
      }

    case GET_KEY_USER_ROLE_SELECT_SUCCESS:
      return {
        ...state,
        listOfRoleFilterData: action.payload
      }
    case GET_NO_0F_QUOTATION:
      return {
        ...state,
        noOfQuotaion: action.payload
      }

    case GET_KEY_MANAGEMENT_ASSIGNED_KEY_REQ:
      return {
        ...state,
        listLoading: true,
      };
    case GET_KEY_MANAGEMENT_ASSIGNED_KEY_SUCCESS:
      return {
        ...state,
        listLoading: false,
        listOfassignData: action.payload,
      };

    case GET_KEY_MANAGEMENT_ASSIGNED_KEY_FAIL:
      return {
        ...state,
        listLoading: false,
      };

    case GET_KEY_MANAGEMENT_UNASSIGNED_KEY_REQ:
      return {
        ...state,
        unassignListLoading: true,
      };
    case GET_KEY_MANAGEMENT_UNASSIGNED_KEY_SUCCESS:
      return {
        ...state,
        unassignListLoading: false,
        listOfUnassignData: action.payload,
      };

    case GET_KEY_MANAGEMENT_UNASSIGNED_KEY_FAIL:
      return {
        ...state,
        unassignListLoading: false,
      };

    case GET_FEED_BACK_LIST_REQ:
      return {
        ...state,
        feedBacklistReq: true,
      };
    case GET_FEEDBACK_LIST_SUCCESS:
      return {
        ...state,
        feedBacklistReq: false,
        feedBackList: action.payload
      };
    case GET_FEEDBACL_LIST_FAIL:
      return {
        ...state,
        feedBacklistReq: false,
      };

    case GET_PRINT_LOADER_STATUS:
      return {
        ...state,
        getPrintLoaderStatus: action.payload,
      };

    case GET_FFAGENT_REQ_QUOTEFORMODEL_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_FFAGENT_SUCCESS_QUOTEFORMODEL_LIST:
      return {
        ...state,
        loading: false,
        quoteForModelList: action.payload,
      };

    case GET_FFAGENT_FAIL_QUOTEFORMODEL_LIST:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_QUOTATION_RESULT_DATA:
      const quotationData = { ...state?.quotationResult, economicsRates: action?.payload?.economicsRates }
      // console.log(quotationData)
      return {
        ...state,
        quotationResult: quotationData
      }


    case GET_AIRLINE_LIST:
      return {
        ...state,
        airlineList1: action.payload
      }


    case GET_AIRLINE_LIST1:
      return {
        ...state,
        airlineList2: action.payload
      }
    case GET_MANUAL_QUOTATION_REQ:
      return {
        ...state,
        manualQuoationReq: true,
      };

    case GET_MANUAL_QUOTATION_FAIL:
      return {
        ...state,
        manualQuoationReq: false
      }

    case GET_MANUAL_QUOTATION_SUCCESS:
      return {
        ...state,
        manualQuoationReq: false,
        manualQuotationData: action.payload?.replayQuotation,
      };


    default:
      return state;
  }
};
export default ffAgentquotationReducer;
