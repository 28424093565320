import React, { useContext } from 'react';
import AppContext from '../../../@crema/utility/AppContext';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { grey, orange } from '@material-ui/core/colors';
import { Fonts } from '../../constants/AppEnums';
import { useTheme } from '@material-ui/core/styles';
import AppLogo from '../../../shared/components/AppLogo';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => {
  return {
    crUserInfo: {
      backgroundColor: 'rgba(0,0,0,.08)',
      paddingTop: 9,
      paddingBottom: 9,
      minHeight: 56,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 10,
        paddingBottom: 10,
        minHeight: 70,
      },
    },
    profilePic: {
      height: 40,
      width: 40,
      fontSize: 24,
      backgroundColor: orange[500],
      [theme.breakpoints.up('xl')]: {
        height: 45,
        width: 45,
      },
    },
    userInfo: {
      width: 'calc(100% - 5px)',
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 18,
      fontWeight: Fonts.MEDIUM,
      [theme.breakpoints.up('xl')]: {
        fontSize: 20,
      },
      color: (props) => (props.themeMode === 'light' ? '#313541' : 'white'),
    },
    designation: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    pointer: {
      cursor: 'pointer',
    },
    adminRoot: {
      color: grey[500],
      fontSize: 16,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    menuIcon: {
      width: 30,
      height: 30,
    },
  };
});

const UserInfo = ({ handleToggleDrawer }) => {
  const { themeMode } = useContext(AppContext);
  const theme = useTheme();
  const classes = useStyles({ themeMode });
  const navCollapsed = useSelector(({ settings }) => settings.navCollapsed);
  const openDrawer = useSelector(({ settings }) => settings.openDrawer);

  return (
    <Box px={{ xs: 4, xl: 7 }} className={clsx(classes.crUserInfo, 'cr-user-info')}>
      <Box display="flex" alignItems="center">
        <Box ml={4} className={clsx(classes.userInfo, 'user-info')}>
          {/* <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box mb={0} className={clsx(classes.userName)}>
              {
                navCollapsed?
              <AppLogo />:openDrawer?<AppLogo />:''
              }
            </Box>
            <Box>
              {
                navCollapsed?
              <IconButton onClick={() => handleToggleDrawer()}>
                {theme.direction === 'ltr' ? (
                  <ChevronLeftIcon className={classes.menuIcon} />
                ) :''
                //  (
                //   <ChevronRightIcon className={classes.menuIcon} />
                // )
                }
              </IconButton>:
            //   openDrawer? <IconButton onClick={() => handleToggleDrawer()}>
            //   {theme.direction === 'ltr' ? (
            //     <ChevronLeftIcon className={classes.menuIcon} />
            //   ) :''}
            // </IconButton>:
              ''
              }
            </Box>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default UserInfo;
