import React, { lazy } from 'react';

export const ffAgentRouteConfig = [
  {
    auth: ['ROLE_FFAGENT'],
    routes: [
      // ADD My Information ROUTES
      {
        path: '/freight-forwarder/agent/profile/update',
        component: lazy(() =>
          // import('pages/mainPages/FreightForwarder/Agent/Profile/UserProfile/UserProfile.js'),
          import('pages/mainPages/FreightForwarder/Admin/Profile/UserProfile/UserProfile.js'),
        ),
      },
      // Quote Email Message Design
      {
        path: '/freight-forwarder/agent/profile/quote/email/msg/setting',
        component: lazy(() =>
          // import('pages/mainPages/FreightForwarder/Agent/Profile/QuoteEmailMessage/QuoteEmailMessage'),
          import('pages/mainPages/FreightForwarder/Admin/Profile/QuoteEmailMessage/QuoteEmailMessage'),
        ),
      },
      // Measurements System
      {
        path: '/freight-forwarder/agent/profile/measurements/system',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Profile/MeasurementsSystem/MeasurementsSystem'),
        ),
      },

      // Client Message
      {
        path: '/freight-forwarder/agent/shippers/message/list/view',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/Shipper/Messages/index')),
      },

      // ADD shipper ROUTES
      {
        path: '/freight-forwarder/agent/shipper/add',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Agent/Shipper/addShipper')),
      },
      {
        path: '/freight-forwarder/agent/shippers/requests/list/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/Shipper/RequestFromShippers'),
        ),
      },
      {
        path: '/freight-forwarder/agent/shipperRequest/quotation/price',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/RecalculateQuickQuote/index'
          ),
        ),
      },
      {
        path: '/freight-forwarder/agent/shipperRequest/Airfreight/price',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/RecalculateAirfreightOutput/index'
          ),
        ),
      },
      {
        path: '/freight-forwarder/agent/subscription/details/view',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Agent/AccountManagement/SubscriptionDetails/SubscriptionDetails'
          ),
        ),
      },
      {
        path: '/freight-forwarder/agent/approveAddonsRequest/details/view',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Agent/AccountManagement/ApproveAddonsRequest/ApproveAddonsRequest'
          ),
        ),
      },
      {
        path: '/freight-forwarder/agent/checkout_payment',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/AccountManagement/SubscriptionDetails/PaymentMethod'),
        ),
      },
      {
        path: '/freight-forwarder/agent/transaction-list/freightforwarder/view',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Agent/AccountManagement/Transactiondetail/TransactionList')),
      },

      {
        path: '/freight-forwarder/agent/transaction-list/details/view',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Agent/AccountManagement/Transactiondetail/TransactionDetail')),
      },
      //Manual Quote
      {
        path: '/freight-forwarder/agent/quotation/manual/view',
        component: lazy(() =>
          // import('pages/mainPages/FreightForwarder/Agent/Quotation/ManualQuote/ManualQuote'),
          import('pages/mainPages/FreightForwarder/Admin/Quotation/ManualQuote/ManualQuote'),

        ),
      },
      {
        path: '/freight-forwarder/agent/quotation/manual/details',
        component: lazy(() =>
          // import('pages/mainPages/FreightForwarder/Agent/Quotation/ManualQuote/ManualQuoteDetails/index'),
          import('pages/mainPages/FreightForwarder/Admin/Quotation/ManualQuote/ManualQuoteDetails/index'),
        ),
      },
      // ECONOMICS ROUTES
      {
        path: '/freight-forwarder/agent/economics/create',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/Economics/EconomicsList/AddNewEconomics'),
        ),
      },
      {
        path: '/freight-forwarder/agent/economics/list/view',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Agent/Economics/CreateEconomics/CreateEconomics'
          ),
        ),
      },
      {
        path: '/freight-forwarder/agent/preset/margin/setup',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/Economics/PresetMarginSetup/index'),

        ),
      },
      {
        path: '/freight-forwarder/agent/economics',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Agent/Economics/CreateEconomics/CreateEconomics'
          ),
        ),
      },

      //Feedback List
      {
        path: '/freight-forwarder/agent/feedback/view',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/Feedback/FeedbackList'),
        ),
      },
      {
        path: '/freight-forwarder/agent/addfeedback',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/Feedback/Addfeedback'),
        ),
      },

      //Tutorial View
      {
        path: '/freight-forwarder/agent/tutorial/view',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Tutorial/ViewTutorial'),
        ),
      },

      //Feedback List View
      {
        path: '/freight-forwarder/agent/feedback/feedbacklist/view/:id',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/Feedback/FeedbackListView'),
        ),
      },

      // ECONOMICS RANGES ROUTES
      // {
      //   path: '/freight-forwarder/agent/trucking-ranges/create',
      //   component: lazy(() =>
      //     import(
      //       'pages/mainPages/FreightForwarder/Agent/EconomicsRanges/TruckingRange/CreateRange'
      //     ),
      //   ),
      // },
      {
        path: '/freight-forwarder/agent/trucking-ranges/list',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Agent/EconomicsRanges/TruckingRange/UpdateRange'
          ),
        ),
      },
      // {
      //   path: '/freight-forwarder/agent/airline-ranges/create',
      //   component: lazy(() =>
      //     import('pages/mainPages/FreightForwarder/Agent/EconomicsRanges/AirlineRange/CreateRange'),
      //   ),
      // },
      {
        path: '/freight-forwarder/agent/airline-ranges/list',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/EconomicsRanges/AirlineRange/UpdateRange'),
        ),
      },
      {
        path: '/freight-forwarder/agent/quotation/shippers/incoming/quote/details/manualquotation/:id',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/ShipperRequestManualquoteDetails/'
          ),
        ),
      },
      {
        path: '/freight-forwarder/agent/insurance-ranges/create',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Agent/EconomicsRanges/InsuranceRange/CreateRange'
          ),
        ),
      },
      {
        path: '/freight-forwarder/agent/insurance-ranges/list',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Agent/EconomicsRanges/InsuranceRange/UpdateRange'
          ),
        ),
      },

      // 	WAREHOUSE ROUTES
      {
        path: '/freight-forwarder/agent/warehosue/create',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/WareHouse/AddWareHouse'),
        ),
      },
      {
        path: '/freight-forwarder/agent/warehosue/list/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/WareHouse/WareHouseList'),
        ),
      },

      {
        path: '/freight-forwarder/agent/warehouse/edit/:warehouseId',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/WareHouse/EditWareHouse'),
        ),
      },

      // QUOTATION ROUTES
      {
        path: '/freight-forwarder/agent/quotation/create',
        component: lazy(() =>
          // import('pages/mainPages/FreightForwarder/Agent/Quotation/QuotationCreate'),
          import('pages/mainPages/FreightForwarder/Admin/Quotation/QuotationCreate/index'),
        ),
      },
      {
        path: '/freight-forwarder/agent/quotation/list/view',
        component: lazy(() =>
          // import('pages/mainPages/FreightForwarder/Agent/Quotation/QuotationList'),
          import('pages/mainPages/FreightForwarder/Admin/Quotation/QuotationList'),

        ),
      },
      {
        path: '/freight-forwarder/agent/quotation/output',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/Quotation/QuoteOutput'),
        ),
      },
      {
        path: '/freight-forwarder/agent/quotation/details/:id',
        component: lazy(() =>
          // import('pages/mainPages/FreightForwarder/Agent/Quotation/QuotationDetails'),
          // import('pages/mainPages/FreightForwarder/Agent/Quotation/PreviousQuote/QuickQuoteOutPut'),
          import('pages/mainPages/FreightForwarder/Admin/Quotation/PreviousQuote/QuickQuoteOutPut'),
        ),
      },

      {
        path: '/freight-forwarder/agent/quotation/goods/details/view/:id',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/Quotation/GoodsQuotationDetails'),
        ),
      },

      {
        path: '/freight-forwarder/agent/quotation/price/quickQuote',
        component: lazy(() =>
          import(
            // 'pages/mainPages/FreightForwarder/Agent/Quotation/QuickQuote/QuickQuoteOutPut/index'
            'pages/mainPages/FreightForwarder/Admin/Quotation/QuickQuote/QuickQuoteOutPut/index'
          ),
        ),
      },

      {
        path: '/freight-forwarder/agent/quotation/price/quickQuotePlus',
        component: lazy(() =>
          import(
            // 'pages/mainPages/FreightForwarder/Agent/Quotation/QuickQuote/QuickQuoteOutPut/index'
            'pages/mainPages/FreightForwarder/Admin/Quotation/QuickQuote/QuickQuoteOutPut/index'
          ),
        ),
      },
      {
        path: '/freight-forwarder/agent/quotation/Airfreight-quote/price',
        component: lazy(() =>
          import(
            // 'pages/mainPages/FreightForwarder/Agent/Quotation/Airfreight/AirfreightOutPut/index'
            'pages/mainPages/FreightForwarder/Admin/Quotation/Airfreight/AirfreightOutPut/index'
          ),
        ),
      },
      {
        path: '/freight-forwarder/agent/quotation/inquerier/list/view',
        component: lazy(() =>
          // import(
          //   'pages/mainPages/FreightForwarder/Agent/Quotation/QuoteInquerier'
          // ),
          import('pages/mainPages/FreightForwarder/Admin/Quotation/QuoteInquerier'),
        ),
      },


      // Quotation Shipper
      {
        path: '/freight-forwarder/agent/quotation/shipper/requests/list/view',
        component: lazy(() =>
          import(
            // 'pages/mainPages/FreightForwarder/Agent/Quotation/ShipperRequest/ShipperRequestList'
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/IncomingQuotesIncoming/QuotesIncoming'
          ),
        ),
      },
      {
        path: '/freight-forwarder/agent/quotation/shipper/request/details/view/:id',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Admin/Quotation/ShipperRequest/ShipperRequestDetails'
            // 'pages/mainPages/FreightForwarder/Agent/Quotation/ShipperRequest/ShipperRequestDetails'
          ),
        ),
      },
      {
        path: '/freight-forwarder/agent/quotation/shipper/requests/goods/details/view/:id',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Agent/Quotation/ShipperRequest/GoodShipperRequestDetails'
          ),
        ),
      },
      {
        path: '/freight-forwarder/agent/quotation/shippers/request/quickquote/price',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Agent/Quotation/ShipperRequest/QuickQuote/QuickQuoteOutPut/index'
          ),
        ),
      },
      {
        path: '/freight-forwarder/agent/quotation/shippers/request/generatedquote/details/:id',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Agent/Quotation/ShipperRequest/GeneratedQuoteRequestDetails'
          ),
        ),
      },
      // {
      //   path: '/freight-forwarder/agent/quotation/update',
      //   component: lazy(() =>
      //     import('pages/mainPages/FreightForwarder/Agent/Quotation/QuotationUpdate'),
      //   ),
      // },
      {
        path: '/freight-forwarder/agent/quotation/update/:departairport/:destairport',
        component: lazy(() =>
          // import('pages/mainPages/FreightForwarder/Agent/Quotation/QuotationUpdate'),
          import('pages/mainPages/FreightForwarder/Admin/Quotation/QuotationUpdate'),
        ),
      },

      {
        path: '/freight-forwarder/agent/quotation/previousquote',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/Quotation/PreviousQuote/QuickQuoteOutPut'),
        ),
      },

      // Generated Quote List
      {
        path: '/freight-forwarder/agent/quotation/shipper/generated/quote/list/view',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Agent/Quotation/ShipperRequest/GeneratedQuoteList'
          ),
        ),
      },

      //Directory ROUTES
      {
        path: '/freight-forwarder/agent/directory/address/new',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/Address/AddressAdd'),
        ),
      },
      {
        path: '/freight-forwarder/agent/directory/address/list/view',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/Address/AddressList'),
        ),
      },
      {
        path: '/freight-forwarder/agent/directory/address/edit/:id',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/Address/AddressEdit'),
        ),
      },
      //Statistics ROUTES
      {
        path: '/freight-forwarder/agent/statistics',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/Statistics/Statistics'),
        ),
      },

      // TRACKING ROUTES
      {
        path: '/freight-forwarder/agent/tracking',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Agent/Tracking')),
      },

      // Notification
      {
        path: '/freight-forwarder/agent/notification',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/Notification/Notification'),
        ),
      },

      //Payment Success
      {
        path: '/freight-forwarders/agent/subscription/payment/success',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Agent/AccountManagement/SubscriptionDetails/PaymentSuccessFFAgent')),
      },

      // ASSIGN KEY ROUTES
      {
        path: '/freight-forwarder/agent/assign-key',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/KeyManagement/AssignKeyList'),
        ),
      },

      // UNASSIGN KEY ROUTES
      {
        path: '/freight-forwarder/agent/unassign-key',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/KeyManagement/UnassignKeyList'),
        ),
      },

      {
        path: '/super-admin/key-management/freightforwarder/agent/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/KeyManagement/FreightForwarder'),
        ),
      },

      {
        path: '/agent/key-management/freightforwarder/agent/details',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/KeyManagement/FreightForwarderDetails'),
        ),
      },

      {
        path: '/agent/key-management/freightforwarder/agent/clientdetails/action',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/KeyManagement/ActionKeyDetails'),
        ),
      },

      {
        path: '/freight-forwarder/agent/key-management/checkout_payment',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/RenewKeyModal/Payment/PaymentMethod'),
        ),
      },
      {
        path: '/freight-forwarder/agent/client/checkout_payment',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/RenewKeyModal/Payment/PaymentMethod'),
        ),
      },

      {
        path: '/freight-forwarder/agent/add/reassign/:reassignId',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/KeyManagement/AssignReassign'),
        ),
      },
      {
        path: '/agent/key-management/freightforwarder/agent/clientlist',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/KeyManagement/ClientListDetails'),
        ),
      },

      {
        path: '/agent/key-management/freightforwarder/agent/clientslist/action',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/KeyManagement/ActionKeyDetailsClientListFFAgent'),
        ),
      },
      {
        path: '/freight-forwarder/agent/key-management/payment-success',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/RenewKeyModal/Payment/PaymentSuccess'),
        ),
      },
      {
        path: '/freight-forwarders/agent/renewkey/payment/success',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/KeyManagement/RenewKeyModal/Payment/PaymentSuccess'),
        ),
      },


      // src\pages\mainPages\FreightForwarder\Agent\KeyManagement\ActionKeyDetailsClientListFFAgent.js

      // ADD SHIPPER ROUTES
      {
        path: '/freight-forwarder/agent/key-management/assign-key/add-shipper',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/KeyManagement/AssignAddShipper'),
        ),
      },

      {
        path: '/freight-forwarder/agent/key-management/unassign-key/add-shipper',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Agent/KeyManagement/UnassignAddShipper'),
        ),
      },
    ],
  },
];
