const initial_state = {
  // keyId: null,
  // companyId: null,
  alphanumericKey: null,
  keyType: null,
  clientType: null,
};

const keyValidationReducer = (state = initial_state, { type, payload }) => {
  switch (type) {
    case 'KEY_ID':
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default keyValidationReducer;
