import React, { useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

export default function IdleComponent() {
  const history = useHistory();
const idleTimeRef = useRef(null);
const onIdle = () => {
  console.log('logout');
  toast.error("SESSION EXPIRED");
  localStorage.removeItem('token');
  localStorage.removeItem('userDetails');
  history.go(0);
}
const idleTimer = useIdleTimer({
crossTab: true,
ref: idleTimeRef,
// timeout: 15 * 1000,
timeout: 60 * 45 * 1000,
onIdle: onIdle})
return (<div idleTimer={idleTimer}></div>);
}