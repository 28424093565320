import {
  GET_SUBSCRIPTION_REQ,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAIL,
  ADD_ADDONS_REQ,
  ADD_ADDONS_SUCCESS,
  ADD_ADDONS_FAIL,
  GET_TOTAL_ROLES_REQ,
  GET_TOTAL_ROLES_SUCCESS,
  GET_TOTAL_ROLES_FAIL,
  GET_APPROVED_ADDON_REQ,
  GET_APPROVED_ADDON_SUCCESS,
  GET_APPROVED_ADDON_FAIL,
  GET_AGENT_APPROVED_ADDON_REQ,
  GET_AGENT_APPROVED_ADDON_SUCCESS,
  GET_AGENT_APPROVED_ADDON_FAIL,
  PAY_NOW_REQ,
  PAY_NOW_SUCCESS,
  PAY_NOW_FAIL,
  GET_SUBSCRIPTION_STATS_REQ,
  GET_SUBSCRIPTION_STATS_SUCCESS,
  GET_SUBSCRIPTION_STATS_FAIL,
  GET_SUBSCRIPTION_DETAILS_BY_USER_REQ,
  GET_SUBSCRIPTION_DETAILS_BY_USER_SUCCESS,
  GET_SUBSCRIPTION_DETAILS_BY_USER_FAIL,
  GET_AD_ONS_DETAILS_VIEW_REQ,
  GET_AD_ONS_DETAILS_VIEW_SUCCESS,
  GET_AD_ONS_DETAILS_VIEW_FAIL,
  GET_CLIENT_SECRET_KEY,
  SAVE_ADDONS_DATA,
  COST_ROLE_TYPE,
  SAVE_TRANSACTION_ID,
  PAYPAL_STATUS,
  GET_TRANSACTION_DETAIL_REQ,
  GET_TRANSACTION_DETAIL_SUCCESS,
  GET_TRANSACTION_DETAIL_FAIL,
  GET_RECEIPT_DISCLAIMER_DETAIL_DATA_FF_AGENT,
  GET_TRANSACTION_PAYMENT_DETAILS_FF_AGENT
} from '../constants';

const initialSettings = {
  subscription: {},
  totalRoles: {},
  loading: false,
  approvedAddons: [],
  subscriptionStatsDetail: [],
  subscriptionDetailsByUser: [],
  adOnsDetailsView: [],
  clientSecretKey: '',
  addonsData: {},
  priceOfRoletype: {},
  transaCationId: {},
  payPalStatus: {},
  transactionLoading: false,
  transactionDetail: [],
  receiptDisclaimerData: {},
  transcationPaymmentDetailsData: {},
};

const AgentSubscription = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_REQ:
      return {
        ...state,
        loading: true,
      };
    case PAYPAL_STATUS:
      return {
        ...state,
        payPalStatus: action.payload
      }
    case SAVE_TRANSACTION_ID:
      return {
        ...state,
        transaCationId: action.payload
      }
    case COST_ROLE_TYPE:
      return {
        ...state,
        priceOfRoletype: action.payload
      }
    case GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        subscription: action.payload,
      };
    case GET_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SAVE_ADDONS_DATA:
      return {
        ...state,
        addonsData: action.payload ? action.payload : {}

      }
    case ADD_ADDONS_REQ:
      return {
        ...state,
        loading: true,
      };
    case ADD_ADDONS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_CLIENT_SECRET_KEY:
      return {
        ...state,
        clientSecretKey: action.payload
      }
    case ADD_ADDONS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_TOTAL_ROLES_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_TOTAL_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        totalRoles: action.payload,
      };
    case GET_TOTAL_ROLES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_APPROVED_ADDON_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_APPROVED_ADDON_SUCCESS:
      return {
        ...state,
        approvedAddons: action.payload,
        loading: false,
      };
    case GET_APPROVED_ADDON_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_AGENT_APPROVED_ADDON_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_AGENT_APPROVED_ADDON_SUCCESS:
      return {
        ...state,
        approvedAddons: action.payload,
        loading: false,
      };
    case GET_AGENT_APPROVED_ADDON_FAIL:
      return {
        ...state,
        loading: false,
      };
    case PAY_NOW_REQ:
      return {
        ...state,
        loading: true,
      };
    case PAY_NOW_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PAY_NOW_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_SUBSCRIPTION_STATS_REQ:
      return {
        ...state,
        subscriptionStatsDetail: [],
        loading: true,
      };
    case GET_SUBSCRIPTION_STATS_SUCCESS:
      return {
        ...state,
        subscriptionStatsDetail: action?.payload,
        loading: false,
      };
    case GET_SUBSCRIPTION_STATS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_SUBSCRIPTION_DETAILS_BY_USER_REQ:
      return {
        ...state,
        subscriptionDetailsByUser: [],
        loading: true,
      };
    case GET_SUBSCRIPTION_DETAILS_BY_USER_SUCCESS:
      return {
        ...state,
        subscriptionDetailsByUser: action?.payload,
        loading: false,
      };
    case GET_SUBSCRIPTION_DETAILS_BY_USER_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_AD_ONS_DETAILS_VIEW_REQ:
      return {
        ...state,
        adOnsDetailsView: [],
        loading: true,
      };
    case GET_AD_ONS_DETAILS_VIEW_SUCCESS:
      return {
        ...state,
        adOnsDetailsView: action?.payload,
        loading: false,
      };
    case GET_AD_ONS_DETAILS_VIEW_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_TRANSACTION_DETAIL_REQ:
      return {
        ...state,
        transactionLoading: true,
      };
    case GET_TRANSACTION_DETAIL_SUCCESS:
      return {
        ...state,
        transactionDetail: action?.payload,
        transactionLoading: false,
      };
    case GET_TRANSACTION_DETAIL_FAIL:
      return {
        ...state,
        transactionLoading: false,
      };

    case GET_RECEIPT_DISCLAIMER_DETAIL_DATA_FF_AGENT:
      return {
        ...state,
        receiptDisclaimerData: action?.payload
      }

    case GET_TRANSACTION_PAYMENT_DETAILS_FF_AGENT:
      return {
        ...state,
        transcationPaymmentDetailsData: action?.payload
      }

    default:
      return state;
  }
};

export default AgentSubscription;
