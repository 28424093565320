import {
  GET_SHIPPER_QUOTATIONS_REQ,
  GET_SHIPPER_QUOTATIONS_SUCCESS,
  GET_SHIPPER_QUOTATIONS_FAIL,
  POST_FFADMIN_QUOTATIONS_PRICE_REQ,
  POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS,
  POST_FFADMIN_QUOTATIONS_PRICE_FAIL,
  GET_FFADMIN_QUOTATIONS_REQ,
  GET_FFADMIN_QUOTATIONS_SUCCESS,
  GET_FFADMIN_QUOTATIONS_FAIL,
  GET_FFADMIN_QUOTATION_REQ,
  GET_FFADMIN_QUOTATION_SUCCESS,
  GET_FFADMIN_QUOTATION_FAIL,
  UPDATE_FFADMIN_QUOTATION_REQ,
  UPDATE_FFADMIN_QUOTATION_SUCCESS,
  UPDATE_FFADMIN_QUOTATION_FAIL,
  DELETE_FFADMIN_QUOTATION_REQ,
  DELETE_FFADMIN_QUOTATION_SUCCESS,
  DELETE_FFADMIN_QUOTATION_FAIL,
  CREATE_FFADMIN_QUOTATION_REQ,
  CREATE_FFADMIN_QUOTATION_SUCCESS,
  CREATE_FFADMIN_QUOTATION_FAIL,
  GET_FFADMIN_SEARCH_ADDRESS_REQ,
  GET_FFADMIN_SEARCH_ADDRESS_SUCCESS,
  GET_FFADMIN_SEARCH_ADDRESS_FAIL,
  GET_FFADMIN_SEARCH_ADDRESS2_REQ,
  GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS,
  GET_FFADMIN_SEARCH_ADDRESS2_FAIL,
  GET_REQUEST_QUOTE_REQ,
  GET_REQUEST_QUOTE_SUCCESS,
  GET_REQUEST_QUOTE_FAIL,
  GET_SHIP_FROM_AIRPORT_REQ,
  GET_SHIP_FROM_AIRPORT_SUCCESS,
  GET_SHIP_FROM_AIRPORT_FAIL,
  GET_SHIP_TO_AIRPORT_REQ,
  GET_SHIP_TO_AIRPORT_SUCCESS,
  GET_SHIP_TO_AIRPORT_FAIL,
  GET_COUNTRY_REQ,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAIL,
  GET_SHIP_TO_QUICK_AIRPORT_REQ,
  GET_SHIP_TO_QUICK_AIRPORT_SUCCESS,
  GET_SHIP_TO_QUICK_AIRPORT_FAIL,
  GET_SEARCH_BY_ZIP_REQ,
  GET_SEARCH_BY_ZIP_SUCCESS,
  GET_SEARCH_BY_ZIP_FAIL,
  CHECK_QUOTE_AVAILABLE_REQ,
  CHECK_QUOTE_AVAILABLE_SUCCESS,
  CHECK_QUOTE_AVAILABLE_FAIL,
  SET_QUOTATION_DATA,
  SEND_QUOTE_BY_EMAIL_REQ,
  SEND_QUOTE_BY_EMAIL_SUCCESS,
  SEND_QUOTE_BY_EMAIL_FAIL,
  GET_AIRPORT_DEPATURE_LIST,
  STORE_RADIO_OPTION,
  GET_EMPTY_COUNTRY_LIST,
  POST_FFADMIN_QUOTATION_PDF_REQ,
  POST_FFADMIN_QUOTATION_PDF_SUCCESS,
  POST_FFADMIN_QUOTATION_PDF_FAIL,
  GET_UPDATED_QUOTATION_LIST,
  GET_SHIPPER_LIST_REQ,
  GET_SHIPPER_LIST_SUCCESS,
  GET_SHIPPER_LIST_FAIL,
  SAVE_QUOTATION_DATA,
  SAVE_CHECKBOX_DATA,
  EDIT_SAVE_QUOTATION,
  EDIT_QUOTATION_REQ,
  EDIT_QUOTATION_SUCCESS,
  EDIT_QUOTATION_FAIL,
  SET_PREVIOUS_STATE,
  SET_UPDATE_PREVIOUS_STATE,
  SET_QUOTATION_UPDATE_UNIT,
  SET_SHIPPER_QUOTATION_REQ,
  SET_SHIPPER_QUOTATION_SUCCESS,
  SET_SHIPPER_QUOTATION_FAIL,
  GET_TRUKING_DATA,
  GET_RECALCULATE_VALUE,
  GET_DELIVERY_ZIPCODE_REQ,
  GET_DELIVERY_ZIPCODE_SUCCESS,
  GET_DELIVERY_ZIPCODE_FAIL,
  GET_UPDATE_DELIVERY_ZIPCODE_REQ,
  GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS,
  GET_UPDATE_DELIVERY_ZIPCODE_FAIL,
  GET_UPDATED_PICKUP_ZIPCODE_REQ,
  GET_UPDATED_PICKUP_ZIPCODE_SUCCESS,
  GET_UPDATED_PICKUP_ZIPCODE_FAIL,
  GET_UNASSIGNED_LIST_REQ,
  GET_UNASSIGNED_LIST_SUCCESS,
  GET_UNASSIGNED_LIST_FAIL,
  GET_ASSIGNED_LIST_REQ,
  GET_ASSIGNED_LIST_SUCCESS,
  GET_ASSIGNED_LIST_FAIL,
  SET_CREATE_SHIPPER_QUOTATION_REQ,
  SET_CREATE_SHIPPER_QUOTATION_FAIL,
  SET_CREATE_SHIPPER_QUOTATION_SUCCESS,
  GET_CONSIGNEE_COUNTRY_LIST,
  GET_CONSIGNEE_COUNTRY_BASED_DATA,
  GET_SEND_REQUEST_STATUS
} from '../constants';

const INIT_STATE = {
  quotationList: [],
  userSelection: '',
  loading: false,
  singleQuotation: {},
  addressResult: [],
  addressResult2: [],
  reqQuoteList: [],
  shipFromAirportList: [],
  shipToAirportList: [],
  quotationResult: {},
  // shipperQuotataionCreateDetail: {},
  countryList: [],
  shipToQuickAirportList: [],
  addressByZip: {},
  stateData: [],
  storedQuotation: {},
  airportOfDepartureList: [],
  pdfrequest: false,
  pdfData: [],
  quotationListLoading: true,
  updatedQuotation: [],
  shipperLoading: true,
  shipperData: [],
  saveQuotation: {},
  checkBoxOption: {},
  date: '',
  unit: '',
  editSaveQuotaion: {},
  editSaveQuotationLoading: false,
  departure: '',
  destination: '',
  truckingCarriersDummyVariable: [],
  previousUnit: 'Imperial / Metric',
  getUpdatePreviousUnit: '',
  getQuoteUpdateUnit: '',
  shipperQuotation: {},
  // shipperQuotataionCreateDetail: {},
  statusFromUpdate: false,
  preAirportOfDepatureDetails: '',
  preAirportOfDestinationDetails: '',
  deliveryAdressByzip: {},
  deliveryPlace: [],
  singleQuotationDetail: {},
  confirmStatus: '',
  comment: '',
  pickUpAdress: {},
  pickAdressData: [],
  updatedAddress: {},
  updatedArrayData: [],
  unassignedLoading: false,
  unassignedList: [],
  assignedListLoading: false,
  assignedList: [],
  consigneeCountryList:[],
  consigneeCountryData:[],
  consigneeSendRequestStatus:false,
};

const ffConsigneeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SHIPPER_QUOTATIONS_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIPPER_QUOTATIONS_SUCCESS:
      return {
        ...state,
        quotationListLoading: false,
        quotationList: action.payload,
        singleQuotation: {},
        singleQuotationDetail: {},
        confirmStatus: '',
      };
    case GET_SHIPPER_QUOTATIONS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case 'RETRIEVE_PRE_AIRPORT_OF_DEPARTURE_DETAILS':
      return {
        ...state,
        preAirportOfDepatureDetails: action.payload,
        requesting: false,
      };

    case 'RETRIEVE_PRE_AIRPORT_OF_DESTINATION_DETAILS':
      return {
        ...state,
        preAirportOfDestinationDetails: action.payload,
        requesting: false,
      };

    case GET_FFADMIN_QUOTATIONS_REQ:
    case GET_FFADMIN_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_FFADMIN_QUOTATIONS_SUCCESS:
      return {
        ...state,
        quotationListLoading: false,
        quotationList: action.payload,
      };

    case POST_FFADMIN_QUOTATIONS_PRICE_REQ:
      return {
        ...state,
        loading: true,
      };

    case POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        quotationList: action.payload,
      };

    case POST_FFADMIN_QUOTATIONS_PRICE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_FFADMIN_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        singleQuotation: action.payload.replayQuotation,
        singleQuotationDetail: action.payload.quotation,
        confirmStatus: action.payload.confirmStatus,
        comment: action?.payload?.comment,
      };
    case GET_FFADMIN_QUOTATIONS_FAIL:
    case GET_FFADMIN_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_FFADMIN_QUOTATION_REQ:
    case UPDATE_FFADMIN_QUOTATION_REQ:
    case DELETE_FFADMIN_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_FFADMIN_QUOTATION_SUCCESS:
    case DELETE_FFADMIN_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CREATE_FFADMIN_QUOTATION_SUCCESS:
      return {
        ...state,
        // truckingCarriersDummyVariable: action.payload.truckingCarriers,
        quotationResult: action.payload,
        // shipperQuotataionCreateDetail: action.payload, 
        loading: false,
        statusFromUpdate: false,
      };
    case CREATE_FFADMIN_QUOTATION_FAIL:
    case UPDATE_FFADMIN_QUOTATION_FAIL:
    case DELETE_FFADMIN_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_FFADMIN_SEARCH_ADDRESS_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_FFADMIN_SEARCH_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        addressResult: action.payload,
      };
    case GET_FFADMIN_SEARCH_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_FFADMIN_SEARCH_ADDRESS2_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS:
      return {
        ...state,
        loading: false,
        addressResult2: action.payload,
      };
    case GET_FFADMIN_SEARCH_ADDRESS2_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_REQUEST_QUOTE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_REQUEST_QUOTE_SUCCESS:
      return {
        ...state,
        reqQuoteList: action.payload,
        loading: false,
      };
    case GET_REQUEST_QUOTE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIP_FROM_AIRPORT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIP_FROM_AIRPORT_SUCCESS:
      return {
        ...state,
        shipFromAirportList: action.payload,
        loading: false,
      };
    case GET_SHIP_FROM_AIRPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIP_TO_AIRPORT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIP_TO_AIRPORT_SUCCESS:
      return {
        ...state,
        shipToAirportList: action.payload,
        loading: false,
      };

    case GET_SHIP_TO_AIRPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_COUNTRY_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countryList: action.payload,
      };
    case GET_COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SHIP_TO_QUICK_AIRPORT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIP_TO_QUICK_AIRPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        shipToQuickAirportList: action.payload,
      };
    case GET_SHIP_TO_QUICK_AIRPORT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SEARCH_BY_ZIP_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_SEARCH_BY_ZIP_SUCCESS:
      return {
        ...state,
        loading: false,
        addressByZip: action.payload,
        stateData: action.payload.places,
      };
    case GET_SEARCH_BY_ZIP_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CHECK_QUOTE_AVAILABLE_REQ:
      return {
        ...state,
        loading: true,
      };
    case CHECK_QUOTE_AVAILABLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHECK_QUOTE_AVAILABLE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SET_QUOTATION_DATA:
      return {
        ...state,
        storedQuotation: action.payload,
      };
    case SEND_QUOTE_BY_EMAIL_REQ:
      return {
        ...state,
        loading: true,
      };
    case SEND_QUOTE_BY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SEND_QUOTE_BY_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_AIRPORT_DEPATURE_LIST: {
      return {
        ...state,
        airportOfDepartureList: action.payload,
      };
    }
    case STORE_RADIO_OPTION: {
      return {
        ...state,
        userSelection: action.payload,
      };
    }
    case GET_EMPTY_COUNTRY_LIST: {
      return {
        ...state,
        shipToQuickAirportList: [],
      };
    }
    case POST_FFADMIN_QUOTATION_PDF_REQ: {
      return {
        ...state,
        pdfrequest: true,
      };
    }
    case POST_FFADMIN_QUOTATION_PDF_FAIL: {
      return {
        ...state,
        pdfrequest: false,
      };
    }
    case POST_FFADMIN_QUOTATION_PDF_SUCCESS: {
      return {
        ...state,
        pdfData: action.payload,
        pdfrequest: false,
      };
    }
    case GET_UPDATED_QUOTATION_LIST: {
      return {
        ...state,
        updatedQuotation: action.payload,
      };
    }

    case GET_SHIPPER_LIST_REQ:

    case GET_SHIPPER_LIST_SUCCESS:
      return {
        shipperLoading: false,
        ...state,
        shipperData: action.payload,
        shipperQuotation: {},
        // quotationResult: {},
      };

    case SAVE_QUOTATION_DATA:
      return {
        ...state,
        saveQuotation: action.payload.data,
        date: action.payload.date,
        unit: action.payload.unit,
        departure: action.payload.airpDep,
        destination: action.payload.airpDest,
      };

    case SAVE_CHECKBOX_DATA:
      return {
        ...state,
        checkBoxOption: action.payload,
      };
    case GET_SHIPPER_LIST_FAIL:

    case EDIT_SAVE_QUOTATION:
      return {
        ...state,
        editSaveQuotaion: action.payload,
      };

    case EDIT_QUOTATION_REQ:
      return {
        ...state,
        editSaveQuotationLoading: true,
      };

    case EDIT_QUOTATION_SUCCESS:
      return {
        ...state,
        // singleQuotation: action.payload,
        quotationResult: action.payload,

        editSaveQuotationLoading: false,
      };

    case EDIT_QUOTATION_FAIL:
      return {
        ...state,
        editSaveQuotationLoading: false,
      };

    case SET_PREVIOUS_STATE:
      return {
        ...state,
        previousUnit: action.payload,
      };

    case SET_UPDATE_PREVIOUS_STATE:
      return {
        ...state,
        getUpdatePreviousUnit: action.payload,
      };
    case SET_QUOTATION_UPDATE_UNIT:
      return {
        ...state,
        getQuoteUpdateUnit: action.payload,
      };

    case SET_SHIPPER_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };
    case SET_SHIPPER_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        shipperQuotation: action.payload,
        quotationResult: {},
      };
    case SET_SHIPPER_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case SET_CREATE_SHIPPER_QUOTATION_REQ:
      return {
        ...state,
        loading: true,
      };
    case SET_CREATE_SHIPPER_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        quotationResult: action.payload,
        // shipperQuotataionCreateDetail: action.payload,
      };
    case SET_CREATE_SHIPPER_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_TRUKING_DATA:
      return {
        ...state,
        truckingCarriersDummyVariable: action.payload,
      };
    case GET_RECALCULATE_VALUE: {
      return {
        ...state,
        statusFromUpdate: true,
      };
    }

    case GET_DELIVERY_ZIPCODE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_DELIVERY_ZIPCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryAdressByzip: action.payload,
        deliveryPlace: action.payload.places,
      };
    case GET_DELIVERY_ZIPCODE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_UPDATE_DELIVERY_ZIPCODE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedAddress: action.payload,
        updatedArrayData: action.payload.places,
      };
    case GET_UPDATE_DELIVERY_ZIPCODE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_UPDATED_PICKUP_ZIPCODE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_UPDATED_PICKUP_ZIPCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        pickUpAdress: action.payload,
        pickAdressData: action.payload.places,
      };
    case GET_UPDATED_PICKUP_ZIPCODE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_UNASSIGNED_LIST_REQ:
      return {
        ...state,
        unassignedLoading: true,
      };
    case GET_UNASSIGNED_LIST_SUCCESS:
      return {
        ...state,
        unassignedLoading: false,
        unassignedList: action.payload,
      };

    case GET_UNASSIGNED_LIST_FAIL:
      return {
        ...state,
        unassignedLoading: false,
      };

    case GET_ASSIGNED_LIST_REQ:
      return {
        ...state,
        assignedListLoading: true,
      };
    case GET_ASSIGNED_LIST_SUCCESS:
      return {
        ...state,
        assignedListLoading: false,
        assignedList: action.payload,
      };

    case GET_ASSIGNED_LIST_FAIL:
      return {
        ...state,
        assignedListLoading: false,
      };
    case GET_CONSIGNEE_COUNTRY_LIST:
      return{
        ...state,
        consigneeCountryList:action.payload,

      }
    case GET_CONSIGNEE_COUNTRY_BASED_DATA:
      return{
        ...state,
        consigneeCountryData:action.payload
      }
    case GET_SEND_REQUEST_STATUS:
      return{
        ...state,
        consigneeSendRequestStatus:action.payload
      }
      
    default:
      return state;
  }
};
export default ffConsigneeReducer;
