import React, { lazy } from 'react';

export const shipperRouteConfig = [
  {
    auth: ['ROLE_CONSIGNEE'],
    routes: [
      // MY PROFILE  ROUTES
      {
        path: '/shipper/profile/update1',
        component: lazy(() => import('pages/mainPages/Shipper/Profile/UserProfile/UserProfile.js')),
      },
      {
        path: '/shipper/profile//measurements/system',
        component: lazy(() => import('pages/mainPages/FreightForwarder/Admin/Profile/MeasurementsSystem/MeasurementsSystem')),
      },
      // QUOTATION ROUTES
      {
        path: '/shipper/quotation/create',
        // component: lazy(() => import('pages/mainPages/Shipper/Quotation/QuotationCreate')),
        component: lazy(() => import('pages/mainPages/FreightForwarder/Client/Quotation/QuotationCreate/index')),
      },
      {
        path: '/shipper/quotation/list/view',
        component: lazy(() => import('pages/mainPages/Shipper/Quotation/QuotationList')),
      },
      {
        path: '/shipper/quotation/output',
        component: lazy(() => import('pages/mainPages/Shipper/Quotation/QuoteOutput')),
      },
      {
        path: '/shipper/quotation/details/:quoteId/:fromStation/:toStation',
        // component: lazy(() => import('pages/mainPages/Shipper/Quotation/QuotationDetails')),
        component: lazy(() => import('pages/mainPages/FreightForwarder/Client/Quotation/QuotationDetails')),
      },
      {
        path: '/shipper/quotation/sent',
        component: lazy(() => import('pages/mainPages/Shipper/Quotation/QuotationOffered')),
      },
      {
        path: '/shipper/goods/quotation/details/:id',
        component: lazy(() => import('pages/mainPages/Shipper/Quotation/GoodQuotationDetails')),
      },
      {
        path: '/shipper/quotation/price',
        component: lazy(() =>
          // import('pages/mainPages/Shipper/Quotation/QuickQuote/QuickQuoteOutPut/index'),
          import('pages/mainPages/FreightForwarder/Client/Quotation/QuickQuote/QuickQuoteOutPut/index'),
        ),
      },
      {
        path: '/shipper/quotation/Airfreight-quote/price',
        component: lazy(() =>
          // import('pages/mainPages/Shipper/Quotation/Airfreight/AirfreightOutPut/index'),
          import('pages/mainPages/FreightForwarder/Client/Quotation/Airfreight/AirfreightOutPut/index'),
        ),
      },

      //	FREIGHT FORWARDER COMPANIES DETAILS
      {
        path: '/shipper/freight-forwarder/company-details/:companyId',
        component: lazy(() =>
          import('pages/mainPages/Shipper/FreightForwarders/CompanyDetails/CompanyDetail'),
        ),
      },

      //CUSTOMERS ROUTES
      {
        path: '/shipper/freight-forwarders/sent/requests/list/view',
        component: lazy(() => import('pages/mainPages/Shipper/FreightForwarders/SentRequests')),
      },
      {
        path: '/shipper/freight-forwarders/request/send',
        component: lazy(() => import('pages/mainPages/Shipper/FreightForwarders/SendRequest')),
      },

      //MESSAGE
      {
        path: '/shipper/freight-forwarders/message/list/view',
        component: lazy(() => import('pages/mainPages/Shipper/FreightForwarders/Messages/index')),
      },

      //SHIPPER STATISTICS
      {
        path: '/shipper/statistics',
        component: lazy(() => import('pages/mainPages/Shipper/Statistics/Statistics')),
      },

      //Directory ROUTES
      {
        path: '/shipper/directory/address/new',
        component: React.lazy(() => import('pages/mainPages/Shipper/Address/AddressAdd')),
      },
      {
        path: '/shipper/directory/address/list/view',
        component: React.lazy(() => import('pages/mainPages/Shipper/Address/AddressList')),
      },
      {
        path: '/shipper/directory/address/edit/:id',
        component: React.lazy(() => import('pages/mainPages/Shipper/Address/AddressEdit')),
      },

      // {
      // 	path: "/shipper/directory/address/new",
      // 	component: React.lazy(() => import("pages/mainPages/Shipper/Address/AddressAdd")),
      // },
      // {
      // 	path: "/shipper/directory/address/list/view",
      // 	component: React.lazy(() => import("pages/mainPages/Shipper/Address/AddressList")),
      // },

      // {
      // 	path: "/shipper/address/edit/:id",
      // 	component: React.lazy(() => import("pages/mainPages/Shipper/Address/AddressEdit")),
      // },

      //Feedback List
      {
        path: '/shipper/feedback/view',
        component: React.lazy(() => import('pages/mainPages/Shipper/Feedback/FeedbackList')),
      },
      {
        path: '/shipper/addfeedback',
        component: React.lazy(() => import('pages/mainPages/Shipper/Feedback/Addfeedback')),
      },

      //Feedback List View
      {
        path: '/freight-forwarder/Shipper/feedback/feedbacklist/view/:id',
        component: React.lazy(() => import('pages/mainPages/Shipper/Feedback/FeedbackListView')),
      },

      //Tutorial View
      {
        path: '/freight-forwarder/Shipper/tutorial/view',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Tutorial/ViewTutorial'),
        ),
      },

      // Notification
      {
        path: '/shipper/notification',
        component: React.lazy(() => import('pages/mainPages/Shipper/Notification/Notification')),
      },
    ],
  },
];
