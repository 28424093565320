import {
  GET_NOTIFICATIONS_REQ,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_COUNT_REQ,
  GET_NOTIFICATIONS_COUNT_SUCCESS,
  GET_NOTIFICATIONS_COUNT_FAIL,
  NOTIF_MARK_AS_READ_REQ,
  NOTIF_MARK_AS_READ_SUCCESS,
  NOTIF_MARK_AS_READ_FAIL,
  NOTIF_MAINMENU_COUNT_DATA_SUCCESS,
  NOTIF_MAINMENU_COUNT_DATA_FAIL,
  SET_NOTIFICATIONS_FLAG_STATUS,
  NOTIF_LIST_MENU_SUBMENU_STATUS_SUCCESS,
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  notificationFlagStatus: false,
  notification: [],
  loading: false,
  notificationCount: null,
  notificationMenuData: [],
};

const notificationsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS_FLAG_STATUS:
      return {
        ...state,
        notificationFlagStatus: action.payload,
        loading: false,
      };
    case GET_NOTIFICATIONS_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notification: action.payload,
        loading: false,
      };
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_NOTIFICATIONS_COUNT_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        notificationCount: action.payload,
        loading: false,
      };
    case GET_NOTIFICATIONS_COUNT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case NOTIF_MARK_AS_READ_REQ:
      return {
        ...state,
        loading: true,
      };
    case NOTIF_MARK_AS_READ_SUCCESS:
      return {
        ...state,
        loading: false,
      };

      // NOTIF_LIST_MENU_SUBMENU_STATUS_SUCCESS

    case NOTIF_MARK_AS_READ_FAIL:
      return {
        ...state,
        loading: false,
      };

    case NOTIF_MAINMENU_COUNT_DATA_SUCCESS:
      return {
        ...state,
        notificationMenuData: action.payload,
      };

    case NOTIF_MAINMENU_COUNT_DATA_FAIL:
      return {
        ...state,
        notificationMenuData: [],
      };

    default:
      return state;
  }
};
export default notificationsReducer;
