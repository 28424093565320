const initialState = {
  requesting: true,
  data: {},
};

export const shipperRequestStatusChangeByAdminReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case '@POST/shipperRequestAcceptByAdmin':
      return {
        ...state,
        requesting: false,
        data: {
          ...payload,
        },
      };
    case '@POST/shipperRequestRejectByAdmin':
      return {
        ...state,
        requesting: false,
        data: {
          ...payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export const addShipperReducer = (state = { requesting: true, data: {} }, { type, payload }) => {
  switch (type) {
    case '@post/addShipperAction':
      return {
        ...state,
        requesting: false,
        data: {
          ...payload,
        },
      };
    default:
      return state;
  }
};
