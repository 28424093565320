import React from 'react';
import Box from '@material-ui/core/Box';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import { ListItem, makeStyles } from '@material-ui/core';
import { Fonts } from '../../../shared/constants/AppEnums';
import clsx from 'clsx';
import { fade } from '@material-ui/core';
import user_profile_logo from 'shared/assets/user_profile.jpg';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { markAsReadNotification, markAsReadPerNotification } from 'redux/actions/Notification';
import { SET_NOTIFICATIONS_FLAG_STATUS } from 'shared/constants/ActionTypes';

const useStyles = makeStyles((theme) => ({
  textBase: {
    fontSize: 13,
  },
  avatar: {
    width: 48,
    height: 48,
  },
  minWidth0: {
    minWidth: 0,
  },
  listItemRoot: {
    padding: '8px 20px',
    cursor: 'pointer',
  },
  unseenMessage: {
    backgroundColor: fade(theme.palette.primary.main, 0.1),
    transform: 'translateY(-2px)',
    boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.black, 0.2)}`,
    cursor: 'pointer',
  },
}));

const NotificationItem = (props) => {
  const { item } = props;

  const classes = useStyles(props);

  const { profile } = useSelector((state) => state.auth);

  const history = useHistory();

  const dispatch = useDispatch();

  const handleNotificationClickFFAdmin = (item) => {
    if (item?.mainManu === 'Quotes') {
      if (item?.subManu === 'My Quotes') {
        history.push(`/freight-forwarder/admin/quotation/list/view`);
      } else if (item?.subManu === 'Quotes Analysis') {
        history.push(`/freight-forwarder/admin/quotation/analysis/list/view`);
      } else if (item?.subManu === 'Quotes Inquirer') {
        history.push(`/freight-forwarder/admin/quotation/inquerier/list/view`);
      }
    }
    else if (item?.mainManu === 'Incoming Quotes') {
      if (item?.subManu === 'Client Quotes - Incoming') {
        if (item?.quotesType === 'Quick Quote') {
          history.push(`/freight-forwarder/admin/quotation/shippers/incoming/quote/details/view/${item?.quoteId}`, {
            quoteId: item?.quoteId,
          })
        } else if (item?.isRedirect === 'manual') {
          history.push(`/freight-forwarder/admin/quotation/shippers/incoming/quote/details/manualquotation/${item?.quoteId}`, {
            quoteId: item?.quoteId,
            // clientName: item?.clientName,
            // clientCompany: item?.clientCompany,
          })
        }
      } else if (item?.subManu === 'Client Quotes - Analysis') {
        history.push(`/freight-forwarder/admin/quotation/shippers/requests/list/view`);
      }
    }
    else if (item?.mainManu === 'Clients') {
      if (item?.subManu === 'Incoming Requests List') {
        history.push(`/freight-forwarder/admin/shippers/requests/list/view`);
      } else if (item?.subManu === 'Client List') {
        history.push(`/freight-forwarder/admin/Client/List`);
      }
    }
    else if (item?.mainManu === 'Agents') {
      if (item?.subManu === 'List') {
        history.push(`/freight-forwarder/admin/agents/list/view`);
      }
    }
    else if (item?.mainManu === 'Warehouse') {
      if (item?.subManu === 'List') {
        history.push(`/freight-forwarder/admin/warehouse/list/view`);
      }
    }
    else if (item?.mainManu === 'Address Book') {
      if (item?.subManu === 'List') {
        history.push(`/freight-forwarder/admin/directory/address/list/view`);
      }
    }
    else if (item?.mainManu === 'Account Management') {
      if (item?.subManu === 'Approved Addons Req') {
        history.push(`/freight-forwarder/admin/approveAddonsRequest`);
      } else if (item?.subManu === 'Key Management') {
        if(item?.notificationType=='Client Licence expiry')
        {
          history.push('/freight-forwarder/key-management/freightforwarder/actionkey/details', {
            userId: '',
            companyId: profile?.companyId,
            role:'Client',
            alphanumericKey: item?.quoteId,
            // role: role,
            roles: ['All', 'Assigned', 'Unassigned', 'Used', 'Unused', 'Active', 'Suspended', 'Expired'],
            postRole: "All",
            pageNumber: 1,
            pageSize: 50,
            index:'',
            dropDownValue: 'All',
      
            fieldRole: 'client',
            fieldName: 'KeyManagement',
          });

        }
        else if(item?.notificationType=="FF Agent  Licence expiry")
        {
          history.push('/freight-forwarder/key-management/freightforwarder/actionkey/details', {
            userId: '',
            companyId: profile?.companyId,
            role:'Client',
            alphanumericKey: item?.quoteId,
            // role: role,
            roles: ['All', 'Assigned', 'Unassigned', 'Used', 'Unused', 'Active', 'Suspended', 'Expired'],
            postRole: "All",
            pageNumber: 1,
            pageSize: 50,
            index:'',
            dropDownValue: 'All',
      
            fieldRole: 'agent',
            fieldName: 'KeyManagement',
          });

        }

        else if(item?.notificationType=="FF Admin  Licence expiry")
        {
          history.push('/freight-forwarder/key-management/freightforwarder/actionkey/details', {
            userId: '',
            companyId: profile?.companyId,
            role:'Client',
            alphanumericKey: item?.quoteId,
            // role: role,
            roles: ['All', 'Assigned', 'Unassigned', 'Used', 'Unused', 'Active', 'Suspended', 'Expired'],
            postRole: "All",
            pageNumber: 1,
            pageSize: 50,
            index:'',
            dropDownValue: 'All',
      
            fieldRole: 'admin',
            fieldName: 'KeyManagement',
          });

        }
        
        // history.push(`/freight-forwarder/key-management/freightforwarder/view`);
      } else if (item?.subManu === 'Transaction Details') {
        history.push(`/freight-forwarder/transaction-list/freightforwarder/view`);
      }
    }
    else if (item?.mainManu === 'Notifications') {
      if (item?.subManu === 'Notifications') {
        history.push(`/freight-forwarder/admin/notification`);
      }
    }
    else if (item?.mainManu === 'Feedback') {
      if (item?.subManu === 'List') {
        history.push(`/freight-forwarder/admin/feedback/view`);
      }
    }
    else if (item?.mainManu === 'About') {
      if (item?.subManu === 'About') {
        history.push(`/freight-forwarder/admin/about/view`);
      }
    }
  };

  const handleNotificationClickFFAgent = (item) => {
    if (item?.mainManu === 'Quotes') {
      if (item?.subManu === 'My Quotes') {
        history.push(`/freight-forwarder/agent/quotation/list/view`);
      } else if (item?.subManu === 'Quotes Inquirer') {
        history.push(`/freight-forwarder/agent/quotation/inquerier/list/view`);
      }
    }
    else if (item?.mainManu === 'Incoming Quotes') {
      if (item?.subManu === 'Client Quotes - Incoming') {
        if (item?.quotesType === 'Quick Quote') {
          history.push(`/freight-forwarder/agent/quotation/shipper/request/details/view/${item?.quoteId}`, {
            quoteId: item?.quoteId,
          });
        }
        else if (item?.quotesType === 'manual') {

          history.push(`/freight-forwarder/agent/quotation/shippers/incoming/quote/details/manualquotation/${item?.quoteId}`, {
            quoteId: item?.quoteId,
            // clientName: item?.clientName,
            // clientCompany: item?.clientCompany,
            // statusDropfilter: statusDropfilter,
            // preFilter: filter,
            // preStatusDropfilter: statusDropfilter,
          })

        }
      }
    }
    else if (item?.mainManu === 'Clients') {
      if (item?.subManu === 'Incoming Requests List') {
        history.push(`/freight-forwarder/agent/shippers/requests/list/view`);
      } else if (item?.subManu === 'Client List') {
        history.push(`/agent/key-management/freightforwarder/agent/clientlist`);
      }
    }
    else if (item?.mainManu === 'Warehouse') {
      if (item?.subManu === 'List') {
        history.push(`/freight-forwarder/agent/warehosue/list/view`);
      }
    }
    else if (item?.mainManu === 'Address Book') {
      if (item?.subManu === 'List') {
        history.push(`/freight-forwarder/agent/directory/address/list/view`);
      }
    }
    else if (item?.mainManu === 'Account Management') {
      if (item?.subManu === 'Key Management') {
        history.push(`/agent/key-management/freightforwarder/agent/details`);
      } else if (item?.subManu === 'Transaction Details') {
        history.push(`/freight-forwarder/agent/transaction-list/freightforwarder/view`);
      }
    }
    else if (item?.mainManu === 'Feedback') {
      if (item?.subManu === 'List') {
        history.push(`/freight-forwarder/agent/feedback/view`);
      }
    }
  };

  const handleNotificationClickClientShipper = (item) => {
    if (item?.mainManu === 'Quotes') {
      if (item?.subManu === 'My Quotes') {
        history.push(`/shipper/quotation/details/${item?.quoteId}/${item?.fromStation}/${item?.toStation}`);
      }
    } else if (item?.mainManu === 'Freight Forwarders') {
      if (item?.subManu === 'Access Status') {
        history.push(`/shipper/freight-forwarders/sent/requests/list/view`);
      } else if (item?.subManu === 'Add Freight Forwarder') {
        history.push(`/shipper/freight-forwarders/request/send`);
      }
    } else if (item?.mainManu === 'Address Book') {
      if (item?.subManu === 'Address List') {
        history.push(`/shipper/directory/address/list/view`);
      }
    } else if (item?.mainManu === 'Feedback') {
      if (item?.subManu === 'List') {
        history.push(`/shipper/feedback/view`);
      }
    }
  };


  const handleNotificationClickFFClient = (item) => {
    if (item?.mainManu === 'Quotes') {
      if (item?.subManu === 'My Quotes') {
        if (item?.quotesType === 'Quick Quote') {
          history.push(
            `/freight-forwarder/client/quotation/details/${item?.quoteId}/${item?.fromStation}/${item?.toStation}`, {
            dataQuoteId: `${item?.quoteId}`,
            // dataId: `${item?.id}`,
          },
          )
        } else if (item?.quotesType === 'manual') {
          history.push(
            `/freight-forwarder/client/quotation/manual/details`, {
            quoteId: `${item?.quoteId}`,
          },
          )
        }
      } else if (item?.subManu === 'Quotes Inquirer') {
        history.push(`/freight-forwarder/client/quotation/inquerier/list/view`);
      }
    } else if (item?.mainManu === 'Freight Forwarders') {
      if (item?.subManu === 'Access Status') {
        history.push(`/freight-forwarder/client/freight-forwarders/sent/requests/list/view`);
      } else if (item?.subManu === 'Add Freight Forwarder') {
        history.push(`/freight-forwarder/client/freight-forwarders/request/send`);
      }
    } else if (item?.mainManu === 'Address Book') {
      if (item?.subManu === 'Address List') {
        history.push(`/freight-forwarder/client/directory/address/list/view`);
      }
    } else if (item?.mainManu === 'Feedback') {
      if (item?.subManu === 'List') {
        history.push(`/freight-forwarder/Client/feedback/view`);
      }
    }
  };

  return (
    <>
      <ListItem
        onClick={() => {
          dispatch(markAsReadPerNotification(item?.id));

          dispatch({ type: SET_NOTIFICATIONS_FLAG_STATUS, payload: true });

          if (item?.isRedirect === true) {

            if (profile?.role === 'FF Admin') {
              handleNotificationClickFFAdmin(item);
            } else if (profile?.role === 'FF Agent') {
              handleNotificationClickFFAgent(item);
            } else if (profile?.role === 'Client (Consignee/Shipper)') {
              handleNotificationClickClientShipper(item);
            } else if (profile?.role === 'Client (Freight Forwarder)') {
              handleNotificationClickFFClient(item);
            }

            // if (item?.quotesType === 'Quick Quote') {
            // if (profile?.role === 'FF Admin') {
            //   history.push(`/freight-forwarder/admin/quotation/shippers/incoming/quote/details/view/${item?.quoteId}`, {
            //     quoteId: item?.quoteId,
            //   })
            // } else

            // if (profile?.role === 'FF Agent') {
            //   history.push(`/freight-forwarder/agent/quotation/shipper/request/details/view/${item?.quoteId}`, {
            //     quoteId: item?.quoteId,
            //   })
            // } else
            // if (profile?.role === 'Client (Consignee/Shipper)') {
            //   history.push(
            //     `/shipper/quotation/details/${item?.quoteId}/${item?.fromStation}/${item?.toStation}`,
            //   );
            // }
            // else
            //   if (profile?.role === 'Client (Freight Forwarder)') {
            //     history.push(
            //       `/freight-forwarder/client/quotation/details/${item?.quoteId}/${item?.fromStation}/${item?.toStation}`,
            //       {
            //         dataQuoteId: `${item?.quoteId}`,
            //         // dataId: `${item?.id}`,
            //       },
            //     )
            //   }
            // }

            // else {
            // if (profile?.role === 'FF Admin') {
            //   history.push(`/freight-forwarder/admin/quotation/shippers/incoming/quote/details/manualquotation/${item?.quoteId}`, {
            //     quoteId: item?.quoteId,
            //     // clientName: item?.clientName,
            //     // clientCompany: item?.clientCompany,
            //   })
            // } else

            // if (profile?.role === 'FF Agent') {
            //   history.push(`/freight-forwarder/admin/quotation/shippers/incoming/quote/details/manualquotation/${item?.quoteId}`, {
            //     quoteId: item?.quoteId,
            //     // clientName: item?.clientName,
            //     // clientCompany: item?.clientCompany,
            //   })
            // }
            // }

          }

        }}
        className={
          item.status === 'unread' ? classes.unseenMessage : clsx(classes.listItemRoot, 'item-hover')
        }
      >
        <Box mr={4}>
          <ListItemAvatar className={classes.minWidth0}>
            <Avatar
              className={classes.avatar}
              alt="Remy Sharp"
              src={item.image || user_profile_logo}
            />
          </ListItemAvatar>
        </Box>
        <Box component="p" className={classes.textBase} color="text.secondary">
          <Box
            mr={2}
            component="span"
            display="inline-block"
            color="text.primary"
            fontWeight={Fonts.MEDIUM}
          >
            {item?.fromUserRole}
          </Box>
          <Box>
            {item.description}
          </Box>
          <Box>
            {item?.createDateTime}
          </Box>
        </Box>
      </ListItem >
    </>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
};
