const initialState = {
  requesting: true,
  adminList: [],
  adminsEnrolled: [],
};

export const retrieveAirlineAdminListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'ADMIN_LIST':
      return {
        ...state,
        requesting: false,
        adminList: payload,
      };
    case 'ADMINS_ENROLLED':
      return {
        ...state,
        requesting: false,
        adminsEnrolled: [...payload],
      };
    default:
      return {
        ...state,
      };
  }
};
