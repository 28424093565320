const initialState = {
  requesting: true,
  data: [],
  messageData: [],
  shipperData: [],
};

export const requestsReceivedByCompaniesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@GET/requestsReceivedByCompanies':
      return {
        ...state,
        requesting: false,
        data: [...payload],
      };

    case '@@GET/retrieveMessageData':
      return {
        ...state,
        requesting: false,
        messageData: payload,
      };

    case '@@GET/retrieveAllShipperList':
      return {
        ...state,
        requesting: false,
        shipperData: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
