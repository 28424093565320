import React from 'react';

export const authRouteConfig = [
  {
    routes: [
      {
        path: '/sign-in',
        component: React.lazy(() => import('pages/authPages/Signin/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/sign-up',
        component: React.lazy(() => import('pages/authPages/Signup/index')),
      },
      {
        path: '/airline/sign-up',
        component: React.lazy(() => import('pages/authPages/AirlineSignup/index')),
      },
      {
        path: '/trucking/sign-up',
        component: React.lazy(() => import('pages/authPages/TruckingSignup/index')),
      },
      {
        path: '/data-entry/sign-up',
        component: React.lazy(() => import('pages/authPages/Signup/index')),
      },
      {
        path: '/add-about/new',
        component: React.lazy(() => import('pages/mainPages/FreightForwarder/Admin/About/AddAbout')),
      },
    ],
  },

  {
    routes: [
      {
        path: '/freightforwarder/key/validate',
        component: React.lazy(() => import('pages/authPages/Signup/FFSignupValidateKey/index')),
      },
    ],
  },

  {
    routes: [
      {
        path: '/shipper/key/validate',
        component: React.lazy(() =>
          import('pages/authPages/Signup/ShipperSignupValidateKey/index'),
        ),
      },
    ],
  },
  {
    routes: [
      {
        path: '/airline/key/validate',
        component: React.lazy(() =>
          import('pages/authPages/Signup/AirlineSignupValidateKey/index'),
        ),
      },
    ],
  },
  {
    routes: [
      {
        path: '/freight-forwarder/sign-up',
        component: React.lazy(() => import('pages/authPages/Signup/freightForwarder/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/shipper/sign-up',
        component: React.lazy(() => import('pages/authPages/Signup/Shipper/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/airline/sign-up',
        component: React.lazy(() => import('pages/authPages/Signup/Airline/index')),
      },
    ],
  },

  {
    routes: [
      {
        path: '/forgot-password',
        component: React.lazy(() => import('pages/authPages/ForgetPassword')),
      },
      {
        path: '/password/reset',
        component: React.lazy(() => import('pages/authPages/ForgetPassword/ResetPassword')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/error-404',
        component: React.lazy(() => import('pages/errorPages/Error404/index')),
      },
    ],
  },
];
