import { superAdminNavConfig } from './superAdmin';
import { dataEntryNavConfig } from './dataEntry';
import { ffAdminNavConfig } from './ffAdmin';
import { ffAgentNavConfig } from './ffAgent';
import { ffClientNavConfig } from './ffClient';
import { shipperNavConfig } from './shipper';
import { airlineAdminNavConfig } from './airlineAdmin';
import { airlineAgentNavConfig } from './airlineAgent';
import { truckingNavConfig } from './trucking';

export const navConfig = [
  ...superAdminNavConfig,
  ...dataEntryNavConfig,
  ...ffAdminNavConfig,
  ...airlineAdminNavConfig,
  ...airlineAgentNavConfig,
  ...shipperNavConfig,
  ...ffAgentNavConfig,
  ...ffClientNavConfig,
  ...truckingNavConfig,
];
