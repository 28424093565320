import React, { lazy } from 'react';

export const ffClientRouteConfig = [
  {
    auth: ['ROLE_FFCLIENT'],
    routes: [
      // MY PROFILE  ROUTES
      {
        path: '/freight-forwarder/client/profile/update',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Profile/UserProfile/UserProfile.js'),
        ),
      },
      // MEASUREMENT SYSTEM ROUTES
      {
        path: '/freight-forwarder/client/profile/measurements/system',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Profile/MeasurementsSystem/MeasurementsSystem'),
        ),
      },
      // QUOTATION ROUTES
      {
        path: '/freight-forwarder/client/quotation/create',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Quotation/QuotationCreate'),
        ),
      },
      {
        path: '/freight-forwarder/client/quotation/list/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Quotation/QuotationList'),
        ),
      },
      {
        path: '/freight-forwarder/client/quotation/manual/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Quotation/ManualQuote/ManualQuote'),
        ),
      },
      {
        path: '/freight-forwarder/client/quotation/manual/details',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Quotation/ManualQuote/ManualQuoteDetails/index'),
        ),
      },
      {
        path: '/freight-forwarder/client/quotation/output',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Quotation/QuoteOutput'),
        ),
      },
      {
        path: '/freight-forwarder/client/quotation/details/:quoteId/:fromStation/:toStation',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Quotation/QuotationDetails'),
        ),
      },
      {
        path: '/freight-forwarder/client/quotation/sent',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Quotation/QuotationOffered'),
        ),
      },
      {
        path: '/freight-forwarder/client/goods/quotation/details/:id',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Quotation/GoodQuotationDetails'),
        ),
      },
      {
        path: '/freight-forwarder/client/quotation/price',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Client/Quotation/QuickQuote/QuickQuoteOutPut/index'
          ),
        ),
      },
      {
        path: '/freight-forwarder/client/quotation/Airfreight-quote/price',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Client/Quotation/Airfreight/AirfreightOutPut/index'
          ),
        ),
      },
      {
        path: '/ffclient/freight-forwarder/company-details/:companyId',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Client/FreightForwarders/CompanyDetails/CompanyDetail'
          ),
        ),
      },
      {
        path: '/freight-forwarder/client/quotation/inquerier/list/view',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Client/Quotation/QuoteInquerier'
          ),
        ),
      },


      //	FREIGHT FORWARDER COMPANIES DETAILS
      {
        path: '/freight-forwarder/client/freight-forwarder/company-details',
        component: lazy(() =>
          import(
            'pages/mainPages/FreightForwarder/Client/FreightForwarders/CompanyDetails/CompanyDetail'
          ),
        ),
      },


      //MESSAGE
      {
        path: '/freight-forwarder/client/freight-forwarders/message/list/view',
        component: lazy(() => import('pages/mainPages/Shipper/FreightForwarders/Messages/index')),
      },


      //CUSTOMERS ROUTES
      {
        path: '/freight-forwarder/client/freight-forwarders/sent/requests/list/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/FreightForwarders/SentRequests'),
        ),
      },

      {
        path: '/freight-forwarder/client/freight-forwarders/sent/requests/list/action/view',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/FreightForwarders/ActionKeyDetails'),
          // src\pages\mainPages\FreightForwarder\Client\FreightForwarders\ActionKeyDetails.js 
        ),
      },

      {
        path: '/freight-forwarder/client/freight-forwarders/request/send',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/FreightForwarders/SendRequest'),
        ),
      },

      //SHIPPER STATISTICS
      {
        path: '/freight-forwarder/client/statistics',
        component: lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Statistics/Statistics'),
        ),
      },

      //Directory ROUTES
      {
        path: '/freight-forwarder/client/directory/address/new',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Address/AddressAdd'),
        ),
      },
      {
        path: '/freight-forwarder/client/directory/address/list/view',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Address/AddressList'),
        ),
      },
      {
        path: '/freight-forwarder/client/directory/address/edit/:id',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Address/AddressEdit'),
        ),
      },

      // {
      // 	path: "/freight-forwarder/client/directory/address/new",
      // 	component: React.lazy(() => import("pages/mainPages/Shipper/Address/AddressAdd")),
      // },
      // {
      // 	path: "/freight-forwarder/client/directory/address/list/view",
      // 	component: React.lazy(() => import("pages/mainPages/Shipper/Address/AddressList")),
      // },

      // {
      // 	path: "/freight-forwarder/client/address/edit/:id",
      // 	component: React.lazy(() => import("pages/mainPages/Shipper/Address/AddressEdit")),
      // },

      //Feedback List
      {
        path: '/freight-forwarder/Client/feedback/view',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Feedback/FeedbackList'),
        ),
      },
      {
        path: '/freight-forwarder/Client/addfeedback',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Feedback/Addfeedback'),
        ),
      },

      //Feedback List View
      {
        path: '/freight-forwarder/ffclient/feedback/feedbacklist/view/:id',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Feedback/FeedbackListView'),
        ),
      },

      //Tutorial View
      {
        path: '/freight-forwarder/client/tutorial/view',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Admin/Tutorial/ViewTutorial'),
        ),
      },

      // Notification
      {
        path: '/freight-forwarder/client/notification',
        component: React.lazy(() =>
          import('pages/mainPages/FreightForwarder/Client/Notification/Notification'),
        ),
      },
    ],
  },
];
