const initial_state = {
  retrive_role_count: {},
  retrive_statistics_graph_list: [],
};

export const super_admin_statistics = (state = initial_state, { type, payload }) => {
  switch (type) {
    case 'RETRIEVE_STATISTICS_ROLE_COUNT':
      return {
        ...state,
        retrive_role_count: {
          ...payload,
        },
      };

    case 'RETRIEVE_STATISTICS_GRAPH_LIST':
      return {
        ...state,
        retrive_statistics_graph_list: [...payload],
      };
    default:
      return {
        ...state,
      };
  }
};
