export const GET_FFADMIN_QUOTATIONS_REQ = 'GET_FFADMIN_QUOTATIONS_REQ';
export const GET_FFADMIN_QUOTATIONS_SUCCESS = 'GET_FFADMIN_QUOTATIONS_SUCCESS';
export const GET_FFADMIN_QUOTATIONS_FAIL = 'GET_FFADMIN_QUOTATIONS_FAIL';

export const GET_SHIPPER_QUOTATIONS_REQ = 'GET_SHIPPER_QUOTATIONS_REQ';
export const GET_SHIPPER_QUOTATIONS_SUCCESS = 'GET_SHIPPER_QUOTATIONS_SUCCESS';
export const GET_SHIPPER_QUOTATIONS_FAIL = 'GET_SHIPPER_QUOTATIONS_FAIL';

export const POST_FFADMIN_QUOTATIONS_PRICE_REQ = 'POST_FFADMIN_QUOTATIONS_PRICE_REQ';
export const POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS = 'POST_FFADMIN_QUOTATIONS_PRICE_SUCCESS';
export const POST_FFADMIN_QUOTATIONS_PRICE_FAIL = 'POST_FFADMIN_QUOTATION_PRICE_FAIL';

export const GET_FFADMIN_QUOTATION_REQ = 'GET_FFADMIN_QUOTATION_REQ';
export const GET_FFADMIN_QUOTATION_SUCCESS = 'GET_FFADMIN_QUOTATION_SUCCESS';
export const GET_FFADMIN_QUOTATION_FAIL = 'GET_FFADMIN_QUOTATION_FAIL';

export const UPDATE_FFADMIN_QUOTATION_REQ = 'UPDATE_FFADMIN_QUOTATION_REQ';
export const UPDATE_FFADMIN_QUOTATION_SUCCESS = 'UPDATE_FFADMIN_QUOTATION_SUCCESS';
export const UPDATE_FFADMIN_QUOTATION_FAIL = 'UPDATE_FFADMIN_QUOTATION_FAIL';

export const DELETE_FFADMIN_QUOTATION_REQ = 'DELETE_FFADMIN_QUOTATION_REQ';
export const DELETE_FFADMIN_QUOTATION_SUCCESS = 'DELETE_FFADMIN_QUOTATION_SUCCESS';
export const DELETE_FFADMIN_QUOTATION_FAIL = 'DELETE_FFADMIN_QUOTATION_FAIL';

export const CREATE_FFADMIN_QUOTATION_REQ = 'CREATE_FFADMIN_QUOTATION_REQ';
export const CREATE_FFADMIN_QUOTATION_SUCCESS = 'CREATE_FFADMIN_QUOTATION_SUCCESS';
export const CREATE_FFADMIN_QUOTATION_FAIL = 'CREATE_FFADMIN_QUOTATION_FAIL';

export const GET_FFADMIN_SEARCH_ADDRESS_REQ = 'GET_FFADMIN_SEARCH_ADDRESS_REQ';
export const GET_FFADMIN_SEARCH_ADDRESS_SUCCESS = 'GET_FFADMIN_SEARCH_ADDRESS_SUCCESS';
export const GET_FFADMIN_SEARCH_ADDRESS_FAIL = 'GET_FFADMIN_SEARCH_ADDRESS_FAIL';

export const GET_FFADMIN_SEARCH_ADDRESS2_REQ = 'GET_FFADMIN_SEARCH_ADDRESS2_REQ';
export const GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS = 'GET_FFADMIN_SEARCH_ADDRESS2_SUCCESS';
export const GET_FFADMIN_SEARCH_ADDRESS2_FAIL = 'GET_FFADMIN_SEARCH_ADDRESS2_FAIL';

export const GET_REQUEST_QUOTE_REQ = 'GET_REQUEST_QUOTE_REQ';
export const GET_REQUEST_QUOTE_SUCCESS = 'GET_REQUEST_QUOTE_SUCCESS';
export const GET_REQUEST_QUOTE_FAIL = 'GET_REQUEST_QUOTE_FAIL';

export const GET_SHIP_FROM_AIRPORT_REQ = 'GET_SHIP_FROM_AIRPORT_REQ';
export const GET_SHIP_FROM_AIRPORT_SUCCESS = 'GET_SHIP_FROM_AIRPORT_SUCCESS';
export const GET_SHIP_FROM_AIRPORT_FAIL = 'GET_SHIP_FROM_AIRPORT_FAIL';

export const GET_SHIP_TO_AIRPORT_REQ = 'GET_SHIP_TO_AIRPORT_REQ';
export const GET_SHIP_TO_AIRPORT_SUCCESS = 'GET_SHIP_TO_AIRPORT_SUCCESS';
export const GET_SHIP_TO_AIRPORT_FAIL = 'GET_SHIP_TO_AIRPORT_FAIL';

export const GET_SHIP_TO_QUICK_AIRPORT_REQ = 'GET_SHIP_TO_QUICK_AIRPORT_REQ';
export const GET_SHIP_TO_QUICK_AIRPORT_SUCCESS = 'GET_SHIP_TO_QUICK_AIRPORT_SUCCESS';
export const GET_SHIP_TO_QUICK_AIRPORT_FAIL = 'GET_SHIP_TO_QUICK_AIRPORT_FAIL';

export const GET_SEARCH_BY_ZIP_REQ = 'GET_SEARCH_BY_ZIP_REQ';
export const GET_SEARCH_BY_ZIP_SUCCESS = 'GET_SEARCH_BY_ZIP_SUCCESS';
export const GET_SEARCH_BY_ZIP_FAIL = 'GET_SEARCH_BY_ZIP_FAIL';

export const GET_COUNTRY_REQ = 'GET_COUNTRY_REQ';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAIL = 'GET_COUNTRY_FAIL';

export const CHECK_QUOTE_AVAILABLE_REQ = 'CHECK_QUOTE_AVAILABLE_REQ';
export const CHECK_QUOTE_AVAILABLE_SUCCESS = 'CHECK_QUOTE_AVAILABLE_SUCCESS';
export const CHECK_QUOTE_AVAILABLE_FAIL = 'CHECK_QUOTE_AVAILABLE_FAIL';

export const SEND_QUOTE_BY_EMAIL_REQ = 'SEND_QUOTE_BY_EMAIL_REQ';
export const SEND_QUOTE_BY_EMAIL_SUCCESS = 'SEND_QUOTE_BY_EMAIL_SUCCESS';
export const SEND_QUOTE_BY_EMAIL_FAIL = 'SEND_QUOTE_BY_EMAIL_FAIL';

export const SET_QUOTATION_DATA = 'SET_QUOTATION_DATA';

export const GET_AIRPORT_DEPATURE_LIST = 'GET_AIRPORT_DEPATURE_LIST'; // added by vishal
export const STORE_RADIO_OPTION = 'STORE_RADIO_OPTION'; //added by vishal
export const GET_EMPTY_COUNTRY_LIST = 'GET_EMPTY_COUNTRY_LIST'; //added by vishal

export const POST_FFADMIN_QUOTATION_PDF_REQ = 'POST_FFADMIN_QUOTATION_PDF_REQ';
export const POST_FFADMIN_QUOTATION_PDF_SUCCESS = 'POST_FFADMIN_QUOTATION_PDF_SUCCESS';
export const POST_FFADMIN_QUOTATION_PDF_FAIL = 'POST_FFADMIN_QUOTATION_PDF_FAIL';

export const GET_UPDATED_QUOTATION_LIST = 'GET_UPDATED_QUOTATION_LIST';

export const GET_SHIPPER_LIST_REQ = 'GET_SHIPPER_LIST_REQ';
export const GET_SHIPPER_LIST_SUCCESS = 'GET_SHIPPER_LIST_SUCCESS';
export const GET_SHIPPER_LIST_FAIL = 'GET_SHIPPER_LIST_FAIL';

export const SAVE_QUOTATION_DATA = 'SAVE_QUOTATION_DATA';
export const SAVE_CHECKBOX_DATA = 'SAVE_CHECKBOX_DATA';

export const EDIT_SAVE_QUOTATION = 'EDIT_SAVE_QUOTATION';
export const EDIT_QUOTATION_REQ = 'EDIT_QUOTATION_REQ';
export const EDIT_QUOTATION_SUCCESS = 'EDIT_QUOTATION_SUCCESS';
export const EDIT_QUOTATION_FAIL = 'EDIT_QUOTATION_FAIL';

export const SET_PREVIOUS_STATE = 'SET_PREVIOUS_STATE';
export const SET_UPDATE_PREVIOUS_STATE = 'SET_UPDATE_PREVIOUS_STATE';
export const SET_QUOTATION_UPDATE_UNIT = 'SET_QUOTATION_UPDATE_UNIT';

export const SET_SHIPPER_QUOTATION_REQ = 'SET_SHIPPER_QUOTATION_REQ';
export const SET_SHIPPER_QUOTATION_SUCCESS = 'SET_SHIPPER_QUOTATION_SUCCESS';
export const SET_SHIPPER_QUOTATION_FAIL = 'SET_SHIPPER_QUOTATION_FAIL';

export const SET_CREATE_SHIPPER_QUOTATION_REQ = 'SET_CREATE_SHIPPER_QUOTATION_REQ';
export const SET_CREATE_SHIPPER_QUOTATION_SUCCESS = 'SET_CREATE_SHIPPER_QUOTATION_SUCCESS';
export const SET_CREATE_SHIPPER_QUOTATION_FAIL = 'SET_CREATE_SHIPPER_QUOTATION_FAIL';

export const GET_TRUKING_DATA = 'GET_TRUKING_DATA';
export const GET_RECALCULATE_VALUE = 'GET_RECALCULATE_VALUE';

export const GET_DELIVERY_ZIPCODE_REQ = 'GET_DELIVERY_ZIPCODE_REQ';
export const GET_DELIVERY_ZIPCODE_SUCCESS = 'GET_DELIVERY_ZIPCODE_SUCESS';
export const GET_DELIVERY_ZIPCODE_FAIL = 'GET_DELIVERY_ZIPCODE_FAIL';

export const GET_UPDATE_DELIVERY_ZIPCODE_REQ = 'GET_UPDATE_DELIVERY_ZIPCODE_REQ';
export const GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS = 'GET_UPDATE_DELIVERY_ZIPCODE_SUCCESS';
export const GET_UPDATE_DELIVERY_ZIPCODE_FAIL = 'GET_UPDATE_DELIVERY_ZIPCODE_FAIL';

export const GET_UPDATED_PICKUP_ZIPCODE_REQ = 'GET_UPDATED_PICKUP_ZIPCODE_REQ';
export const GET_UPDATED_PICKUP_ZIPCODE_SUCCESS = 'GET_UPDATED_PICKUP_ZIPCODE_SUCCESS';
export const GET_UPDATED_PICKUP_ZIPCODE_FAIL = 'GET_UPDATED_PICKUP_ZIPCODE_FAIL';

export const GET_UNASSIGNED_LIST_REQ = 'GET_UNASSIGNED_LIST_REQ';
export const GET_UNASSIGNED_LIST_SUCCESS = 'GET_UNASSIGNED_LIST_SUCCESS';
export const GET_UNASSIGNED_LIST_FAIL = 'GET_UNASSIGNED_LIST_FAIL';

export const GET_ASSIGNED_LIST_REQ = 'GET_ASSIGNED_LIST_REQ';
export const GET_ASSIGNED_LIST_SUCCESS = 'GET_ASSIGNED_LIST_SUCCESS';
export const GET_ASSIGNED_LIST_FAIL = 'GET_ASSIGNED_LIST_FAIL';

export const GET_PREVIOUS_QUOTE_DETAIL = 'GET_PREVIOUS_QUOTE_DETAIL';

export const GET_CLIENT_COUNTRY_LIST = 'GET_CLIENT_COUNTRY_LIST';
export const GET_CLIENT_COUNTRY_BASED_DATA = 'GET_CLIENT_COUNTRY_BASED_DATA';

export const GET_SEND_REQUEST_STATUS = 'GET_SEND_REQUEST_STATUS';

export const GET_FEED_BACK_LIST_REQ_FOR_CLIENT_AND_SHIPPER =
  'GET_FEED_BACK_LIST_REQ_FOR_CLIENT_AND_SHIPPER';
export const GET_FEEDBACK_LIST_SUCCESS_FOR_CLIENT_AND_SHIPPER =
  'GET_FEEDBACK_LIST_SUCCESS_FOR_CLIENT_AND_SHIPPER';
export const GET_FEEDBACL_LIST_FAIL_FOR_CLIENT_AND_SHIPPER =
  'GET_FEEDBACL_LIST_FAIL_FOR_CLIENT_AND_SHIPPER';


export const GET_FFCLIENT_SEARCH_ADDRESS2_REQ_QUOTEFORMODEL = 'GET_FFCLIENT_SEARCH_ADDRESS2_REQ_QUOTEFORMODEL';
export const GET_FFCLIENT_SEARCH_ADDRESS2_SUCCESS_QUOTEFORMODEL = 'GET_FFCLIENT_SEARCH_ADDRESS2_SUCCESS_QUOTEFORMODEL';
export const GET_FFCLIENT_SEARCH_ADDRESS2_FAIL_QUOTEFORMODEL = 'GET_FFCLIENT_SEARCH_ADDRESS2_FAIL_QUOTEFORMODEL';

export const GET_FFCLIENT_REQ_QUOTEFORMODEL_LIST = 'GET_FFCLIENT_REQ_QUOTEFORMODEL_LIST';
export const GET_FFCLIENT_SUCCESS_QUOTEFORMODEL_LIST = 'GET_FFCLIENT_SUCCESS_QUOTEFORMODEL_LIST';
export const GET_FFCLIENT_FAIL_QUOTEFORMODEL_LIST = 'GET_FFCLIENT_FAIL_QUOTEFORMODEL_LIST';


export const GET_FFCLIENT_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_REQ='GET_FFCLIENT_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_REQ';
export const GET_FFCLIENT_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_SUCCESS='GET_FFCLIENT_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_SUCCESS'
export const GET_FFCLIENT_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_FAIL='GET_FFCLIENT_SEARCH_INQUIRER_DETAILS_BY_INQUIRER_NAME_FAIL'

export const GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_REQ='GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_REQ';
export const GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS='GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS';
export const GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_FAIL='GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_FAIL'

export const GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS_SAVE_RECORD='GET_FFCLIENT_SEARCH_INQUIRER_NAME_BY_COMPANY_SUCCESS_SAVE_RECORD';
export const GET_COMBINED_PDF='GET_COMBINED_PDF'

export const GET_USA_AIRPORTS_LIST_FAIL = 'GET_USA_AIRPORTS_LIST_FAIL';
export const GET_USA_AIRPORTS_LIST_REQ = 'GET_USA_AIRPORTS_LIST_REQ';
export const GET_USA_AIRPORTS_LIST_SUCCESS = 'GET_USA_AIRPORTS_LIST_SUCCESS';

export const GET_LOCAL_AIRPORTS_LIST_SUCCESS = 'GET_LOCAL_AIRPORTS_LIST_SUCCESS';

export const GET_MANUAL_QUOTATION_REQ = 'GET_MANUAL_QUOTATION_REQ';
export const GET_MANUAL_QUOTATION_SUCCESS = 'GET_MANUAL_QUOTATION_SUCCESS';
export const GET_MANUAL_QUOTATION_FAIL = 'GET_MANUAL_QUOTATION_FAIL';

export const SAVE_MANUAL_QUOTATION_REQ = 'SAVE_MANUAL_QUOTATION_REQ';
export const PRINT_MANUAL_QUOTATION_REQ = 'PRINT_MANUAL_QUOTATION_REQ';
export const SHARE_MANUAL_QUOTATION_REQ = 'SHARE_MANUAL_QUOTATION_REQ';

export const SAVE_MANUAL_QUOTATION_SUCCESS = 'SAVE_MANUAL_QUOTATION_SUCCESS';
export const PRINT_MANUAL_QUOTATION_SUCCESS = 'PRINT_MANUAL_QUOTATION_SUCCESS';
export const SHARE_MANUAL_QUOTATION_SUCCESS = 'SHARE_MANUAL_QUOTATION_SUCCESS';

export const SAVE_MANUAL_QUOTATION_FAIL = 'SAVE_MANUAL_QUOTATION_FAIL';
export const PRINT_MANUAL_QUOTATION_FAIL = 'PRINT_MANUAL_QUOTATION_FAIL';
export const SHARE_MANUAL_QUOTATION_FAIL = 'SHARE_MANUAL_QUOTATION_FAIL';

export const CREATE_TRUKCING_QUOTATION_REQ = 'CREATE_TRUKCING_QUOTATION_REQ';
export const CREATE_TRUKCING_QUOTATION_SUCCESS = 'CREATE_TRUKCING_QUOTATION_SUCCESS';
export const CREATE_TRUKCING_QUOTATION_FAIL = 'CREATE_TRUKCING_QUOTATION_FAIL';

export const GET_UNIT_MEASUREMENT='GET_UNIT_MEASUREMENT'
export const SAVED_QUOTATION_ID='SAVED_QUOTATION_ID';
export const REMOVE_SAVE_DATA_FROM_NEW_QUOTE = 'REMOVE_SAVE_DATA_FROM_NEW_QUOTE';

export const GET_DEPARTURE_ZIPCODE = 'GET_DEPARTURE_ZIPCODE';
export const GET_AIRLINE_LIST = 'GET_AIRLINE_LIST';
export const GET_AIRLINE_LIST1 = 'GET_AIRLINE_LIST1';


export const PRINT_MANUAL_QUOTATION_DETAIL_REQ = 'PRINT_MANUAL_QUOTATION_DETAIL_REQ'
export const SAVE_MANUAL_QUOTATION_DETAIL_REQ = 'SAVE_MANUAL_QUOTATION_DETAIL_REQ'
export const SHARE_MANUAL_QUOTATION_DETAIL_REQ = 'SHARE_MANUAL_QUOTATION_DETAIL_REQ'

export const PRINT_MANUAL_QUOTATION_DETAIL_SUCCESS = 'PRINT_MANUAL_QUOTATION_DETAIL_SUCCESS'
export const SAVE_MANUAL_QUOTATION_DETAIL_SUCCESS = 'SAVE_MANUAL_QUOTATION_DETAIL_SUCCESS'
export const SHARE_MANUAL_QUOTATION_DETAIL_SUCCESS = 'SHARE_MANUAL_QUOTATION_DETAIL_SUCCESS'

export const PRINT_MANUAL_QUOTATION_DETAIL_FAIL = 'PRINT_MANUAL_QUOTATION_DETAIL_FAIL'
export const SAVE_MANUAL_QUOTATION_DETAIL_FAIL = 'SAVE_MANUAL_QUOTATION_DETAIL_FAIL'
export const SHARE_MANUAL_QUOTATION_DETAIL_FAIL = 'SHARE_MANUAL_QUOTATION_DETAIL_FAIL'

export const GET_DISCLAIMER_DETAIL_DATA='GET_DISCLAIMER_DETAIL_DATA';

export const GET_USA_AIRPORTS_TIER2_LIST_SUCCESS = 'GET_USA_AIRPORTS_TIER2_LIST_SUCCESS';

export const GET_PREFERRED_AIRPORTS_REQUEST = 'GET_PREFERRED_AIRPORTS_REQUEST';
export const GET_PREFERRED_AIRPORTS_SUCCESS = 'GET_PREFERRED_AIRPORTS_SUCCESS';
export const GET_PREFERRED_AIRPORTS_FAIL = 'GET_PREFERRED_AIRPORTS_FAIL';
export const GET_PREFERRED_AIRPORTS_INITIAL = 'GET_PREFERRED_AIRPORTS_INITIAL';

