const initialState = {
  requesting: true,
  approvedCompanies: {},
  unapprovedCompanies: {},
  approvedCompaniesNameDetails: {},

  activeStatus: {},
};

export const freightForwarderCompaniesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@@superAdmin/freightForwarder/approvedCompanies':
      return {
        ...state,
        requesting: false,
        approvedCompanies: {
          ...payload,
        },
      };

    case '@@superAdmin/freightForwarder/approvedCompanies/companyNameDetails':
      return {
        ...state,
        requesting: false,
        approvedCompaniesNameDetails: {
          ...payload,
        },
      };

    case '@@superAdmin/freightForwarder/unapprovedCompanies':
      return {
        ...state,
        requesting: false,
        unapprovedCompanies: {
          ...payload,
        },
      };

    case '@@superAdmin/freightForwarder/approveCompany':
      return {
        ...state,
        requesting: false,
        approvalStatus: payload,
      };

    case '@@activateFreightForwarder/super-admin/freightForwarder/companies':
      return {
        ...state,
        requesting: false,
        activeStatus: {
          ...payload,
        },
      };

    case '@@deactivateFreightForwarder/super-admin/freightForwarder/companies':
      return {
        ...state,
        requesting: false,
        activeStatus: {
          ...payload,
        },
      };
    default:
      return state;
  }
};
